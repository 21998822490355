






















import Vue from 'vue';
import Component from 'vue-class-component';
import GraduateHatIcon from '@/assets/icon/graduate-hat.svg';
import EducationalStandardsMenu from '@/components/Layout/Header/EducationalStandardsMenu.vue';
import Permission from '@/components/Permission/Permission.vue';

@Component({
  components: {
    GraduateHatIcon,
    EducationalStandardsMenu,
    Permission
  }
})
export default class EducationalStandards extends Vue {
  public GraduateHatIcon = GraduateHatIcon;
  public clearFields() {
    this.$store.dispatch('allowCloseSearch', true);
    this.$store.dispatch('clearEducationStandartsFields');
  }
}
