import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import StatisticApiService from '@/services/api/statistic.service';

const initialState = () => ({});
const state = initialState();

const actions: ActionTree<object, RootState> = {
  async sendStatisticStream({getters}, id: number) {
    return StatisticApiService.sendStatisticStream(id, getters.getMediaCenterId);
  },
  async sendStatisticDownload({getters}, idList: number[]) {
    return StatisticApiService.sendStatisticDownload(idList, getters.getMediaCenterId);
  }
};
export default {
  state,
  actions
};
