import {ICredentials} from '@/services/api/authorization.service';
import {IRegistrationData} from '@/services/api/user.service';
import {IResetPassword} from '@/services/api/authorization.service';

import login from './login.form.store';
import register from './register.form.store';
import email from './user.form.store';

export interface IFormState {
  login: ICredentials,
  register: IRegistrationData,
  email: IResetPassword,
}

const modules = {
  login,
  register,
  email
};

export default {
  modules
};
