







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {Direction, IDirection} from '@/interfaces/interfaces';

@Component({})
export default class Chevron extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'left'
  })
  public direction!: Direction;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public disabled!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public animated!: boolean;

  private directionAngleDictionary: IDirection<number> = {
    up: 180,
    right: 270,
    down: 0,
    left: 90
  };

  private get angle(): number {
    return this.directionAngleDictionary[this.direction];
  }
}
