import {ActionTree, GetterTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RawLocation, Route} from 'vue-router/types/router';
import router from '@/router';
import {RootState} from '@/store';
import {IDictionary} from '@/interfaces/interfaces';
import {IMedia, MediaExtensions} from '@/services/api/media.service';
import SearchApiService, {
  ExpertSearchFilterDictionary,
  IExpertSearchParameters,
  IExpertSearchQueryParameters,
  IFilterItem,
  ISearchQueryParameters,
  ISearchQueryRootParameters,
  ISearchQueryStringParameters,
  ISearchRequestParameters,
  ISearchResponse,
  SearchSort,
  SearchSortType,
  ISearchParameters,
  IEducationalStandardsItem
} from '@/services/api/search.service';
import {
  cloneObject,
  decodeNumberParameter,
  decodeStringParameter,
  getStringFromArrayParameter,
  isEmptyParameter,
  isObjectsEqual,
} from '@/utilities';

interface IDefaultValueDictionaryrs {
  search: string;
  size: number;
  page: number;
  sort: SearchSort;
  recommended: boolean;
  oer: boolean;
}

export type SearchFilter = 'media' | 'educational-standard';

export interface ISearchState {
  search: string;

  size: number;
  page: number;
  sort: SearchSortType,

  searchParameters: null | ISearchRequestParameters;
  searchData: null | ISearchResponse<IMedia>;

  isSearchOpened: boolean;
  isActiveFilterTab: boolean;
  appliedFilter: null | SearchFilter;
  allowCloseSearch: boolean;
}

export const defaultValueDictionary: IDefaultValueDictionaryrs = {
  search: '',
  size: 24,
  page: 0,
  sort: SearchSort.RELEVANCE,
  recommended: false,
  oer: false
};

export const getSearchRequestParametersFromSearchQueryParameters 
  = (query: ISearchQueryParameters, mcAllowed: boolean): ISearchRequestParameters => {
  const {
    search,
    sort,
    page,
    mc,
    size,
    recommended,
    oer,
    subject,
    targetGroup,
    mediaType,
    loanable,
    educationalStandard,
    aspect
  } = query;
  const searchParameters: ISearchParameters = {
    search: search ? search : '',
    sorting: sort ? sort : defaultValueDictionary.sort,
    ...(page && {page}),
    ...(size && {size}),
    ...(mcAllowed && {mc})
  };
  const expertSearch: IExpertSearchParameters = {
    ...(recommended && {recommendedMedia: recommended}),
    ...(oer && {oer}),
    ...(subject && {subjectList: subject}),
    ...(targetGroup && {targetGroupList: targetGroup}),
    ...(mediaType && {mediaTypeList: mediaType}),
    ...(loanable && {loanableList: loanable}),
    ...(educationalStandard && {educationalStandardList: educationalStandard}),
    ...(aspect && {aspectList: aspect}),
  };
  return Object.keys(expertSearch).length
    ? {...searchParameters, expertSearch}
    : searchParameters;
};

const getQuery = (data: ISearchQueryParameters): ISearchQueryStringParameters => {
  return (Object.keys(data) as (keyof ISearchQueryParameters)[])
    .reduce(
      (result: IDictionary<string>, key: keyof ISearchQueryParameters) => {
        const item: undefined | SearchSortType | string | number | boolean | number[] = data[key];
        if (isEmptyParameter(item)) {
          return result;
        }
        if (Array.isArray(item)) {
          result[key] = getStringFromArrayParameter(item);
        } else {
          result[key] = item!.toString();
        }
        return result;
      },
      {}
    );
};

const redirect = (data: ISearchQueryParameters): Promise<Route> => {
  const sid = router.currentRoute.query.sid
    ? router.currentRoute.query.sid
    : null;
  const query = sid 
    ? ({...getQuery(data), sid})
    : getQuery(data);
  const location = {
    name: 'search',
    query,
  } as RawLocation;
  return router
    .push(location);
};

const initialState = (): ISearchState => ({
  search: defaultValueDictionary.search,

  size: defaultValueDictionary.size,
  page: defaultValueDictionary.page,
  sort: defaultValueDictionary.sort,

  searchParameters: null,
  searchData: null,

  isSearchOpened: false,
  isActiveFilterTab: false,
  appliedFilter: null,
  allowCloseSearch: false,
});

const state = initialState();

const getters: GetterTree<ISearchState, RootState> = {
  getSearchField: (state: ISearchState) => getField(state),

  search: (state: ISearchState): string => state.search,

  page: (state: ISearchState): number => state.page,

  searchParameters: (state: ISearchState): null | ISearchQueryParameters => state.searchParameters,

  isActiveFilterTab: (state: ISearchState): boolean => state.isActiveFilterTab,

  getTotalPages: (state: ISearchState): number => state.searchData
    ? state.searchData.responseObjects.totalPages
    : 0,
  getTotalElements: (state: ISearchState): number => state.searchData
    ? state.searchData.responseObjects.totalElements
    : 0,
  getLastSearch: (state: ISearchState): null | string => state.searchParameters && state.searchParameters.search
    ? state.searchParameters.search
    : null,
  
  getIsSearchByEducationStandards: (state: ISearchState): boolean => state.appliedFilter === 'educational-standard',

  getSearchQueryRootParameters: (state: ISearchState, getters): ISearchQueryRootParameters => {
    const parameters: ISearchQueryRootParameters = {};
    if (state.search !== defaultValueDictionary.search) {
      parameters.search = state.search;
    }
    if (state.sort !== defaultValueDictionary.sort) {
      parameters.sort = state.sort;
    }
    if (state.size !== defaultValueDictionary.size) {
      parameters.size = state.size;
    }
    if (state.page !== defaultValueDictionary.page) {
      parameters.page = state.page + 1;
    }
    if (getters.getUserAvailableMZLength > 1) {
      parameters.mc = getters.getMediaCenterId!;
    }
    return parameters;
  },

  getSearchQueryFiltersParameters: (state: ISearchState, getters): IExpertSearchQueryParameters => {
    const parameters: IExpertSearchQueryParameters = {
      ...getters.selectedFilters
    };
    const educationalStandard = [...getters.educationalStandard, ...getters.selectedEducationalStandardFilters];
    if (educationalStandard.length) {
      parameters.educationalStandard = educationalStandard;
    }
    const aspect = getters.selectedAspect;
    if (aspect.length) {
      parameters.aspect = aspect;
    }
    if (getters.recommended !== defaultValueDictionary.recommended) {
      parameters.recommended = getters.recommended;
    }
    if (getters.oer !== defaultValueDictionary.oer) {
      parameters.oer = getters.oer;
    }
    return parameters;
  },

  getSearchQueryParameters: (state: ISearchState, getters): ISearchQueryParameters => {
    return {
      ...getters.getSearchQueryRootParameters,
      ...getters.getSearchQueryFiltersParameters
    };
  },
  getSearchParameters: (state: ISearchState, getters): ISearchRequestParameters => {
    const parameters: ISearchRequestParameters = {
      search: state.search,
      sorting: state.sort
    };
    if (state.size !== defaultValueDictionary.size) {
      parameters.size = state.size;
    }
    if (state.page !== defaultValueDictionary.page) {
      parameters.page = state.page;
    }
    if (getters.getUserAvailableMZLength > 1) {
      parameters.mc = getters.getMediaCenterId;
    }
    const expertSearch: IExpertSearchParameters = {
      ...getters.selectedFilters,
    };
    if (getters.recommended !== defaultValueDictionary.recommended) {
      expertSearch.recommendedMedia = getters.recommended;
    }
    if (getters.oer !== defaultValueDictionary.oer) {
      expertSearch.oer = getters.oer;
    }
    const aspectList = getters.selectedAspect;
    if (aspectList.length) {
      expertSearch.aspectList = aspectList;
    }
    const educationalStandard = [...getters.educationalStandard, ...getters.selectedEducationalStandardFilters];
    if (educationalStandard.length) {
      expertSearch.educationalStandardList = educationalStandard;
    }
    if (Object.keys(expertSearch).length) {
      parameters.expertSearch = expertSearch;
    }
    return parameters;
  },

  getSearchResultList: (state: ISearchState): IMedia[] => state.searchData ? state.searchData.responseObjects.content : [],
  getFiltersData: (state: ISearchState): null | ExpertSearchFilterDictionary<IFilterItem[]> => {
    if (state.searchData === null) {
      return null;
    }
    const {
      subjectList,
      targetGroupList,
      mediaTypeList,
      loanableList
    } = state.searchData.filters;
    return {
      subjectList,
      targetGroupList,
      mediaTypeList,
      loanableList
    };
  },
  getAspectData: (state: ISearchState): null | IFilterItem[] => {
    if (state.searchData === null) {
      return null;
    }
    return state.searchData.filters.aspectList;
  },
  getEducationalStandardsData: (state: ISearchState): null | IEducationalStandardsItem[] => {
    if (state.searchData === null) {
      return [];
    }
    return state.searchData.filters.educationStandardList;
  },
  getAppliedFilter: (state: ISearchState, getters): null | SearchFilter => {
    return getters.isSearchMediaFiltersSelected
      ? 'media'
      : getters.isSearchEducationStandartsFiltersSelected
        ? 'educational-standard'
        : null;
  }
};

const mutations = {
  updateSearchField(state: ISearchState, field: string) {
    return updateField(state, field);
  },
  ['SET_SEARCH'](state: ISearchState, payload: string) {
    state.search = payload;
  },
  ['RESET_SEARCH'](state: ISearchState) {
    state.search = defaultValueDictionary.search;
  },

  ['SET_SIZE'](state: ISearchState, payload: number) {
    state.size = payload;
  },

  ['SET_PAGE'](state: ISearchState, payload: number) {
    state.page = payload;
  },
  ['RESET_PAGE'](state: ISearchState) {
    state.page = defaultValueDictionary.page;
  },

  ['SET_SORT'](state: ISearchState, payload: SearchSortType) {
    state.sort = payload;
  },

  ['SET_SEARCH_PARAMETERS'](state: ISearchState, payload: ISearchRequestParameters) {
    state.searchParameters = payload;
  },
  ['SET_SEARCH_DATA'](state: ISearchState, payload: null | ISearchResponse<IMedia>) {
    //  HACK: Fix streaming media direct url authentication for LTI on Safari
    if (router.currentRoute.query.sid && payload) {
      payload.responseObjects.content.forEach(data => {
        const streamUrl = `/api/download/stream/${data.id}/cid/${router.currentRoute.query.sid}`;
        if (
          data.dataInfo 
          && data.dataInfo.versions.original 
          && data.dataInfo.versions.original.url
          && data.dataInfo.extension !== MediaExtensions.docx
        ) {
          data.dataInfo.versions.original.url = streamUrl;
        }
      });
    }
    state.searchData = payload;
  },

  ['SET_IS_SEARCH_OPENED'](state: ISearchState, payload: boolean) {
    state.isSearchOpened = payload;
  },
  ['SET_IS_ACTIVE_FILTER_TAB'](state: ISearchState, payload: boolean) {
    state.isActiveFilterTab = payload;
  },
  ['SET_APPLIED_FILTER'](state: ISearchState, payload: null | SearchFilter) {
    state.appliedFilter = payload;
  },
  ['SET_ALLOW_CLOSE_SEARCH'](state: ISearchState, payload: boolean) {
    state.allowCloseSearch = payload;
  },
};

const actions: ActionTree<ISearchState, RootState> = {
  parseSearchParameters: ({commit, dispatch, getters}, parameters: ISearchQueryStringParameters = {}): Promise<void> => {
    commit('SET_LOADING', {search: true});

    const {subject, targetGroup, mediaType, loanable, ltiInitial} = parameters;
    const filters = {subject, targetGroup, mediaType, loanable};
    return Promise.all([
      dispatch('parseSearch', parameters.search),
      dispatch('parseSize', parameters.size),
      dispatch('parsePage', parameters.page),
      dispatch('parseSort', parameters.sort),
      dispatch('parseRecommended', parameters.recommended),
      dispatch('parseOer', parameters.oer),
      dispatch('parseSelectedFilters', filters),
      dispatch('parseEducationalStandards', parameters.educationalStandard),
      dispatch('parseSelectedAspect', parameters.aspect),
      dispatch('parseMediaCenter', parameters.mc)
    ]).then(() => {
      const searchParameters = getters.getSearchParameters;
      commit('SET_SEARCH_PARAMETERS', searchParameters);
      return dispatch('getSearchData', {...searchParameters, ltiInitial})
        .then(() => dispatch('redirectFromEmptyToLastPage'));
    });
  },
  parseSearch: ({commit}, parameters?: string): void => {
    commit('SET_SEARCH', decodeStringParameter(parameters, ''));
  },
  parseSize: ({commit}, parameters?: string): void => {
    commit('SET_SIZE', decodeNumberParameter(parameters, defaultValueDictionary.size));
  },
  parsePage: ({commit}, parameters?: string): void => {
    commit('SET_PAGE', decodeNumberParameter(parameters, defaultValueDictionary.page + 1) - 1);
  },
  parseSort: ({commit}, parameters?: string): void => {
    const sortParameter = decodeStringParameter(parameters, defaultValueDictionary.sort) as SearchSortType;
    const sort = typeof SearchSort[sortParameter] === 'undefined' ? defaultValueDictionary.sort : SearchSort[sortParameter];
    commit('SET_SORT', sort);
  },
  parseMediaCenter: ({commit}, parameters?: string): void => {
    const mc = decodeStringParameter(parameters, null);
    if (mc) commit('SET_MEDIA_CENTER', mc);
  },

  getSearchData: ({commit, dispatch}, payload: ISearchRequestParameters): Promise<void> => {
    const {ltiInitial, ...payloadParams} = payload;
    const params = ltiInitial
      ? {...payloadParams, expertSearch: { mediaTypeList: [4] }}
      : payloadParams;
    return SearchApiService.search(params)
      .then((data: ISearchResponse<IMedia>) => {
        return dispatch('setSearchData', data);
      })
      .finally(() => {
        commit('SET_LOADING', {search: false});
      });
  },
  setSearchData: ({commit, dispatch}, data: ISearchResponse<IMedia>): void => {
    commit('SET_SEARCH_DATA', data);
    dispatch('setAspect');
    dispatch('setFilters');
    dispatch('setEducationalStandard');
  },
  redirectToSearch: ({commit, getters}, query: ISearchQueryParameters): Promise<void | Route> => {
    commit('SET_APPLIED_FILTER', getters.getAppliedFilter);

    if (
      query.page === undefined &&
      getters.searchParameters !== null &&
      router.currentRoute.name === 'search' &&
      isObjectsEqual(
        getters.searchParameters,
        getSearchRequestParametersFromSearchQueryParameters(query, getters.getUserAvailableMZLength > 1)
      )
    ) {
      return Promise.resolve();
    }
    commit('SET_IS_ACTIVE_FILTER_TAB', false);
    return redirect(query);
  },

  onChangeSearch: ({commit, dispatch, getters}): Promise<void | Route> => {
    commit('RESET_PAGE');
    return dispatch('redirectToSearch', getters.getSearchQueryRootParameters);
  },
  onChangeEducationalStandard: ({commit, dispatch, getters}): Promise<void | Route> => {
    commit('RESET_SEARCH');
    commit('RESET_PAGE');
    const parameters: ISearchQueryParameters = cloneObject(getters.getSearchQueryRootParameters);
    const educationalStandard = getters.getEducationalStandard;
    if (educationalStandard.length) {
      parameters.educationalStandard = educationalStandard;
    }
    return dispatch('redirectToSearch', parameters);
  },
  onChangeSort: ({commit, dispatch, getters}, sort: SearchSortType): Promise<void | Route> => {
    commit('RESET_PAGE');
    commit('SET_SORT', sort);
    return dispatch('setSelectedFilters')
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  onChangeMediaCenter: ({commit, dispatch, getters}): Promise<void | Route> => {
    commit('RESET_PAGE');
    return dispatch('setSelectedFilters')
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  onChangePage: ({commit, dispatch, getters}, page: number): Promise<void | Route> => {
    commit('SET_PAGE', page);
    return dispatch('setSelectedFilters')
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  onChangeFilter: ({dispatch, getters}): Promise<void | Route> => {
    return Promise.all([
      dispatch('setSelectedFilters'),
      dispatch('setSelectedAspect')
    ])
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  onChangeMediaFilter: ({commit, dispatch, getters}): Promise<void | Route> => {
    commit('RESET_SELECTED_ASPECT');
    commit('RESET_EDUCATION_STANDARDS');
    return dispatch('setSelectedFilters')
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  onChangeEducationalStandardsFilter: ({commit, dispatch, getters}): Promise<void | Route> => {
    commit('RESET_SELECTED_FILTERS');
    return dispatch('setSelectedAspect')
      .then(() => dispatch('redirectToSearch', getters.getSearchQueryParameters));
  },
  resetFilters({commit, dispatch, getters}): Promise<void | Route> {
    commit('RESET_PAGE');
    const parameters = getters.getSearchQueryRootParameters;
    return dispatch('redirectToSearch', parameters)
      .then(() => commit('SET_APPLIED_FILTER', null));
  },
  resetSearchOutputParameters({commit, dispatch}): Promise<void | Route> {
    commit('SET_SORT', defaultValueDictionary.sort);
    dispatch('setSelectedFilters');
    return dispatch('resetFilters');
  },
  allowCloseSearch({commit}, payload): void {
    commit('SET_ALLOW_CLOSE_SEARCH', payload);
  },
  redirectFromEmptyToLastPage({dispatch, getters}) {
    if (!getters.getSearchResultList.length && getters.getTotalPages) {
      return dispatch('onChangePage', getters.getTotalPages - 1);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
