


























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import { createHelpers } from 'vuex-map-fields';
const { mapFields: mapUiField } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  computed: {
    ...mapUiField([
      'modalVisibility'
    ]),
  }
})

export default class Modal extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public visible!: boolean;

  @Prop({
    type: String,
    required: false,
    default: '100%'
  })
  public maxWidth!: string;

  @Prop({
    type: String,
    required: false,
    default: 'initial'
  })
  public height!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public backDropClose!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public params!: Record<string, any>;

  public closeModal(): void {
    this.$emit('closeModal');
  }

  public get styleObject(): object {
    return {
      '--max-width': this.maxWidth,
      '--height': this.height
    };
  }
}
