







import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IDictionary} from '@/interfaces/interfaces';

@Component({
  inheritAttrs: false
})
export default class InputError extends Vue {

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public isShowSingleError!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorMessageList!: IDictionary<string>;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorState!: IDictionary<boolean>;

  public get errorList(): string[] {
    if (!this.errorState.$dirty) {
      return [];
    }
    const errorStateFiltered: string[] = Object.keys(this.errorState)
      .filter((key: string) => key.charAt(0) !== '$' && !this.errorState[key]);
    return errorStateFiltered
      .map((key: string) => this.errorMessageList[key] ? this.errorMessageList[key] : key);
  }
}
