








































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import InputCloseIcon from '@/assets/icon/input-close.svg';
import Chevron from '@/components/UI/Icons/Chevron.vue';

// @ts-ignore
import pdf from 'vue-pdf';

export type PdfViewerSizeOption = {
  title: string;
  value: string;
}

@Component({
  components: {
    pdf,
    InputCloseIcon,
    Chevron
  }
})
export default class PdfViewer extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public url!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public visible!: boolean;

  private loading: boolean = false;
  private numPages: number | null = null;
  private currPage: number = 1;

  private scaleOptions: Record<string, PdfViewerSizeOption> = {
    autoZoom: {
      title: 'Automatischer Zoom',
      value: '841px'
    },
    pageFit: {
      title: 'Seitengröße',
      value: 'calc(((var(--vh, 1vh) * 100) - 64px - 24px) * 1262 / 1785)'
    },
    pageWidth: {
      title: 'Seitenbreite',
      value: '1348px'
    }
  }

  private scale = this.scaleOptions.autoZoom;

  private loadingTask = pdf.createLoadingTask(this.url);
  private src = this.loadingTask;
  
  public mounted() {
    this.loading = true;
    this.src.promise
      .then((pdf: any) => {
        this.numPages = pdf.numPages;
      })
      .catch(() => {
        console.warn('error');
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Ups! Irgendwas lief schief...',
          text: 'Server hat nicht geantwortet. Bitte versuchen Sie es später erneut.'
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private isNumber(evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }

  private scrollToPage(page: number) {
    if (this.currPage > this.numPages! || this.currPage < 1) { return; }
    const target = document.querySelector(`#pdfpage${page}`)!;
    target.scrollIntoView({behavior: 'smooth'});
  }

  private scrollToPageUp() {
    if (this.currPage === this.numPages) { return; }
    this.currPage++;
    this.scrollToPage(this.currPage);
  }
  private scrollToPageDown() {
    if (this.currPage === 1) { return; }
    this.currPage--;
    this.scrollToPage(this.currPage);
  }

  private setScale(forward: boolean) {
    const options = Object.values(this.scaleOptions);
    const currPosition = options.indexOf(this.scale);

    forward
      ? currPosition < options.length - 1
        ? this.scale = options[currPosition + 1]
        : this.scale = options[0]
      : currPosition !== 0
        ? this.scale = options[currPosition - 1]
        : this.scale = options[options.length - 1];
  }
}
