

















































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})

export default class TermsOfUse extends Vue {

  public accept(): void {
    this.$emit('closeModal');
  }
}
