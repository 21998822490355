import {getField, updateField} from 'vuex-map-fields';
import {IMedia} from '@/services/api/media.service';
import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import ChartApiService from '@/services/api/charts.service';

export interface IChartState {
  chartList: IMedia[];
}
const initialState = (): IChartState => ({
  chartList: []
});
const state = initialState();
const getters = {
  getChartField: (state: IChartState) => getField(state)
};
const mutations = {
  ['SET_CHART_LIST'](state: IChartState, payload: IMedia[]) {
    state.chartList = payload;
  },
  updateChartField(state: IChartState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IChartState, RootState> = {
  async getChartList({commit}) {
    ChartApiService.getChartList()
      .then((data: IMedia[]) => {
        commit('SET_CHART_LIST', data);
      })
      .catch(() => {
        commit('SET_CHART_LIST', []);
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
