






















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {ITab} from '@/components/UI/Tabs/interfaces';

@Component({})
export default class Tabs extends Vue {

  @Prop({
    type: String,
    required: false,
    default: 'white'
  })
  private mode!: 'gray' | 'white';

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private listCustomClass!: string;

  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  private activeTab!: number;

  private active: number = -1;
  private enabledTabCount: number = 0;
  private tabList: ITab[] = [];

  private get classObject(): object {
    return {
      [`tabs-${this.mode}`]: true,
    };
  }

  private updated(): void {
    this.updateTabs();
    this.updateActiveTab();
  }
  private mounted(): void {
    this.updateTabs();
    this.updateActiveTab();
    this.$emit('activate');
  }

  private updateTabs(): void {
    this.tabList = this.$children as ITab[] || [];
    this.enabledTabCount = this.tabList
      .filter((tab: ITab) => !tab.disabled && !tab.hidden)
      .length;
  }

  private updateActiveTab(): void {
    if (this.active === -1) {
      this.active = this.activeTab;
    }
    if (this.active > this.tabList.length - 1) {
      this.active = 0;
    }
    const tab = this.tabList[this.active];
    if (!tab || !this.isTabEnabled(tab)) {
      const array = this.tabList.reduce(
        (result: number[], tab: ITab, index: number) => this.isTabEnabled(tab)
          ? [...result, index]
          : result,
        []
      );
      this.active = this.getClosest(array, this.active);
    }
    this.setTab(this.active);
  }

  private getClosest(array: number[], target: number): number {
    return array.reduce(
      (previous: number, current: number) => Math.abs(current - target) < Math.abs(previous - target)
        ? current
        : previous
    );
  }

  private setTab(tabIndex: number): void {
    const tab = this.tabList[tabIndex];
    if (!this.isTabEnabled(tab)) {
      return;
    }
    this.active = tabIndex;
    this.tabList.map(
      (tab: ITab, index: number) => {
        tab.isActive = (index === this.active);
      }
    );
  }

  private isTabEnabled(tab: ITab): boolean {
    return !tab.disabled && !tab.hidden;
  }
}
