




































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

const defaultSize = 24;
@Component({})
export default class IconButton extends Vue {

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public icon!: string | null;

  @Prop({
    type: String,
    required: false,
    default: 'button'
  })
  public tag!: 'a' | 'button';

  @Prop({
    type: String,
    required: true
  })
  private title!: string;

  @Prop({
    type: [Number, String],
    required: false,
    default: defaultSize
  })
  private size!: number;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  private mode!: 'primary' | 'secondary' | null;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private isLoading!: boolean;

  private get iconSize(): number {
    return this.size < 0 || this.size > 40 ? defaultSize : this.size;
  }

  private get classObject(): object {
    return {
      [`${this.mode}-icon-button`]: this.mode,
      'loading-icon-button': this.isLoading
    };
  }
}
