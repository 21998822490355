













































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {IEducationalStandard} from '@/services/api/educational-standarts.service';
import EducationalStandardsSelect from '@/components/Layout/Header/EducationalStandardsSelect.vue';

const { mapFields } = createHelpers({
  getterType: 'getEducationalStandardsField',
  mutationType: 'updateEducationalStandardsField',
});

@Component({
  components: { EducationalStandardsSelect },
  computed: {...mapFields([
      'schoolType',
      'subject',
      'classroom'
    ])
  }
})
export default class EducationalStandardsMenu extends Vue {
  private schoolType!: null | IEducationalStandard;
  private subject!: null | IEducationalStandard;
  private classroom!: null | IEducationalStandard;

  public checkSchoolType() {
    if (!this.$store.getters.schoolTypeList.length) {
      this.$store.dispatch('getSchoolTypeList');
    }
  }
  public setSchoolType(value: IEducationalStandard) {
    this.$store.dispatch('setSchoolType', value);
  }

  public checkSubject() {
    if (!this.$store.getters.subjectList.length) {
      this.$store.dispatch('getSubjectList');
    }
  }
  public setSubject(value: IEducationalStandard) {
    this.$store.dispatch('setSubject', value);
  }

  public checkClassroom() {
    if (!this.$store.getters.classroomList.length) {
      this.$store.dispatch('getClassroomList');
    }
  }
  public setClassroom(value: IEducationalStandard) {
    this.$store.dispatch('setClassroom', value);
  }

  public onSearch() {
    this.$store.dispatch('onChangeEducationalStandard');
  }
}
