import store from '@/store';
import { RouteConfig } from 'vue-router';
import router from '..';

const productionRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "Main" */ '@/pages/Main/Main.page.vue'),
    beforeEnter: (to, from, next) => {
      !router.currentRoute.query.sid
        ? next()
        : next({name: 'not-found', params: { '0': to.path }});
    },
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/media-center-select',
    name: 'media-center-select',
    component: () => import(/* webpackChunkName: "SelectMediaCenter" */ '@/pages/SelectMediaCenter.vue'),
    beforeEnter: (to, from, next) => {
      !router.currentRoute.query.sid
        ? next()
        : next({name: 'not-found', params: { '0': to.path }});
    },
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/search/:pageNumber?',
    name: 'search',
    component: () => import(/* webpackChunkName: "Search" */ '@/pages/Search/Search.page.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    beforeEnter: (to, from, next) => {
      store.getters.permission('useBookmarks')
        ? next()
        : next({name: 'not-found', params: { '0': to.path }});
      !router.currentRoute.query.sid
        ? next()
        : next({name: 'not-found', params: { '0': to.path }});
    },
    component: () => import(/* webpackChunkName: "Bookmarks" */ '@/pages/Bookmarks/Bookmarks.page.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/shared/:id',
    name: 'shared',
    component: () => import(/* webpackChunkName: "Shared" */ '@/pages/Shared/Shared.page.vue'),
    beforeEnter: (to, from, next) => {
      !router.currentRoute.query.sid
        ? next()
        : next({name: 'not-found', params: { '0': to.path }});
    },
    props: true,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/details/:id',
    name: 'details',
    component: () => import(/* webpackChunkName: "Details" */ '@/pages/Details/Details.page.vue'),
    props: true,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/lti-keys',
    name: 'lti-keys',
    beforeEnter: (to, from, next) => {
      !store.getters.isSelfRegisteredUser
        ? next()
        : next('/');
    },
    component: () => import(/* webpackChunkName: "LtiKeys" */ '@/pages/Lti/LtiKeys.page.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/mediathek',
    name: 'mediathek',
  },
  {
    path: '/lti-media/:id',
    name: 'lti-media',
    component: () => import(/* webpackChunkName: "LtiMedia" */ '@/pages/Lti/LtiMedia.page.vue'),
    props: true,
    meta: {
      layout: 'frame'
    },
  },
  {
    path: '/public/:id',
    name: 'public',
    component: () => import(/* webpackChunkName: "Public" */ '@/pages/Public/Public.page.vue'),
    props: true,
    meta: {
      layout: 'frame',
      public: true
    }
  },
  {
    path: '/sl/stream/:id',
    name: 'stream',
    meta: {
      public: true
    }
  },
  {
    path: '*',
    name: 'not-found',
    props: {errorCode: 400},
    component: () => import(/* webpackChunkName: "Error" */ '@/pages/Error.vue')
  }
];

export default productionRoutes;
