












import Vue from 'vue';
import Component from 'vue-class-component';
import InputCloseIcon from '../../assets/icon/input-close.svg';

@Component({
  components: {
    InputCloseIcon
  }
})
export default class LoadingIndicator extends Vue {}
