import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export interface IEducationalStandard {
  id: number;
  parentId: number;
  level: number;
  name: string;
  sort: string;
  fullName: string;
  codeNew: string;
  hasChildren: boolean;
}
export interface IEducationalStandardsApiService {
  getEducationalStandardsList: (parentId?: number) => Promise<IEducationalStandard[]>;
  getEducationalStandardsPath: (id?: number) => Promise<IEducationalStandard[]>;
}

const getEducationalStandardsList = (parentId?: number): Promise<IEducationalStandard[]> => {
  return api
    .get(
      '/educational_standarts',
      {
        params: { parentId },
        data: null
      }
    )
    .then((response: AxiosResponse<IEducationalStandard[]>) => response.data)
    .catch((error) => Promise.reject(error));
};
const getEducationalStandardsPath = (id?: number): Promise<IEducationalStandard[]> => {
  return api
    .get(
      '/educational_standarts/path',
      {
        params: { id },
        data: null
      }
    )
    .then((response: AxiosResponse<IEducationalStandard[]>) => response.data)
    .catch((error) => Promise.reject(error));
};

const EducationalStandardsApiService: IEducationalStandardsApiService = {
  getEducationalStandardsList,
  getEducationalStandardsPath
};

export default EducationalStandardsApiService;
