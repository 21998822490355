














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CopyIcon from '@/assets/icon/ico-copy.svg';

@Component({
  components: {
    CopyIcon
  }
})
export default class CopyToClipBoard extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public value!: string;

  @Prop({
    type: String,
    required: false,
    default: 'Kopieren'
  })
  public title!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public reversed!: boolean;

  public doCopy() {
    this.$copyText(this.value);
  }

  public onCopy() {
    this.$emit('copySuccess');
  }
}
