// eslint-disable-next-line
export type Callback = (...args: any[]) => void
export const debounce = (callback: Callback, timeout: number = 20): Callback => {
  let handle = 0;
  const callable = (...args: any) => {
    clearTimeout(handle);
    handle = window.setTimeout(() => callback(...args), timeout);
  };
  return callable;
};

