


















import Vue from 'vue';
import Component from 'vue-class-component';
import BookmarksIcon from '@/assets/icon/bookmarks.svg';

@Component({
  components: { BookmarksIcon }
})
export default class Bookmarks extends Vue {
  public BookmarksIcon = BookmarksIcon;
}
