












import Vue from 'vue';
import Component from 'vue-class-component';
import {ILinkItem} from '@/interfaces/interfaces';
import {scrollTo} from '@/utilities';

@Component({})
export default class HeaderMenu extends Vue {

  public linkList: ILinkItem[] = [
    {
      link: '#benefits',
      text: 'Vorteile'
    },
    {
      link: '#functionality',
      text: 'Wie funktioniert’s?'
    },
    {
      link: '#map',
      text: 'Medienzentren'
    },
  ];

  public scrollTo(selector: string) {
    scrollTo(selector, 'smooth');
  }
}
