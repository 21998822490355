



























import Vue from 'vue';
import Component from 'vue-class-component';
import { IModal } from '@/interfaces/interfaces';

import LoginForm from '@/components/Form/Login.form.vue';
import ResetPasswordForm from '@/components/Form/ResetPassword.form.vue';
import ResetPasswordSuccess from '@/components/Form/ResetPassword.success.form.vue';
import RegisterForm from '@/components/Form/Register.form.vue';
import RegisterLicense from '@/components/Form/Register.license.vue';
import RegisterSuccess from '@/components/Form/Register.success.vue';
import ProfileForm from '@/components/Form/Profile.form.vue';
import Iframe from '@/components/UI/Iframe.vue';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    LoginForm,
    ResetPasswordForm,
    ResetPasswordSuccess,
    RegisterForm,
    RegisterLicense,
    RegisterSuccess,
    Iframe
  },
  computed: {...mapFields([
      'modalState'
    ]),
  }
})

export default class HeaderModals extends Vue {
  public url: string = '';

  public mounted() {
    this.$root.$on('openHeaderModal', (id: string) => this.openModal(id));
    this.$root.$on('closeHeaderModal', () => this.closeModal());
  }

  private modals: IModal[] = [
    {
      id: 'login',
      title: 'Anmelden',
      maxWidth: '560px',
      component: LoginForm
    },
    {
      id: 'resetPassword',
      title: 'Passwort zurücksetzen',
      maxWidth: '560px',
      component: ResetPasswordForm
    },
    {
      id: 'changePassword',
      title: 'Passwort ändern',
      maxWidth: '560px',
      component: ResetPasswordForm,
      type: 'change'
    },
    {
      id: 'resetPasswordSuccess',
      title: 'Passwort vergessen',
      maxWidth: '560px',
      component: ResetPasswordSuccess
    },
    {
      id: 'changePasswordSuccess',
      title: 'Passwort ändern',
      maxWidth: '560px',
      component: ResetPasswordSuccess
    },
    {
      id: 'register',
      title: 'Zugang anfordern',
      maxWidth: '560px',
      component: RegisterForm
    },
    {
      id: 'registerLicense',
      title: 'Einwilligungserklärung',
      maxWidth: '955px',
      component: RegisterLicense
    },
    {
      id: 'registerSuccess',
      title: 'Registrierung erfolgreich',
      maxWidth: '560px',
      component: RegisterSuccess
    },
    {
      id: 'profile',
      title: 'Benutzerdaten',
      maxWidth: '560px',
      component: ProfileForm
    },
    {
      id: 'rentalFrame',
      title: 'Verleihkonto',
      maxWidth: '1200px',
      component: Iframe,
      height: '50vh'
    }
  ];

  private visibleData: boolean[] = [];

  private setDefaultVisibleData() {
    this.visibleData = [...Array(this.modals.length).fill(false)];
  }

  private getIndexById(id: string): number {
    return this.modals
      .findIndex((modal: IModal) => modal.id === id);
  }

  public openModal(id: string, url?: string): void {
    url ? this.url = url : this.url = '';
    this.setDefaultVisibleData();
    this.visibleData
      .splice(this.getIndexById(id), 1, true);
  }

  public closeModal(): void {
    this.$store.commit('RESET_MODALS');
    this.setDefaultVisibleData();
  }
}
