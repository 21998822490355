import store from '@/store';
import { IRouterGuard } from '@/interfaces/interfaces';
import Vue from 'vue';

export const mainGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;
  
  store.commit('SET_ERROR_CODE', null);
  store.commit('SET_ERROR_MESSAGE', null);

  if (to.meta.public) {
    store.commit('SET_LOADING', {user: false});
    return nextGuard!({from, to, next});
  } else {
    if (to.name === 'shared') {
      const id: undefined | string = to.params.id;
      if (id) {
        return store.dispatch('loginById', id)
          .then(() => {
            if (!store.getters.getUserId) return nextGuard!({from, to, next});
            nextGuard!({from, to, next});
            store.dispatch('getSharedData', {bookmarkId: store.getters.getUserSharedBookmarkId})
              .then(() => {
                if (!store.getters.getUserRevelant) {
                  Vue.prototype.$notify({
                    group: 'app',
                    type: 'error',
                    title: 'Der Freigabezeitraum ist überschritten',
                    text: 'Die freigegebenen Medien stehen leider nicht mehr zur Verfügung.'
                  });
                }
              });
          });
      } else {
        return next({name: 'not-found', params: { '0': to.path }});
      }
    }
  
    if (to.name === 'details' && store.getters.permission('student')) {
      return nextGuard!({from, to, next});
    }
  
    if (to.query.sid) {
      store.dispatch('loginByPid', to.query.sid)
        .then(() => nextGuard!({from, to, next}));
    } else {
      const promise = !store.getters.getUserField('isInitialized') && !store.getters.getUserField('isInitializing')
      ? store.dispatch('initialize')
      : Promise.resolve();
  
    promise
      .then(() => {
        const confirmParams = {
          code: to.query.code,
          email: to.query.email
        };

        if (to.query.open) {
          store.commit('SET_MODAL', to.query.open);
        }
        
        if (confirmParams.code && confirmParams.email) {
          store.dispatch('confirmRegistration', confirmParams);
        }
  
        if (to.name === 'search') {
          store.getters.permission('useSearch')
          ? nextGuard!({from, to, next})
          : next({name: 'not-found', params: { '0': to.path }});
        }
        else if (to.name === 'media-center-select') {
          if (store.getters.getMediaCenterField('isMediaCenterSet') || !store.getters.getUserField('isAnonymousUser')) {
            return next({name: 'main'});
          } else {
            return nextGuard!({from, to, next});
          }
        } 
        else if (to.name === 'mediathek') {
          const query = to.query || null;
          store.dispatch('initialize')
            .then(() => {
              if (!store.getters.getMediaCenterField('isMediaCenterSet')) {
                store.dispatch('setMediaCenter', 0).then(() => next(`/search?search=${query.search}`));
              } else {
                return next(`/search?search=${query.search}${store.getters.getMediaCenterId ? '&mc=' + store.getters.getMediaCenterId : ''}`);
              }
            });
        } 
        else {
          if (!store.getters.getMediaCenterField('isMediaCenterSet') && store.getters.getUserField('isAnonymousUser')) {
            store.commit('SET_REDIRECT', to.path || null);
            return next({name: 'media-center-select'});
          } else {
            return nextGuard!({from, to, next});
          }
        }
      })
      .catch(() => nextGuard!({from, to, next}));
    }
  }
};
