export interface IStorageService {
  set: (key: string, value: any, suffix?: string) => boolean;
  get: <T>(key: string, suffix?: string) => null | T;
  remove: (key: string, suffix?: string) => boolean;
  removeList: (keyList: string[]) => boolean;
  keys: (suffix?: string) => string[];
  clear: (suffix?: string) => boolean;
}

const storage: Storage = (window as Window).localStorage;

const getIsSupported = (): boolean => {
  try {
    const supported: boolean = 'localStorage' in window && storage !== null;
    if (supported) {
      // When Safari (OS X or iOS) is in private browsing mode, it
      // appears as though localStorage is available, but trying to
      // call .setItem throws an exception.
      //
      // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
      // to add something to storage that exceeded the quota."
      const key = `__${Math.round(Math.random() * 1e7)}`;
      storage.setItem(key, '');
      storage.removeItem(key);
    }
    return supported;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

const isSupported: boolean = getIsSupported();

const checkSupport = (): boolean => {
  if (!isSupported) {
    console.warn('Local storage not supported');
    return false;
  }
  return true;
};

const setSuffix = (
  key: string,
  suffix: string,
  delimiter: string = '-'
): string => {
  return `${suffix}${delimiter}${key}`;
};

const set = (key: string, value: any, suffix?: string): boolean => {
  if (checkSupport()) {
    if (typeof suffix !== 'undefined') {
      key = setSuffix(key, suffix);
    }
    if (typeof value === 'undefined') {
      value = null;
    } else {
      value = JSON.stringify(value);
    }
    try {
      if (storage) {
        storage.setItem(key, value);
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
    return true;
  }
  return false;
};

const get = <T>(key: string, suffix?: string): T | null => {
  let item = null;
  if (!checkSupport()) {
    return item;
  }
  if (typeof suffix !== 'undefined') {
    key = setSuffix(key, suffix);
  }
  item = storage ? storage.getItem(key) : null;
  if (item === null) {
    return item;
  }
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

const remove = (key: string, suffix?: string): boolean => {
  if (checkSupport()) {
    let result = true;
    if (typeof suffix !== 'undefined') {
      key = setSuffix(key, suffix);
    }
    try {
      storage.removeItem(key);
    } catch (error) {
      console.error(error.message);
      result = false;
    }
    return result;
  }
  return false;
};

const removeList = (keyList: string[]): boolean => {
  if (checkSupport()) {
    let result = true;
    keyList.forEach((key: string) => {
      try {
        storage.removeItem(key);
      } catch (error) {
        console.error(error.message);
        result = false;
      }
    });
    return result;
  }
  return false;
};

const keys = (suffix?: string): string[] => {
  const keyList: string[] = [];
  if (checkSupport()) {
    for (const key of Object.keys(storage)) {
      try {
        if (typeof suffix !== 'undefined') {
          if (key.startsWith(`${suffix}-`)) {
            keyList.push(key.replace(new RegExp(`^(${suffix}-)`), ''));
          }
        } else {
          keyList.push(key);
        }
      } catch (error) {
        console.error(error.message);
        return keyList;
      }
    }
  }
  return keyList;
};

const clear = (suffix?: string): boolean => {
  if (checkSupport()) {
    for (const key of Object.keys(storage)) {
      try {
        if (typeof suffix !== 'undefined') {
          if (key.startsWith(`${suffix}-`)) {
            remove(key.replace(new RegExp(`^(${suffix}-)`), ''));
          }
        } else {
          remove(key);
        }
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
    return true;
  }
  return false;
};

export const StorageService: IStorageService = {
  get,
  keys,
  set,
  remove,
  removeList,
  clear,
};
