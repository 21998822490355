import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { IDictionary } from '@/interfaces/interfaces';

export interface IMedia {
  id: number;
  mediaNumber: number;
  mediaType: MediaType;
  oer: boolean;
  ccIcon: IMediaCCIcon;
  recommended: boolean;
  streaming: boolean;
  download: boolean;
  coverLink: string;
  title: string;
  seriesTitle: string;
  shortContent: string;
  year: string;
  children: boolean;
  nestedObjectsSize: number;
  nestedObjects: IMedia[];
  score: number;
  compiled: string;
  dataInfo: {
    compiled: string;
    originalFilenameBasename: string;
    versions: IMediaVersions;
    extension: string;
  };
  productionInformation: IMediaProductionInfo;
  didacticNotes: string[];
  standardList: IMediaStandard[];
  furtherInformation: IMediaFurtherInfo;
  debugInfo: {
    mask: string;
    license: string[];
    mediaCenter: string;
    allObjectLicensee: IObjectLicense[];
  }
  parentTitle: string;
  parentId: string;
  runningTime: string;
  url: string;
  loanable: boolean;
  loanableMedia: boolean;
  loanableMediaType: string;
  externalObject: boolean;
  loanMailTo: string;
  learningObjectTypes?: string[] | null;
  assetLink?: string | null
}

interface IMediaCCIcon {
  abbreviation: string;
  commentUrl: string;
  iconUrl: string;
  id: number;
  name: string;
}

export interface IMediaVersions {
  original: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  directory: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  preview: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  small: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  the360P: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  aac: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  huge: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
  full: {
    the360_PClass: string;
    url: string;
    width: number;
    status: string;
    hasMoreJobs: boolean;
    height: number;
    filesize: number;
    aspectRatio: number;
    extension: string;
    downloadUrl: string;
    downloadAllowed: boolean;
    notAllowed: boolean;
    dpi: number;
    zoomUrl: string;
    compiled: string;
    dateCreated: string;
  };
}

export interface IMediaProductionInfo {
  author: string[];
  productions: string[];
  countryOfProduction: string[];
  colours: string[];
  fsk: string;
}

export interface IMediaFurtherInfo {
  targetGroups: string[];
  keywords: string[];
  subjects: string[];
  rightsAccording: string;
  links: [
    {
      comment: string;
      url: string;
    }
  ];
  rightOfUse: string;
  timeRange: string;
  locationAssignments: string[][];
  cooperationList: string[];
  lawList: string[];
  comparativeRecord: string;
  fileSize: string;
  systematikList: string[][];
  subjectsThemen: string[][];
  competenceMediaEducation: string[];
  technicalAdvice: string;
}

export interface IMediaStandard {
  id: number | null,
  parentId: number,
  level: number,
  name: string,
  sort: string,
  fullName: string,
  codeNew: string,
  hasChildren: boolean
}

export interface IObjectLicense {
  mz: string;
  licenseType: string;
}

export interface IFileLinks {
  original: IFileLink;
  preview: IFileLink;
  small: IFileLink;
}

export interface IFileLink {
  the360_PClass: string;
  extension: string;
  downloadUrl: string;
  width: number;
  height: number;
  filesize: number;
  dpi?: number;
  aspectRatio?: number;
  status: string
  compiled: string
  dateCreated: string
  hasMoreJobs: boolean;
  url: string;
}

export type MediaType
  = 'MEDIENSAMMLUNG'
  | 'UNTERRICHTSMODUL'
  | 'DVD_VIDEO'
  | 'FILM'
  | 'BILD'
  | 'AUDIO'
  | 'WEBSEITE'
  | 'ARBEITSMATERIAL'
  | 'INTERAKTION'
  | 'SONSTIGES'
  | 'LOSUNGSBLATT'
  | 'ARBEITSBLATT'
  | 'ARBEITSBLATT_INTERAKTIV'
  | 'DIDAKTIK_METHODIK'
  | 'SACHINFORMATION';

export type MediaGroup
  = 'DOCUMENT'
  | 'STREAM'
  | 'COLLECTION';

export type MediaExtension
  = 'zip' | 'webdvd.zip'
  | 'mp4' | 'mov' | 'flv' | 'mpg' | 'wmv'
  | 'jpg' | 'png' | 'tif'
  | 'mp3' | 'wav' | 'ogg'
  | 'html' | 'htm'
  | 'doc' | 'pdf' | 'docx' | 'ppt' | 'pptx' | 'xls' | 'xlsx'
  | 'swf' | 'bin' | 'exe' | 'flipchart' | 'gxt' | 'iso' | 'jcw'
  | 'lld' | 'lsc' | 'lso' | 'mbz' | 'mdb' | 'mtt' | 'ogg' | 'piv'
  | 'stk' | 'sxd' | 'sxi' | 'xtm';

export const MediaTypes: IDictionary<MediaType> = {
  MEDIENSAMMLUNG: 'MEDIENSAMMLUNG',
  UNTERRICHTSMODUL: 'UNTERRICHTSMODUL',
  DVD_VIDEO: 'DVD_VIDEO',
  FILM: 'FILM',
  BILD: 'BILD',
  AUDIO: 'AUDIO',
  WEBSEITE: 'WEBSEITE',
  ARBEITSMATERIAL: 'ARBEITSMATERIAL',
  INTERAKTION: 'INTERAKTION',
  SONSTIGES: 'SONSTIGES',
  LOSUNGSBLATT: 'LOSUNGSBLATT',
  ARBEITSBLATT: 'ARBEITSBLATT',
  ARBEITSBLATT_INTERAKTIV: 'ARBEITSBLATT_INTERAKTIV',
  DIDAKTIK_METHODIK: 'DIDAKTIK_METHODIK',
  SACHINFORMATION: 'SACHINFORMATION'
};

export const MediaGroups: IDictionary<MediaGroup> = {
  DOCUMENT: 'DOCUMENT',
  STREAM: 'STREAM',
  COLLECTION: 'COLLECTION'
};

export const MediaTypesGroups: IDictionary<MediaGroup> = {
  MEDIENSAMMLUNG: 'COLLECTION',
  UNTERRICHTSMODUL: 'COLLECTION',
  DVD_VIDEO: 'COLLECTION',
  FILM: 'STREAM',
  BILD: 'DOCUMENT',
  AUDIO: 'STREAM',
  WEBSEITE: 'DOCUMENT',
  ARBEITSMATERIAL: 'DOCUMENT',
  INTERAKTION: 'DOCUMENT',
  SONSTIGES: 'DOCUMENT',
  LOSUNGSBLATT: 'DOCUMENT',
  ARBEITSBLATT: 'DOCUMENT',
  ARBEITSBLATT_INTERAKTIV: 'DOCUMENT',
  DIDAKTIK_METHODIK: 'DOCUMENT',
  SACHINFORMATION: 'DOCUMENT'
};

export const MediaExtensions: IDictionary<MediaExtension> = {
  zip: 'zip', webdvdzip: 'webdvd.zip',
  mp4: 'mp4', mov: 'mov', flv: 'flv', mpg: 'mpg', wmv: 'wmv',
  jpg: 'jpg', png: 'png', tif: 'tif',
  mp3: 'mp3', ogg: 'ogg', wav: 'wav',
  html: 'html', htm: 'htm',
  doc: 'doc', pdf: 'pdf', docx: 'docx', ppt: 'ppt', pptx: 'pptx', xls: 'xls', xlsx: 'xlsx',
  swf: 'swf', bin: 'bin', exe: 'exe', flipchart: 'flipchart', gxt: 'gxt', iso: 'iso', jcw: 'jcw',
  lld: 'lld', lsc: 'lsc', lso: 'lso', mbz: 'mbz', mdb: 'mdb', mtt: 'mtt', piv: 'piv', stk: 'stk',
  sxd: 'sxd', sxi: 'sxi', xtm: 'xtm'
};

export const MediaVideoExtensions: MediaExtension[] = [
  'mp4', 'mov', 'flv', 'mpg', 'wmv'
];

export const MediaAudioExtensions: MediaExtension[] = [
  'mp3', 'ogg', 'wav'
];

export interface IMediaApiService {
  getMediaData: (id: number) => Promise<IMedia>,
  embedMedia: (id: number) => Promise<void>,
  openExternalMediaLink: (id: number) => Promise<IMediaExternalLinkResponse>
  getRentalMediaLink: (id: number) => Promise<IMediaRentalLinkResponse>
}

export interface ILtiResponse {
  url: string;
  payload: IDictionary<string>;
}

export interface IMediaExternalLinkResponse {
  url: string;
}
export interface IMediaRentalLinkResponse {
  loanUrl: string;
}

const getMediaData = (id: number) => api
  .get(`/details/${id}`)
  .then((response: AxiosResponse<IMedia>) => response.data)
  .catch((error) => Promise.reject(error));

const embedMedia = (id: number) => api
  .post(`/ltimanager/${id}`)
  .then(
    (response: AxiosResponse<ILtiResponse>) => {
      const {url, payload} = response.data;
      const form = document.createElement('form') as HTMLFormElement;
      form.action = url;
      form.method = 'POST';
      for (const name of Object.keys(payload)) {
        const node = document.createElement('input') as HTMLInputElement;
        node.name = name;
        node.type = 'hidden';
        node.value = payload[name];
        form.appendChild(node);
      }
      document.body.appendChild(form);
      form.submit();
    }
  )
  .catch((error) => Promise.reject(error));

const openExternalMediaLink = (id: number) => api
  .get(`external_link/${id}`)
  .then((response: AxiosResponse<IMediaExternalLinkResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const getRentalMediaLink = (id: number) => api
  .get(`loan?id=${id}`)
  .then((response: AxiosResponse<IMediaRentalLinkResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const MediaApiService: IMediaApiService = {
  getMediaData,
  openExternalMediaLink,
  getRentalMediaLink,
  embedMedia
};

export default MediaApiService;
