











































import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {
  IDictionary,
  IInputAction,
  InputActionData,
  InputActionDictionary,
  InputActionPosition, InputActionType, InputType, InputTypeActionDictionary
} from '@/interfaces/interfaces';
import {ISuggest} from '@/services/api/search.service';
import InputAction from '@/components/UI/Input/InputAction.vue';
import InputActionClear from '@/components/UI/Input/InputActionClear.vue';
import InputActionPassword from '@/components/UI/Input/InputActionPassword.vue';
import InputSuggest from '@/components/UI/Input/InputSuggest.vue';
import InputActionLoading from '@/components/UI/Input/InputActionLoading.vue';
import EnvelopeIcon from '@/assets/icon/envelope.svg';
import InputLockIcon from '@/assets/icon/input-lock.svg';

@Component({
  components: { InputAction, InputActionClear, InputActionPassword, InputSuggest, EnvelopeIcon, InputLockIcon },
  inheritAttrs: false
})
export default class Input extends Vue {

  @Prop({
    type: Boolean,
    required: false,
    default: null
  })
  public loading!: null | boolean;

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public icon!: null | VueConstructor<Vue>;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  public actionList!: InputActionData[];

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  public suggestList!: ISuggest[];

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public isShowSingleError!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorMessageList!: IDictionary<string>;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorState!: IDictionary<boolean>;

  @Watch('actionList', { immediate: true, deep: true })
  private onActionListChanged(actionList: InputActionData[]): void {
    this.setActionDictionary(actionList);
  }

  public isPassword: boolean = false;
  public isPasswordVisible: boolean = false;

  public actionDictionary: IDictionary<IInputAction[]> = {
    left: [],
    right: []
  };

  private getValue(): string {
    return this.$attrs.value;
  }

  private getLoading(): boolean {
    return !!this.loading;
  }

  private getIsPassword(): boolean {
    return this.isPassword;
  }

  private getIsPasswordVisible(): boolean {
    return this.isPasswordVisible;
  }

  private inputTypeActionDictionary: InputTypeActionDictionary = {
    email: [
      {
        position: 'left',
        icon: EnvelopeIcon
      },
    ],
    password: [
      {
        position: 'left',
        icon: InputLockIcon
      },
      InputActionType.password
    ]
  }

  private inputActionDictionary: InputActionDictionary = {
    password: {
      component: InputActionPassword,
      props: {
        isPassword: this.getIsPassword.bind(this),
        isPasswordVisible: this.getIsPasswordVisible.bind(this),
        action: this.togglePasswordVisibility.bind(this)
      }
    },
    clear: {
      component: InputActionClear,
      props: {
        value: this.getValue.bind(this),
        title: 'Löschen',
        action: this.clearInput.bind(this)
      }
    },
    loading: {
      component: InputActionLoading,
      props: {
        title: 'Wird geladen',
        value: this.getLoading.bind(this),
      }
    }
  };

  private created() {
    this.isPassword = this.$attrs.type === 'password';
  }

  public get classObject(): object {
    return {
      error: this.errorState.$invalid && this.errorState.$dirty
    };
  }

  public get inputClassObject(): object {
    return {
      [`padding-left-${this.actionDictionary.left.length}`]: this.actionDictionary.left.length,
      [`padding-right-${this.actionDictionary.right.length}`]: this.actionDictionary.right.length
    };
  }

  public setFocus() {
    const input = this.$refs.input as HTMLInputElement;
    const position = input.value.length;
    input.focus();
    if (input.setSelectionRange && /text|search|password|tel|url/i.test(input.type || '')) {
      input.setSelectionRange(position, position);
    }
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.$nextTick(() => {
      this.setFocus();
    });
  }

  public clearInput() {
    this.$emit('input', '');
    this.$nextTick(() => {
      this.setFocus();
    });
  }

  private getActionComponent(action: InputActionData): IInputAction {
    if (typeof action === 'string') {
      return this.inputActionDictionary[action];
    } else {
      return {
        component: InputAction,
        props: {
          icon: action.icon,
          title: action.title || null,
          className: action.className || null,
          action: action.action || this.setFocus.bind(this)
        }
      };
    }
  }

  private setActionDictionary(data: InputActionData[]): void {
    const actionList: InputActionData[] = [...data];
    this.actionDictionary = {
      left: [],
      right: []
    };
    if (this.icon) {
      actionList.unshift({
        position: 'left',
        icon: this.icon
      });
    }
    const inputTypeActionList: InputActionData[] = this.inputTypeActionDictionary[this.$attrs.type as InputType] || [];
    if (!actionList.length) {
      actionList.push(...inputTypeActionList);
    }
    if (!actionList.includes(InputActionType.clear)) {
      actionList.unshift(InputActionType.clear);
    }
    if (this.loading !== null) {
      actionList.push(InputActionType.loading);
    }
    if (!actionList || !actionList.length) {
      return;
    }
    for (const actionData of actionList) {
      const position: InputActionPosition = typeof actionData !== 'string' && actionData.position !== undefined
        ? actionData.position
        : 'right';
      const action: IInputAction = this.getActionComponent(actionData);
      this.actionDictionary[position].push(action);
    }
  }
}
