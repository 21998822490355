import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import { getField, updateField } from 'vuex-map-fields';
import {ICredentials} from '@/services/api/authorization.service';

const initialState = (): ICredentials => ({
  login: '',
  password: '',
  submitSuccess: false
});
const state: ICredentials = initialState();

const getters = {
  getLoginFormField(state: ICredentials) {
    return getField(state);
  }
};

const mutations = {
  ['RESET_LOGIN_FORM'](state: ICredentials) {
    Object.assign(state, initialState());
  },
  ['SET_LOGIN_SUBMIT_SUCCESS'](state: ICredentials, payload: boolean) {
    state.submitSuccess = payload;
  },
  updateLoginFormField(state: ICredentials, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<ICredentials, RootState> = {};

export default {
  state,
  getters,
  mutations,
  actions
};
