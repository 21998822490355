


























































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class RegisterLicense extends Vue {
  public accept(): void {
    this.$store.dispatch('setIsLicenceOpened', true);
    this.$emit('openModal', 'register');
  }
}
