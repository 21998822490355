
















import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileIcon from '@/assets/icon/profile.svg';

@Component({})

export default class Login extends Vue {
  
  public ProfileIcon = ProfileIcon;

  public openModal() {
    this.$emit('openModal', 'login');
  }
}
