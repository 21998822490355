



































































































import Vue from 'vue';
import Component from 'vue-class-component';
import store from '@/store';
import {Watch} from 'vue-property-decorator';
import UserProfileIcon from '@/assets/icon/user-profile.svg';
import {email, required, sameAs} from 'vuelidate/lib/validators';
import {createHelpers} from 'vuex-map-fields';
import UserService, {ISchoolData} from '@/services/api/user.service';
import {debounce, markMatch} from '@/utilities';

const { mapFields } = createHelpers({
  getterType: 'getRegistrationFormField',
  mutationType: 'updateRegistrationFormField',
});

const validations = {
  firstName: {
    required
  },
  lastName: {
    required
  },
  email: {
    required,
    email,
    unique: () => {
      return !store.getters.isRegistrationEmailDuplicated;
    }
  },
  password: {
    required,
    valid: (value: string) => {
      const hasNumbers = (value: string) => /\d/.test(value);
      const hasLetters = (value: string) => /[a-zA-Z]/.test(value);
      const isLong = (value: string) => value.length > 7;

      return hasNumbers(value) && isLong(value) && hasLetters(value);
    },
  },
  passwordConfirm: {
    required,
    sameAsPassword: sameAs('password')
  },
  school: {
    required
  },
  termsOfUseAccepted: {
    sameAs: sameAs( () => true )
  }
};

@Component({
  validations,
  computed: {...mapFields([
      'firstName',
      'lastName',
      'email',
      'password',
      'passwordConfirm',
      'school',
      'schoolModel',
      'subscribed',
      'termsOfUseAccepted',
      'isLicenceOpened',
      'submitSuccess',
      'emailDuplicated'
    ])
  },
})
export default class RegisterForm extends Vue {
  private email!: string;
  private UserProfileIcon = UserProfileIcon;

  private firstNameErrorMessageList = {
    required: 'Vorname wird benötigt'
  };
  private lastNameErrorMessageList = {
    required: 'Nachname wird benötigt'
  };
  private get emailErrorMessageList() {
    return {
      required: 'Email wird benötigt',
      email: 'E-Mail ist falsch',
      unique: `Die E-Mailadresse <b>${this.email}</b> wird bereits verwendet. Bitte registrieren Sie sich mit einer anderen E-Mail-Adresse.`
    };
  }
  private passwordErrorMessageList = {
    required: 'Passwort wird benötigt',
    valid: 'Ein Passwort muss aus mindestens 8 Zeichen (Buchstaben und Zahlen) bestehen',
  };
  private passwordConfirmErrorMessageList = {
    required: 'Passwortbestätigung ist erforderlich',
    sameAsPassword: 'Die Passwortbestätigung entspricht nicht dem Passwort'
  };
  private schoolErrorMessageList = {
    required: 'Schule / Institution ist erforderlich',
  };
  private isTermsOfUseAcceptedErrorMessageList = {
    sameAs: 'Sie müssen die Nutzungsbedingungen für die Registrierung akzeptieren'
  };

  private markMatch = markMatch;
  private loading = false;
  private school!: null | number;
  private schoolData: ISchoolData[] = [];
  private showChevron: boolean = false;
  private submitSuccess!: boolean;

  private request(query: string): void {
    this.loading = true;
    UserService.getSchoolList(query)
      .then((data: ISchoolData[]) => {
        this.schoolData = data;
        this.showChevron = !!this.schoolData.length;
        this.loading = false;
      });
  }
  public getSchoolData = debounce((query: string) => this.request(query), 300);
  
  public updateSchool(value: ISchoolData): void {
    this.school = value
      ? value.systemObjectId
      : null;
  }

  public submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$store.dispatch('register');
    } else {
      Vue.prototype.$notify({
        group: 'app',
        type: 'warning',
        title: 'Error',
        text: 'Bitte überprüfen Sie die Formularfelder'
      });
    }
  }

  public verifyMZ(name: string): boolean{
    return name.indexOf('MZ ') > -1;
  }

  public highlightSchool(path: string[]) {
    return path.length
      ? path.map((item: string) => {
          return this.verifyMZ(item)
            ? `<span class="highlight">${item}</span>`
            : item;
        })
      : path;
  }

  @Watch('submitSuccess')
  public onSubmitSuccess(success: boolean) {
    if (success) this.$emit('openModal', 'registerSuccess');
  }
}
