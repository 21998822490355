





import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class DocumentViewer extends Vue {
  private src: string = '';

  @Prop({
    type: String,
    required: true,
  })
  public url!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public visible!: boolean;
}
