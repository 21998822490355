import {ActionTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import EducationalStandardsApiService, {IEducationalStandard} from '@/services/api/educational-standarts.service';
import EducationalStandardsService from '@/services/data/educational-standarts.service';

export interface IEducationalStandardsState {
  schoolType: null | IEducationalStandard;
  schoolTypeList: IEducationalStandard[];
  schoolTypeLoading: boolean;
  subject: null | IEducationalStandard;
  subjectList: IEducationalStandard[];
  subjectLoading: boolean;
  classroom: null | IEducationalStandard;
  classroomList: IEducationalStandard[];
  classroomLoading: boolean;
}
const initialState = (): IEducationalStandardsState => ({
  schoolType: EducationalStandardsService.restore('schoolType'),
  schoolTypeList: [],
  schoolTypeLoading: false,
  subject: EducationalStandardsService.restore('subject'),
  subjectList: [],
  subjectLoading: false,
  classroom: EducationalStandardsService.restore('classroom'),
  classroomList: [],
  classroomLoading: false
});
const state = initialState();
const getters = {
  getEducationalStandard: (state: IEducationalStandardsState) => [
    state.schoolType
    && state.schoolType.id,
    state.subject
    && state.subject.id,
    state.classroom
    && state.classroom.id
  ]
    .filter((item: null | number) => item !== null),
  getEducationalStandardsField: (state: IEducationalStandardsState) => getField(state),
  schoolTypeList: (state: IEducationalStandardsState): IEducationalStandard[] => Array.from(state.schoolTypeList) || [],
  subjectList: (state: IEducationalStandardsState): IEducationalStandard[] => Array.from(state.subjectList) || [],
  classroomList: (state: IEducationalStandardsState): IEducationalStandard[] => Array.from(state.classroomList) || [],
  schoolTypeLoading: (state: IEducationalStandardsState): boolean => state.schoolTypeLoading,
  subjectLoading: (state: IEducationalStandardsState): boolean => state.subjectLoading,
  classroomLoading: (state: IEducationalStandardsState): boolean => state.classroomLoading
};
const mutations = {
  ['SET_SCHOOL_TYPE'](state: IEducationalStandardsState, payload: null | IEducationalStandard) {
    state.schoolType = payload;
    EducationalStandardsService.store('schoolType', payload);
  },
  ['SET_SCHOOL_TYPE_LIST'](state: IEducationalStandardsState, payload: IEducationalStandard[]) {
    state.schoolTypeList = payload;
  },
  ['SET_SCHOOL_TYPE_LOADING'](state: IEducationalStandardsState, payload: boolean) {
    state.schoolTypeLoading = payload;
  },
  ['SET_SUBJECT_LIST'](state: IEducationalStandardsState, payload: IEducationalStandard[]) {
    state.subjectList = payload;
  },
  ['SET_SUBJECT'](state: IEducationalStandardsState, payload: null | IEducationalStandard) {
    state.subject = payload;
    EducationalStandardsService.store('subject', payload);
  },
  ['SET_SUBJECT_LOADING'](state: IEducationalStandardsState, payload: boolean) {
    state.subjectLoading = payload;
  },
  ['SET_CLASSROOM_LIST'](state: IEducationalStandardsState, payload: IEducationalStandard[]) {
    state.classroomList = payload;
  },
  ['SET_CLASSROOM'](state: IEducationalStandardsState, payload: null | IEducationalStandard) {
    state.classroom = payload;
    EducationalStandardsService.store('classroom', payload);
  },
  ['SET_CLASSROOM_LOADING'](state: IEducationalStandardsState, payload: boolean) {
    state.classroomLoading = payload;
  },
  updateEducationalStandardsField(state: IEducationalStandardsState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IEducationalStandardsState, RootState> = {
  async getSchoolTypeList({commit}) {
    commit('SET_SCHOOL_TYPE_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList()
      .then((data: IEducationalStandard[]) => {
        commit('SET_SCHOOL_TYPE_LIST', data);
        commit('SET_SCHOOL_TYPE_LOADING', false);
      })
      .catch(() => {
        commit('SET_SCHOOL_TYPE_LIST', []);
        commit('SET_SCHOOL_TYPE_LOADING', false);
      });
  },
  setSchoolType({commit, dispatch}, payload: null | IEducationalStandard) {
    commit('SET_SCHOOL_TYPE', payload);
    commit('SET_SUBJECT_LIST', []);
    dispatch('setSubject', null);
  },
  async getSubjectList({commit, dispatch, state}) {
    commit('SET_SUBJECT_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList(state.schoolType!.id)
      .then((data: IEducationalStandard[]) => {
        commit('SET_SUBJECT_LIST', data);
        dispatch('setClassroom', null);
        commit('SET_SUBJECT_LOADING', false);
      })
      .catch(() => {
        commit('SET_SUBJECT_LIST', []);
        dispatch('setClassroom', null);
        commit('SET_SUBJECT_LOADING', false);
      });
  },
  setSubject({commit, dispatch}, payload: null | IEducationalStandard) {
    commit('SET_SUBJECT', payload);
    commit('SET_CLASSROOM_LIST', []);
    dispatch('setClassroom', null);
  },
  async getClassroomList({commit, state}) {
    commit('SET_CLASSROOM_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList(state.subject!.id)
      .then((data: IEducationalStandard[]) => {
        const sortedData = data.sort((a: IEducationalStandard, b: IEducationalStandard) => {
          const sort = (item: IEducationalStandard) => +item.name.split(' ')[0] || 0;
          return sort(a) - sort(b);
        });
        commit('SET_CLASSROOM_LIST', sortedData);
        commit('SET_CLASSROOM_LOADING', false);
      })
      .catch(() => {
        commit('SET_CLASSROOM_LIST', []);
        commit('SET_CLASSROOM_LOADING', false);
      });
  },
  setClassroom({commit}, payload: null | IEducationalStandard) {
    commit('SET_CLASSROOM', payload);
  },
  async clearEducationStandartsFields({commit}) {
    commit('SET_SCHOOL_TYPE', null);
    commit('SET_SUBJECT', null);
    commit('SET_CLASSROOM', null);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
