










































import Vue from 'vue';
import Component from 'vue-class-component';
import {IBookmark} from '@/services/api/bookmark.service';
import {createHelpers} from 'vuex-map-fields';
import {InputActionData, InputActionType} from '@/interfaces/interfaces';
import SearchIcon from '@/assets/icon/search.svg';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  computed: {...mapFields([
      'bookmarkListModal',
      'searchModal',
      'bookmarkIdList',
      'isModalBookmarkListLoading',
      'activeMediaIdList'
    ]),
  },
   mixins: [adaptiveButtonSize]
})

export default class BookmarksForm extends Vue {
  public SearchIcon = SearchIcon;

  public searchModal!: string;
  public isModalBookmarkListLoading!: boolean;
  public bookmarkIdList!: number[];
  public bookmarkListModal!: IBookmark[];
  public submitDisabled: boolean = false;

  public actionList: InputActionData[] = [
    InputActionType.clear,
    {
      position: 'left',
      icon: SearchIcon,
      className: 'search',
      title: 'Suchen',
    }
  ];

  private created(): void {
    this.$store.dispatch(
      'getBookmarkList', 
      {
        modal: true, 
        own: true, 
        mediaId: this.$attrs.mediaId
      }
    );
  }

  private mounted(): void {
    this.bookmarkIdList = [];
  }

  public updateSearch(value: string): void {
    this.$store.dispatch('searchBookmarkModal', value);
  }

  public callBookmarkAction(): void {
    this.$store.dispatch(this.$attrs.action, {mediaIdList: this.$attrs.mediaIdList})
      .then(() => {
        this.$emit('closeModal'); 
        this.$root.$emit('clearMediaIdList', this.$attrs.mediaItemType);
      });
    this.submitDisabled = false;
  }

  public cancel(): void {
    this.$emit('closeModal');
  }
}
