import {api} from '@/services/api/api.service';
import {AxiosResponse, AxiosError} from 'axios';
import {IMediaCenter} from '@/services/api/media-center.service';

export type PermissionType = 'shareBookmarks'
  | 'useBookmarks'
  | 'useSearch'
  | 'student';

export type PermissionDictionary = {
  [key in PermissionType]: boolean;
};
export interface IUser {
  token: string;
  userId: number;
  firstName: string;
  secondName: string;
  displayName: string;
  district: string;
  schools: string;
  mediaCenter: string;
  email: string;
  newsletter: boolean;
  antaresSync: boolean;
  availableMZ: IMediaCenter[];
  rights: PermissionDictionary;
  userType: UserType[];
  maxDateShare: string;
  relevantUser: boolean;
  ltiMode: boolean;
  schoolersBookmark: number;
}

export interface ISchoolData {
  id: number;
  systemObjectId: number;
  path: string[];
  name: string;
}

export interface IRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm?: string;
  school: null | number;
  subscribed: boolean;
  termsOfUseAccepted: boolean;
}

export interface IRegistrationResponse {
  id: number;
  type: null
  basetype: string;
  fullName: string;
  emailList: string[];
}

export interface IRegistrationConfirmResponse {
  token: string;
}

export interface IUserService {
  getUser: () => Promise<null | IUser>;
  isAnonymousUser: (user: IUser) => boolean;
  getSchoolList: (query: string) => Promise<ISchoolData[]>;
  register: (data: IRegistrationData) => Promise<IRegistrationResponse>;
  updateProfile: (newsletter: boolean, antaresSync: boolean) => Promise<null | IUser>;
  confirmRegistration: (code: string, email: string) => Promise<object>;
}

export enum UserType {
  UNDEFINED = 'Undefined',
  ANONYMOUS = 'Anonyme Benutzer',
  SELF_REGISTERED_USER = 'Selbst-registrierte Benutzer',
  ONLINE_CONFIRMED_USER = 'Bestätigte Nutzer (Online)',
  RENTAL_CONFIRMED_USER = 'Bestätigte Nutzer (Verleih)',
  STUDENT = 'Schüler',
  ADMIN = 'Admin'
}

const getUser = (): Promise<IUser> => api
  .get('user/current')
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const isAnonymousUser = (user: IUser): boolean => user.userType.includes(UserType.ANONYMOUS);

const getSchoolList = (query: string): Promise<ISchoolData[]> => !!query && query.length > 0 ? api
  .get(`user/schools?q=${query}`)
  .then((response: AxiosResponse<ISchoolData[]>) => response.data)
  .catch((error: AxiosError) => Promise.reject(error.response)) : Promise.resolve([]);

const register = (data: IRegistrationData): Promise<IRegistrationResponse> => api
  .post('user/register', data)
  .then((response: AxiosResponse<IRegistrationResponse>) => response.data)
  .catch((error: AxiosError) => Promise.reject(error));

const updateProfile = (newsletter: boolean, antaresSync: boolean): Promise<IUser> => api
  .get(`user/update?newsletter=${newsletter}&antaresSync=${antaresSync}`)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error: AxiosError) => Promise.reject(error.response));

const confirmRegistration = (code: string, email: string): Promise<object> => api
  .get(`user/registration-confirm?login=${email}&key=${code}`)
  .then((response: AxiosResponse<object>) => response)
  .catch((error: AxiosError) => Promise.reject(error.response));

const UserService: IUserService = {
  getUser,
  isAnonymousUser,
  getSchoolList,
  register,
  updateProfile,
  confirmRegistration
};

export default UserService;
