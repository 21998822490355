import {ActionTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {Dictionary} from 'vue-router/types/router';
import store, {RootState} from '@/store';
import router from '@/router';
import {scrollTo} from '@/utilities';
import MediaCenterService from '@/services/data/media-center.service';
import MediaCenterApiService, { IRentalUrlResponse } from '@/services/api/media-center.service';

export interface IMediaCenterState {
  isMediaCenterSet: boolean;
  mediaCenter: null | number;
  redirect: null | string;
  isMediaCentersVisible: boolean;
  rentalUrlParameters: string;
}
const initialState = (): IMediaCenterState => ({
  isMediaCenterSet: false,
  mediaCenter: null,
  redirect: null,
  isMediaCentersVisible: false,
  rentalUrlParameters: ''
});
const state = initialState();

const getters = {
  getMediaCenterField: (state: IMediaCenterState) => getField(state),
  getMediaCenterId: (state: IMediaCenterState) => state.mediaCenter,
};
const mutations = {
  ['SET_MEDIA_CENTER'](state: IMediaCenterState, payload: null | number) {
    state.mediaCenter = payload;
    state.isMediaCenterSet = payload != null;
  },
  ['SET_REDIRECT'](state: IMediaCenterState, payload: null | string) {
    state.redirect = payload;
  },
  ['SET_MEDIA_CENTERS_VISIBLE'](state: IMediaCenterState, payload: boolean) {
    state.isMediaCentersVisible = payload;
  },
  ['SET_RENTAL_URL_PARAMETERS'](state: IMediaCenterState, payload: string) {
    state.rentalUrlParameters = payload;
  },

  updateMediaCenterField(state: IMediaCenterState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IMediaCenterState, RootState> = {
  checkMediaCenter({dispatch, getters}) {
    const user = getters.getUserField('user');
    const isAnonymousUser = getters.getUserField('isAnonymousUser');
    const mediaCenter = user && !isAnonymousUser
      ? user.availableMZ[0].id
      : MediaCenterService.getMediaCenter();
    dispatch('setMediaCenter', mediaCenter);
  },
  redirectMediaCenter({commit, getters, state}) {
    const mediaCenter = state.mediaCenter;
    const routeName = router.currentRoute.name;
    const redirect: string = !routeName || routeName === 'media-center-select' ? 'main' : routeName;
    const params: Dictionary<string> = {redirect};
    const isAnonymousUser = getters.getUserField('isAnonymousUser');
    if (mediaCenter === null) {
      if (isAnonymousUser) {
        if (routeName !== 'media-center-select') {
          return router
            .push({
              name: 'media-center-select',
              params
            });
        }
      } else {
        return commit('SET_MEDIA_CENTERS_VISIBLE', false);
      }
    } else {
      if (routeName === 'media-center-select') {
        const route = state.redirect ? {path: state.redirect} : {name: 'main'};
        return router
          .push(route)
          .then(() => commit('SET_REDIRECT', null));
      }
    }
  },
  setMediaCenter({commit, dispatch}, value: null | number) {
    commit('SET_MEDIA_CENTER', value);
    MediaCenterService.setMediaCenter(value);
    if (!store.getters.getUserField('isInitializing')) {
      dispatch('redirectMediaCenter');
    }
    if (router.currentRoute.name === 'search') 
      dispatch('onChangeMediaCenter')
        .then(() => scrollTo('.app-container', 'smooth'));
  },
  async getRentalUrlParameters({commit}) {
    commit('SET_LOADING', {mediaCenter: true});
    return MediaCenterApiService.getRentalUrlParameters()
      .then((data: IRentalUrlResponse) => {
        commit('SET_RENTAL_URL_PARAMETERS', data.params);
        commit('SET_LOADING', {mediaCenter: false});
      })
      .catch((error) => Promise.reject(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
