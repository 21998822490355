



















import Vue from 'vue';
import Component from 'vue-class-component';
import { IModal } from '@/interfaces/interfaces';

import TermsOfUse from '@/components/Layout/Footer/TermsOfUse.vue';
import DataProtection from '@/components/Layout/Footer/DataProtection.vue';
import DeclarationOfConsent from '@/components/Layout/Footer/DeclarationOfConsent.vue';
import Impressum from '@/components/Layout/Footer/Impressum.vue';
import Cookies from '@/components/Layout/Footer/Cookies.vue';
import ForMediaProviders from '@/components/Layout/Footer/ForMediaProviders.vue';
import EmbedResult from '@/components/UI/EmbedResult.vue';
import DataTransmission from '@/components/Layout/Footer/DataTransmission.vue';

import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    TermsOfUse,
    DataProtection,
    DeclarationOfConsent,
    Impressum,
    ForMediaProviders,
    EmbedResult,
    DataTransmission
  },
  computed: {...mapFields([
      'modalState'
    ]),
  }
})

export default class FooterModals extends Vue {
  public modalState!: string[];
  public mediaId!: string;
  public callback!: string;

  public mounted() {
    this.$root.$on('openFooterModal', (payload: Record<string, string>) => this.openModal(payload.id, payload.mediaId, payload.callback));
    this.$root.$on('closeFooterModal', () => this.closeModal());

    this.$store.subscribeAction({
      before: (action) => {
        if (['createEmbedMedia'].includes(action.type)) {
          this.$root.$emit('closeDetailsModal');
        }
      },
      after: (action) => {
        if (action.type === 'createEmbedMedia') {
          this.openModal('embed');
        }
      }
    });
  }

  public modals: IModal[] = [
    {
      id: 'termsOfUse',
      label: 'ANB',
      title: 'Allgemeine Nutzungsbedingungen',
      maxWidth: '956px',
      component: TermsOfUse
    },
    {
      id: 'cookies',
      label: 'Cookies',
      title: 'Cookies',
      maxWidth: '956px',
      component: Cookies
    },
    {
      id: 'dataProtection',
      label: 'Datenschutz',
      title: 'Datenschutzerklärung',
      maxWidth: '956px',
      component: DataProtection
    },
    {
      id: 'declarationOfConsent',
      label: 'Einwilligungserklärung',
      title: 'Einwilligungserklärung',
      maxWidth: '956px',
      component: DeclarationOfConsent
    },
    {
      id: 'impressum',
      label: 'Impressum',
      title: 'Impressum',
      maxWidth: '956px',
      component: Impressum
    },
    {
      id: 'forMediaProviders',
      label: 'Für Medienanbieter',
      title: 'Für Medienanbieter',
      maxWidth: '956px',
      component: ForMediaProviders
    },
    {
      id: 'embed',
      title: 'Teilen',
      maxWidth: '560px',
      component: EmbedResult
    },
    {
      id: 'dataTransmission',
      title: 'Hinweis zur Weiterleitung',
      maxWidth: '956px',
      component: DataTransmission
    }
  ];

  private visibleData: boolean[] = [];

  private setDefaultVisibleData() {
    this.visibleData = [...Array(this.modals.length).fill(false)];
  }

  private getIndexById(id: string): number {
    return this.modals
      .findIndex((modal: IModal) => modal.id === id);
  }

  public openModal(id: string, mediaId?: string, callback?: string): void {
    this.setDefaultVisibleData();
    this.visibleData
      .splice(this.getIndexById(id), 1, true);
    if (mediaId) this.mediaId = mediaId;
    if (callback) this.callback = callback;
  }

  public closeModal(): void {
    this.$store.commit('RESET_MODALS');
    this.setDefaultVisibleData();
  }

  public confirm() {
    if (this.callback && this.mediaId) {
      this.$store.dispatch(this.callback, this.mediaId);
    }
    this.closeModal();
  }
}
