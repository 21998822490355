

























import Vue from 'vue';
import Component from 'vue-class-component';
import LogoutIcon from '@/assets/icon/logout.svg';
import Permission from '@/components/Permission/Permission.vue';

@Component({
  components: { LogoutIcon, Permission }
})
export default class UserMenu extends Vue {
  public get fullName(): string {
    return `${this.$store.state.user.user.firstName} ${this.$store.state.user.user.secondName}`;
  }

  public logout() {
    this.$store.dispatch('logout');
  }
}
