













import Vue from 'vue';
import Component from 'vue-class-component';
import {IMediaCenter} from '@/services/api/media-center.service';

import MediaCentersModal from '@/components/Layout/Footer/MediaCentersModal.vue';
import Permission from '@/components/Permission/Permission.vue';
import Arrow from '@/assets/icon/arrow-popup.svg';

@Component({
  components: {
    Arrow,
    Permission,
    MediaCentersModal
  }
})

export default class MediaCenters extends Vue {
  private get mediaCenter() {
    const currentMediaCenterId = this.$store.getters.getMediaCenterId;
    if (currentMediaCenterId === null) {
      return 'Wählen Sie Ihr MZ';
    }
    const user = this.$store.getters.getUserField('user');
    const mediaCenterList: IMediaCenter[] = user ? user.availableMZ : [];
    const currentMediaCenter: IMediaCenter = mediaCenterList
      .filter((mediaCenter: IMediaCenter) => +mediaCenter.id === +currentMediaCenterId)[0];
    return currentMediaCenter ? currentMediaCenter.name : '';
  }
}
