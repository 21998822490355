


























import Vue from 'vue';
import Component from 'vue-class-component';
import {Model, Prop} from 'vue-property-decorator';
import CheckboxCheckIcon from '../../assets/icon/checkbox-check.svg';
import CheckboxIndeterminateIcon from '../../assets/icon/checkbox-indeterminate.svg';
import {HTMLElementEvent, IDictionary} from '@/interfaces/interfaces';

@Component({
  components: { CheckboxCheckIcon, CheckboxIndeterminateIcon },
  inheritAttrs: false
})
export default class Checkbox extends Vue {

  @Model('change', { type: [Boolean, String, Array ] })
  readonly modelValue!: boolean | boolean[] | string | string[];

  @Prop({
    type: String,
    required: true
  })
  public name!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public label!: string;

  @Prop({
    type: String,
    required: false,
    default: 'checkbox'
  })
  public mode!: 'checkbox' | 'switch';

  @Prop({
    type: [String, Boolean, Number],
    required: false,
    default: false
  })
  public value!: string | boolean;

  @Prop({
    type: [Boolean, String],
    required: false,
    default: true
  })
  public trueValue!: boolean | string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public checkedForce!: boolean;

  @Prop({
    type: [Boolean, String],
    required: false,
    default: false
  })
  public falseValue!: boolean | string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public noPadding!: boolean;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public inputClass!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public labelClass!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public white!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public isShowSingleError!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorMessageList!: IDictionary<string>;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorState!: IDictionary<boolean>;

  public get classObject(): object {
    return {
      [`${this.mode}-input`]: true,
      'no-padding': this.noPadding,
      'white': this.white,
      error: this.errorState.$invalid && this.errorState.$dirty
    };
  }
  public get shouldBeChecked() {
    if (this.checkedForce) return true;
    if (this.modelValue instanceof Array) {
      return (this.modelValue as (string | boolean)[]).includes(this.value);
    }
    return this.modelValue === this.trueValue;
  }
  public updateInput(event: HTMLElementEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;

    if (this.modelValue instanceof Array) {
      const newValue = [...this.modelValue];

      if (isChecked) {
        newValue.push(this.value);
      } else {
        newValue.splice(newValue.indexOf(this.value), 1);
      }
      this.$emit('change', newValue);
    } else {
      this.$emit('change', isChecked ? this.trueValue : this.falseValue);
    }
  }
}
