import { api } from '@/services/api/api.service';
import { AxiosResponse } from 'axios';
import { IMedia } from './media.service';

export interface IMediaShareApiService {
  createSharedMedia: (id: number) => Promise<IMediaShareResponse>,
  getSharedMedia: (id: number) => Promise<IMedia>,
  createAssetLink: (id: number) => Promise<{ token: string; availableTo: string }>
}

export interface IMediaShareResponse {
  token: string;
  endDate: string;
}

const createSharedMedia = (id: number) => api
  .get(`share/create/${id}`)
  .then((response: AxiosResponse<IMediaShareResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const getSharedMedia = (id: number) => api
  .get(`share/${id}`)
  .then((response: AxiosResponse<IMedia>) => response.data)
  .catch((error) => Promise.reject(error));

const createAssetLink = (id: number) => api
  .get(`asset/create/${id}`)
  .then((response: AxiosResponse<{ token: string; availableTo: string }>) => response.data)
  .catch((error) => Promise.reject(error));

const MediaShareApiService: IMediaShareApiService = {
  createSharedMedia,
  getSharedMedia,
  createAssetLink
};

export default MediaShareApiService;
