import { redirectGuard } from '@/router/guards/redirect.guard';
import { mainGuard } from '@/router/guards/main.guard';
import { NavigationGuardNext, Route } from 'vue-router';

const globalGuards = (to: Route, from: Route, next: NavigationGuardNext) => {
  const params = { to, from, next };

  return mainGuard({
    ...params,
    nextGuard: (mainGuardParams) => redirectGuard({
      ...mainGuardParams!
    })
  });
  
};

export default globalGuards;