








import Vue from 'vue';
import Component from 'vue-class-component';
import InputCloseIcon from '../../assets/icon/input-close.svg';
import {Prop} from 'vue-property-decorator';

@Component({
  components: {
    InputCloseIcon
  }
})
export default class Loading extends Vue {

  @Prop({
    type: String,
    required: false,
    default: '44'
  })
  public size!: string;

}
