




































// https://github.com/euvl/vue-notification/blob/master/README.md
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import NoticeIconSuccess from '@/assets/icon/round-check-done.svg';
import NoticeIconWarning from '@/assets/icon/round-check-warn.svg';
import { TweenLite } from 'gsap';
import UI, {AdaptiveMode} from '@/store/modules/ui/ui.store';

@Component({
  components: {
    NoticeIconSuccess,
    NoticeIconWarning
  },
  inheritAttrs: false,
})

export default class Notification extends Vue {

  @Prop({
    type: String,
    required: false,
    default: 'app'
  })
  private group!: 'app' | 'custom';

  @Prop({
    type: Number,
    required: false,
    default: 4000
  })
  public durationMilliseconds!: number;

  public get position() {
    return UI.state.adaptiveMode === AdaptiveMode.LG ? 'top right' : 'bottom left';
  }
  
  public get width() {
    return UI.state.adaptiveMode === AdaptiveMode.SM || UI.state.adaptiveMode === AdaptiveMode.XS ? '375px' : '390px';
  }

  public get classObject() {
    return {
      'mobile': UI.state.adaptiveMode === AdaptiveMode.SM || UI.state.adaptiveMode === AdaptiveMode.XS
    };
  }

  public swipe(event: WheelEvent, close: () => void) {
    const delta: number = Math.abs(event.deltaX / 100 || 0);
    const target = (event.target as HTMLElement).closest('[data-id]');
    let opacity: number = 1 - delta;

    if (opacity < 0) opacity = 0;

    TweenLite.to(target, .1, {x: event.deltaX + 1});

    if (Math.abs(event.deltaX) > 150) {
      close();
    }
  }

  public swipeEnd(event: WheelEvent, close: () => void): void {
    const delta: number = Math.abs(event.deltaX / 100 || 0);
    const target = (event.target as HTMLElement).closest('[data-id]');

    if (delta > 150) {
      close();
    } else {
      TweenLite.to(target, .1, {x: 0});
    }
  }
}
