import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {IUser} from '@/services/api/user.service';

export interface ICredentials {
  login: string;
  password: string;
  submitSuccess?: boolean;
}

export interface IResetPassword {
  email: string
}

export interface ISharedResponse {
  bookmarkId: number;
  user: IUser;
}

export interface IAuthorizationService {
  login: (credentials: ICredentials) => Promise<null | IUser>
  loginById: (id: string) => Promise<null | IUser>
  loginByPid: (id: string) => Promise<null | IUser>
  logout: () => Promise<null>,
  resetPassword: (email: string) => Promise<null>
}

const login = (credentials: ICredentials): Promise<null | IUser> => api
  .post('login', credentials)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const loginById = (id: string): Promise<null | IUser> => api
  .get(`short-login/?id=${id}`)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const loginByPid = (id: string): Promise<null | IUser> => api
  .get(`login/pid?id=${id}`)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const logout = (): Promise<null> => api
  .post('logout', {})
  .then(() => null)
  .catch((error) => Promise.reject(error));

const resetPassword = (email: string): Promise<null> => api
  .post('user/reset-password', {email})
  .then(() => null)
  .catch((error) => Promise.reject(error));

const AuthorizationService: IAuthorizationService = {
  login,
  loginById,
  loginByPid,
  logout,
  resetPassword
};

export default AuthorizationService;
