import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {IMedia} from '@/services/api/media.service';

export interface IBookmark {
  parentId: number;
  collectionId: number;
  collectionName: string;
  count: number,
  isOwnCollection: boolean;
  description: string;
  version: number;
  type: string;
  groupIdList: number[];
  from: string;
  to: string;
  ownCollection: boolean;
  added: boolean;
  updateDate: number;
}

export interface INewBookmark {
  name: string;
  parentId: null |number;
  id: null | number;
  version: null | number;
  type: string;
}

export interface IBookmarkMedia {
  mediaIdList: number[];
  bookmarkIdList: number[];
}

export interface IBookmarkReplacingMedia {
  objectIdList: number[];
  fromBookmarkIdList: number[];
  toBookmarkIdList: number[];
}

export interface IBookmarkUpdateResponse {
  count: number;
  bookmarkId: number;
  _version: number;
}

export interface IBookmarkShareResponse {
  id: string;
  version: number;
}

export interface IBookmarkApiService {
  getBookmarkList: (own: boolean, mediaId: number) => Promise<IBookmark[]>
  addBookmarkMedia: (media: IBookmarkMedia) => Promise<IBookmarkUpdateResponse[]>
  removeBookmarkMedia: (media: IBookmarkMedia) => Promise<IBookmarkUpdateResponse[]>
  replaceBookmarkMedia: (replacingMedia: IBookmarkReplacingMedia) => Promise<IBookmarkUpdateResponse[]>
  getMediaListById: (id: number) => Promise<IMedia[]>
  getParentId: () => Promise<IBookmark>
  createBookmark: (bookmark: INewBookmark) => Promise<INewBookmark>
  removeBookmark: (id: number) => Promise<INewBookmark>
  updateBookmark: (bookmark: INewBookmark) => Promise<INewBookmark>
  shareBookmark: (request: IBookmark) => Promise<IBookmarkShareResponse>
}

const getBookmarkList = (own: boolean, mediaId: number): Promise<IBookmark[]> => api
  .get(`/bookmark?own-collection=${own}${mediaId ? '&media-id='+mediaId : ''}`)
  .then((response: AxiosResponse<IBookmark[]>) => response.data)
  .catch((error) => Promise.reject(error));

const addBookmarkMedia = (media: IBookmarkMedia): Promise<IBookmarkUpdateResponse[]> => api 
  .post('/bookmark/collection/object/add', media)
  .then((response: AxiosResponse<IBookmarkUpdateResponse[]>) => response.data)
  .catch((error) => Promise.reject(error));

const removeBookmarkMedia = (media: IBookmarkMedia): Promise<IBookmarkUpdateResponse[]> => api 
  .post('/bookmark/collection/object/remove', media)
  .then((response: AxiosResponse<IBookmarkUpdateResponse[]>) => response.data)
  .catch((error) => Promise.reject(error));

const replaceBookmarkMedia = (replacingMedia: IBookmarkReplacingMedia) => api
  .post('/bookmark/collection/object/move', replacingMedia)
  .then((response: AxiosResponse<IBookmarkUpdateResponse[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getMediaListById = (id: number): Promise<IMedia[]> => {
  return api
    .post('/bookmark/data', id)
    .then((response: AxiosResponse<IMedia[]>) => response.data)
    .catch((error) => Promise.reject(error));
};

const getParentId = (): Promise<IBookmark> => {
  return api
    .get('/bookmark/parent')
    .then((response: AxiosResponse<IBookmark>) => response.data)
    .catch((error) => Promise.reject(error));
};

const createBookmark = (bookmark: INewBookmark): Promise<INewBookmark> => {
  return api
    .post('/bookmark/collection/create', bookmark)
    .then((response: AxiosResponse<INewBookmark>) => response.data)
    .catch((error) => Promise.reject(error));
};

const removeBookmark = (id: number): Promise<INewBookmark> => {
  return api
    .delete('/bookmark/collection/delete', {data: id})
    .then((response: AxiosResponse<INewBookmark>) => response.data)
    .catch((error) => Promise.reject(error));
};

const updateBookmark = (bookmark: INewBookmark): Promise<INewBookmark> => {
  return api
    .put('/bookmark/collection/update', bookmark)
    .then((response: AxiosResponse<INewBookmark>) => response.data)
    .catch((error) => Promise.reject(error));
};

const shareBookmark = (request: IBookmark): Promise<IBookmarkShareResponse> => {
  return api
    .post('/bookmark/collection/share', request)
    .then((response: AxiosResponse<IBookmarkShareResponse>) => response.data)
    .catch((error) => Promise.reject(error));
};

const BookmarkApiService: IBookmarkApiService = {
  getBookmarkList,
  addBookmarkMedia,
  removeBookmarkMedia,
  replaceBookmarkMedia,
  getMediaListById,
  getParentId,
  createBookmark,
  removeBookmark,
  updateBookmark,
  shareBookmark
};

export default BookmarkApiService;