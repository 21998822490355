import { AxiosError } from 'axios';
import store from '@/store';
import router from '@/router';

const errorInterceptor = (error: AxiosError) => {
  if (error.message && error.message === 'aborted') {
    return Promise.reject(error);
  }
  if (error.response && error.response.status === 401) {
    if (router.currentRoute.name !== 'media-center-select') {
      router.push({ name: 'media-center-select' });
    }
  } else if (error.response && error.response.status === 409) {
    return Promise.reject(error);
  } else if (error.response && error.response.status === 404) {
    store.commit('SET_ERROR_CODE', 404);
  } else if (error.response && error.response.status === 400) {
    store.commit('SET_ERROR_CODE', 400);
    store.commit('SET_ERROR_MESSAGE', error.response.data.message);
  } else {
    store.commit('SET_ERROR_CODE', 500);
  }
  return Promise.reject(error);
};

export default errorInterceptor;
