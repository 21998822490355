





































import Vue from 'vue';
import Component from 'vue-class-component';
import { createHelpers } from 'vuex-map-fields';
import {IMediaCenter} from '@/services/api/media-center.service';
import { Prop } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import Arrow from '@/assets/icon/arrow-popup.svg';
import BackIcon from '@/assets/icon/back.svg';
import Input from '@/components/UI/Input/Input.vue';

const { mapFields } = createHelpers({
  getterType: 'getMediaCenterField',
  mutationType: 'updateMediaCenterField',
});

export type modeType = 'advanced-chose' | 'sub-footer';

@Component({
  name: 'MediaCenterForm',
  components: {
    Input,
    Arrow,
    BackIcon
  },
  computed: {...mapFields([
      'mediaCenter'
    ]),
  },
  directives: {
    clickOutside
  }
})
export default class MediaCenterForm extends Vue {
  private BackIcon = BackIcon;

  @Prop({
    required: false,
    type: String
  })
  public mode!: modeType;

  public search: string = '';

  public mediaCenterList: IMediaCenter[] = this.$store.getters.getUserAvailableMZList;
  public dataList: IMediaCenter[] = this.mediaCenterList;

  public activated: boolean = false;

  private activateInput() {
    this.$store.getters.isMobileAdaptiveMode
      ? this.$emit('openModal')
      : this.activated = true;
  }

  public deactivateInput() {
    this.activated = false;
  }

  public get classObject() {
    return {
      [`${this.mode}-wrapper`]: this.mode,
      'active': this.activated
    };
  }

  public updateSearch(value: string): void {
    this.search = value;
    this.dataList = this.search === ''
      ? this.mediaCenterList
      : this.mediaCenterList.filter((item: IMediaCenter) => item.name.toLowerCase().includes(this.search.toLowerCase()));
  }

  public updateMediaCenter(id: number): void {
    if (this.mode !== 'advanced-chose') {
      this.closeModal();
    }
    this.$store.dispatch('setMediaCenter', id);
  }

  private setAllMediaCenters() {
    this.$store.dispatch('setMediaCenter', 0);
  }

  public closeModal() {
    this.$emit('closeModal');
  }

  public focusInput() {
    (this.$refs.input as Input).setFocus();
  }
}
