import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export interface ISlide { 
  title: string;
  description: string;
  link: string;
  image: string;
}

export interface ISliderApiService {
  getSlideList: () => Promise<ISlide[]>
}

const getSlideList = (): Promise<ISlide[]> => {
  return api
    .get<ISlide[]>('/slider')
    .then((response: AxiosResponse<ISlide[]>) => response.data)
    .catch(error => Promise.reject(error));
};

const SliderApiService: ISliderApiService = {
  getSlideList
};

export default SliderApiService;
