import {getField, updateField} from 'vuex-map-fields';
import {IMedia} from '@/services/api/media.service';
import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import OffersApiService from '@/services/api/offers.service';

export interface IOffersState {
  offersList: IMedia[];
}
const initialState = (): IOffersState => ({
  offersList: []
});
const state = initialState();
const getters = {
  getOffersField: (state: IOffersState) => getField(state)
};
const mutations = {
  ['SET_OFFERS_LIST'](state: IOffersState, payload: IMedia[]) {
    state.offersList = payload;
  },
  updateOffersField(state: IOffersState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IOffersState, RootState> = {
  async getOffersList({commit}) {
    OffersApiService.getOffersList()
      .then((data: IMedia[]) => {
        commit('SET_OFFERS_LIST', data);
      })
      .catch(() => {
        commit('SET_OFFERS_LIST', []);
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
