






















import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/Layout/Header/Header.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import Error from '@/pages/Error.vue';
import {ErrorCode} from '@/store/modules/ui/ui.store';
import {createHelpers} from 'vuex-map-fields';
import DetailsModals from '@/pages/Details/DetailsModals.vue';
import LogoSplash from '@/components/UI/LogoSplash.vue';
import CookieBanner from '@/components/UI/CookieBanner.vue';
import LtiDisclamer from '@/components/Layout/LtiDisclamer.vue';
import RegistrationConfirmModal from '@/components/Layout/RegistrationConfirm.modal.vue';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    Header,
    Footer,
    Error,
    DetailsModals,
    LogoSplash,
    CookieBanner,
    LtiDisclamer,
    RegistrationConfirmModal
  },
  computed: {
    ...mapFields([
      'errorCode',
      'errorMessage'
    ])
  }
})
export default class DefaultLayout extends Vue {
  private errorCode!: null | ErrorCode;
  private errorMessage!: null | string;
}
