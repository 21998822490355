















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import InputEyeIcon from '@/assets/icon/input-eye.svg';
import InputEyeCrossedIcon from '@/assets/icon/input-eye-crossed.svg';

@Component({
  components: { InputEyeIcon, InputEyeCrossedIcon }
})
export default class InputActionPassword extends Vue {
  @Prop({
    type: Function,
    required: true
  })
  public isPassword!: () => boolean;

  @Prop({
    type: Function,
    required: true
  })
  public isPasswordVisible!: () => boolean;

  @Prop({
    type: Function,
    required: true
  })
  public action!: () => void;
}
