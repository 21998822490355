import Vue from 'vue';
import TokenService from '@/services/data/token.service';
import {ActionContext, ActionTree} from 'vuex';
import store, {RootState} from '@/store';
import AuthorizationService from '@/services/api/authorization.service';
import UserService, {IUser, PermissionType, UserType} from '@/services/api/user.service';
import { CookieService } from '@/services/data/cookie.service';
import { AxiosError } from 'axios';
import {getField, GetterTree, updateField} from 'vuex-map-fields';
import router from '@/router';

export interface IUserState {
  isInitializing: boolean;
  isInitialized: boolean;
  isAnonymousUser: boolean;
  token: null | string;
  user: null | IUser;
  isRegistrationConfirmed: boolean;
}

const initialState = (): IUserState => ({
  isInitializing: false,
  isInitialized: false,
  isAnonymousUser: true,
  token: null,
  user: null,
  isRegistrationConfirmed: false
});
const state = initialState();

const getters: GetterTree<IUserState, RootState> = {
  permission: (state: IUserState) => (permission: PermissionType): boolean => {
    return router.currentRoute.query.sid && permission === 'useSearch'
      ? true
      : state.user === null ? false : state.user.rights[permission];
  },
  isLtiMode: (state: IUserState) => state.user === null ? false : state.user.ltiMode,
  getUserField: (state: IUserState) => getField(state),
  getUserId: (state: IUserState) => state.user === null ? null : state.user.userId,
  getUserMaxShareDate: (state: IUserState) => state.user === null ? false : state.user.maxDateShare,
  getUserSharedBookmarkId: (state: IUserState) => state.user ? state.user.schoolersBookmark : null,
  getUserRevelant: (state: IUserState) => state.user === null ? false : state.user.relevantUser,
  getUserToken: (state: IUserState) => state.user? state.user.token : null,
  getUserAvailableMZList: (state: IUserState) => state.user?.availableMZ
    .filter(mz => ![117, 85, 89, 49, 97, 5736, 5635].includes(mz.id)) || [],
  getUserAvailableMZLength: (state: IUserState) => state.user === null ? null : state.user.availableMZ.length,
  isSelfRegisteredUser: (state: IUserState) => state.user 
    && state.user.userType.includes(UserType.SELF_REGISTERED_USER) 
    && !state.user.email,
  sharingMediaPermission: (state, getters, _rootState) => !getters.permission('student')
    && !state.isAnonymousUser
};
const mutations = {
  ['SET_IS_INITIALIZING'](state: IUserState, payload: boolean) {
    state.isInitializing = payload;
  },
  ['SET_IS_INITIALIZED'](state: IUserState, payload: boolean) {
    state.isInitialized = payload;
    state.isInitializing = false;
  },
  ['SET_IS_ANONYMOUS_USER'](state: IUserState, payload: boolean) {
    state.isAnonymousUser = payload;
  },
  ['SET_TOKEN'](state: IUserState, payload: null | string) {
    state.token = payload;
  },
  ['SET_USER'](state: IUserState, payload: null | IUser) {
    state.user = payload;
  },
  ['RESET_USER'](state: IUserState) {
    state.user = null;
  },
  ['SET_REGISTRATION_CONFIRM_SUCCESS'](state: IUserState, payload: boolean) {
    state.isRegistrationConfirmed = payload;
  },
  updateUserField(state: IUserState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IUserState, RootState> = {
  setToken({commit, getters}, token: null | string) {
    commit('SET_TOKEN', token);
    if (getters.isLtiMode) return;
    token ? TokenService.setToken(token) : TokenService.resetToken();
  },
  restoreToken({dispatch, getters}) {
    if (getters.isLtiMode) return;
    const token = TokenService.getToken();
    dispatch('setToken', token);
  },
  setUser({ commit, dispatch }, user: null | IUser) {
    dispatch('setToken', user && !user.ltiMode ? user.token : null);
    if (user !== null) {
      commit('SET_USER', user);
      commit('SET_IS_ANONYMOUS_USER', UserService.isAnonymousUser(user)); // TODO use getter?
    } else {
      commit('RESET_USER');
      commit('SET_IS_ANONYMOUS_USER', true);
    }
    dispatch('checkMediaCenter');
    commit('SET_LOADING', {user: false});
  },
  async getUserByToken({commit, dispatch}) {
    return UserService.getUser()
      .then((user: null | IUser) => {
        dispatch('setUser', user);
      })
      .catch((error: AxiosError) => {
        if (store.getters.getUiField('errorCode') === null) {
          dispatch('setToken', null);
          dispatch('getUserByToken');
        } else {
          commit('SET_IS_INITIALIZING', false);
          commit('SET_LOADING', {user: false});
        }
        return Promise.reject(error);
      });
  },
  async initialize({commit, dispatch}) {
    if (router.currentRoute.query.sid) {
      CookieService.set('cookieLogoSplash', true);
      dispatch('setIsLogoSplash', true);
      dispatch('setIsCookieBanner', true);
      commit('SET_IS_INITIALIZING', true);
      return dispatch('getUserByToken')
        .then(() => commit('SET_IS_INITIALIZED', true))
        .catch((error: AxiosError) => Promise.reject(error));
    } else {
      commit('SET_IS_INITIALIZING', true);
      const cookieLogoSplash = CookieService.get('cookieLogoSplash');
      const cookieBanner = CookieService.get('cookieBanner');
      if (!cookieLogoSplash){
        CookieService.set('cookieLogoSplash', true);
        dispatch('setIsLogoSplash', true);
      }
      if (!cookieBanner){
        dispatch('setIsCookieBanner', true);
      }
      dispatch('restoreToken');

      return dispatch('getUserByToken')
        .then(() => commit('SET_IS_INITIALIZED', true))
        .catch((error: AxiosError) => Promise.reject(error));
    }
  },
  async login({ rootState, commit, dispatch }: ActionContext<IUserState, RootState>) {
    const { login, password } = rootState.form.login;
    commit('SET_LOADING', {user: true});
    dispatch('setToken', null);
    return AuthorizationService.login({ login, password })
      .then((user: null | IUser) => {
        dispatch('setUser', user);
        if (!store.getters.getUserField('isInitialized')) {
          commit('SET_IS_INITIALIZED', true);
        }
        commit('SET_LOGIN_SUBMIT_SUCCESS', true);
        const pages = ['main', 'search', 'media-center-select'];
        if (router.currentRoute.name && pages.includes(router.currentRoute.name)) {
          router.go(0);
        }
      })
      .then(() => commit('RESET_LOGIN_FORM'))
      .catch(() => {
        commit('SET_LOADING', {user: false});
        Vue.prototype.$notify({
          group: 'app',
          type: 'error',
          title: 'Error',
          text: 'Anmelden fehlgeschlagen. Anmelden oder passwort stimmen nicht überein.'
        });
      });
  },
  async loginById({commit, dispatch }: ActionContext<IUserState, RootState>, id: string) {
    commit('SET_LOADING', {user: true});
    dispatch('setToken', null);
    return AuthorizationService.loginById(id)
      .then((user: null | IUser) => {
        dispatch('setUser', user);
        if (!store.getters.getUserField('isInitialized')) {
          commit('SET_IS_INITIALIZED', true);
        }
      })
      .catch(() => {
        commit('SET_LOADING', {user: false});
      });
  },
  async loginByPid({commit}: ActionContext<IUserState, RootState>, id: string) {
    commit('SET_LOADING', {user: true});
    return AuthorizationService.loginByPid(id)
      .then((user: null | IUser) => {
        TokenService.setToken(user!.token, 'idb');
        commit('SET_LOADING', {user: false});
      })
      .catch(() => {
        commit('SET_LOADING', {user: false});
      });
  },
  logout({commit, dispatch}) {
    commit('SET_LOADING', {user: true});
    AuthorizationService.logout()
      .then(() => {
        dispatch('setToken', null);
        dispatch('getUserByToken');
      })
      .catch(() => {
        dispatch('setToken', null);
        dispatch('getUserByToken');
      })
      .finally(() => {
        if (router.currentRoute.name !== 'main') {
          router.push({
            path: '/',
            name: 'main'
          });
        }
      });
  },
  async register({ rootState, commit, getters }: ActionContext<IUserState, RootState>) {
    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      school,
      subscribed,
      termsOfUseAccepted,
    } = rootState.form.register;
    const data = {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      school,
      subscribed,
      termsOfUseAccepted
    };
    commit('SET_LOADING', {register: true});
    return UserService.register(data)
      .then(() => {
        commit('SET_REGISTRATION_SUBMIT_SUCCESS', true);
        commit('SET_LOADING', {register: false});
      })
      .then(() => commit('RESET_REGISTRATION_FORM'))
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 409) {
          commit('SET_REGISTRATION_EMAIL_DUPLICATED', true);
          Vue.prototype.$notify({
            group: 'app',
            type: 'error',
            title: 'Error',
            text: `Die E-Mailadresse <b>${getters.getRegistrationFormEmail}</b> wird bereits verwendet. Bitte registrieren Sie sich mit einer anderen E-Mail-Adresse.`
          });
        }
        commit('SET_LOADING', {register: false});
      });
  },
  async resetPassword({rootState, commit}: ActionContext<IUserState, RootState>){
    const { email } = rootState.form.email;
    commit('SET_LOADING', {resetPassword: true});
    return AuthorizationService.resetPassword(email)
      .then(()=>{
        commit('SET_LOADING', {resetPassword: false});
      })
      .catch((error: AxiosError) => {
        console.error(error);
        commit('SET_LOADING', {resetPassword: false});
      });
  },
  async updateProfile({commit, dispatch, state}: ActionContext<IUserState, RootState>) {
    const { newsletter, antaresSync } = state.user as IUser ;

    commit('SET_LOADING', {user: true});
    return UserService.updateProfile(newsletter, antaresSync)
      .then((user: null | IUser) => {
        dispatch('setUser', user);
        commit('SET_LOADING', {user: false});
      })
      .catch(() => {
        commit('SET_LOADING', {user: false});
      });
  },
  async confirmRegistration({commit} , params: {code: string, email: string}) {
    commit('SET_LOADING', {user: true});
    return UserService.confirmRegistration(params.code, params.email)
      .then(() => {
        commit('SET_LOADING', {user: false});
        commit('SET_REGISTRATION_CONFIRM_SUCCESS', true);
      })
      .catch(() => {
        commit('SET_LOADING', {user: false});
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
