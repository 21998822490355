









import Vue from 'vue';
import Component from 'vue-class-component';
import LogoIcon from '@/assets/image/logo.svg';

@Component({
  components: { LogoIcon }
})
export default class Logo extends Vue {
  public get isDisabled(): boolean {
    return !!this.$store.getters.permission('student') 
      || !!this.$route.query.sid;
  }
}
