import {ActionTree, MutationTree} from 'vuex';
import {RootState} from '@/store';
import {getField, updateField} from 'vuex-map-fields';
import {IRegistrationData, ISchoolData} from '@/services/api/user.service';

export interface IRegistrationForm extends IRegistrationData {
  schoolModel: null | ISchoolData;
  isLicenceOpened: boolean;
  submitSuccess: boolean;
  emailDuplicated: boolean;
}
const initialState = (): IRegistrationForm => ({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  school: null,
  schoolModel: null,
  subscribed: false,
  termsOfUseAccepted: false,
  isLicenceOpened: false,
  submitSuccess: false,
  emailDuplicated: false,
});
const state: IRegistrationData = initialState();

const getters = {
  getRegistrationFormEmail: (state: IRegistrationForm) => state.email,
  isRegistrationEmailDuplicated: (state: IRegistrationForm) => state.emailDuplicated,
  getRegistrationFormField(state: IRegistrationData) {
    return getField(state);
  }
};
const mutations: MutationTree<IRegistrationForm> = {
  ['RESET_REGISTRATION_FORM'](state: IRegistrationData) {
    Object.assign(state, initialState());
  },
  ['SET_IS_LICENCE_OPENED'](state: IRegistrationForm, payload: boolean) {
    state.isLicenceOpened = payload;
  },
  ['SET_REGISTRATION_SUBMIT_SUCCESS'](state: IRegistrationForm, payload: boolean) {
    state.submitSuccess = payload;
  },
  ['SET_REGISTRATION_EMAIL_DUPLICATED'](state: IRegistrationForm, payload: boolean) {
    state.emailDuplicated = payload;
  },
  updateRegistrationFormField(state: IRegistrationForm, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IRegistrationData, RootState> = {
  setIsLicenceOpened({commit}, value: boolean) {
    commit('SET_IS_LICENCE_OPENED', value);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
