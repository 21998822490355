import { RouteConfig } from 'vue-router';

const developmentRoutes: RouteConfig[] = [];

if (process.env.NODE_ENV !== 'production') {
  developmentRoutes.push(
    {
      path: '/ui',
      name: 'ui',
      component: () => import(/* webpackChunkName: "Public" */ '@/pages/UI/Ui.vue'),
      meta: {
        layout: 'default'
      }
    },
  );
}

export default developmentRoutes;
