








import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import BackspaceIcon from '@/assets/icon/backspace.svg';

@Component({
  components: { BackspaceIcon }
})
export default class InputActionClear extends Vue {
  @Prop({
    type: Function,
    required: true
  })
  public value!: () => string;

  @Prop({
    type: String,
    required: true
  })
  public title!: string;

  @Prop({
    type: Function,
    required: true
  })
  public action!: () => void;
}
