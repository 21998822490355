import {ActionTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import {ILtiKey} from '@/services/api/lti.service';
import LtiApiService from '@/services/api/lti.service';

export interface ILtiState {
  keyList: ILtiKey[]
}
const initialState = (): ILtiState => ({
  keyList: [],
});
const state = initialState();
const getters = {
  getLtiField: (state: ILtiState) => getField(state)
};
const mutations = {
  ['SET_LTI_KEYLIST'](state: ILtiState, payload: ILtiKey[]) {
    state.keyList = payload;
  },
  updateLtiField(state: ILtiState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<ILtiState, RootState> = {
  async getLtiKeys({commit}) {
    commit('SET_LOADING', {lti: true});
    return LtiApiService.getLtiKeys()
      .then((data) => {
        commit('SET_LTI_KEYLIST', data);
        commit('SET_LOADING', {lti: false});
      })
      .catch(() => {
        commit('SET_LOADING', {lti: false});
      });
  },
  async addLtiKey({commit, dispatch}, keyName: string) {
    commit('SET_LOADING', {lti: true});
    return LtiApiService.addLtiKey(keyName)
      .then(() => {
        dispatch('getLtiKeys');
      })
      .catch(() => {
        commit('SET_LOADING', {lti: false});
      });
  },
  async removeLtiKey({commit, dispatch}, id: string) {
    commit('SET_LOADING', {lti: true});
    return LtiApiService.removeLtiKey(id)
      .then(() => {
        dispatch('getLtiKeys');
      })
      .catch(() => {
        commit('SET_LOADING', {lti: false});
      });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
