

























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {LinkList, ILinkItem} from '@/interfaces/interfaces';

@Component({})
export default class Links extends Vue {
  @Prop({ default: '' }) readonly heading!: string;
  @Prop({ default: () => []}) readonly itemList!: LinkList;
  @Prop({
    type: String,
    default: ''
  })
  public externalLink!: string;

  public openModal(item: ILinkItem) {
    this.$emit('openModal', item.id);
  }
}
