








import Vue from 'vue';
import Component from 'vue-class-component';
import UserMenu from '@/components/Layout/Header/UserMenu.vue';

@Component({
  components: {
    UserMenu
  }
})
export default class User extends Vue {
  public get initials(): string {
    return this.$store.state.user.user.firstName.charAt(0) + this.$store.state.user.user.secondName.charAt(0);
  }
}
