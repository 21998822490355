




































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IDictionary} from '@/interfaces/interfaces';
import ChevronDown from '../../assets/icon/chevron-down.svg';

@Component({
  components: { ChevronDown },
  inheritAttrs: false
})
export default class MultiSelect extends Vue {

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public loading!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public showLabels!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public preserveSearch!: boolean;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public placeholder!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public isShowSingleError!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorMessageList!: IDictionary<string>;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public showChevron!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorState!: IDictionary<boolean>;

  public get classObject(): object {
    return {
      'select-input': true,
      error: this.errorState.$invalid && this.errorState.$dirty
    };
  }
}
