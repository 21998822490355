import TokenService from '@/services/data/token.service';
import {StorageService} from '@/services/data/storage.service';
import {IEducationalStandard} from '@/services/api/educational-standarts.service';

export interface IEducationalStandardsService {
  restore: (key: string) => null | IEducationalStandard;
  store: (key: string, value: null | IEducationalStandard) => void;
}

const restore = (key: string): null | IEducationalStandard => {
  return StorageService.get<IEducationalStandard>(`${key}-${TokenService.getToken()}`);
};
const store = (key: string, value: null | IEducationalStandard): void => {
  value === null
    ? StorageService.remove(`${key}-${TokenService.getToken()}`)
    : StorageService.set(`${key}-${TokenService.getToken()}`, value);
};

const EducationalStandardsService: IEducationalStandardsService = {
  restore,
  store
};

export default EducationalStandardsService;
