

























































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import { IModal } from '@/interfaces/interfaces';
import {IMedia, MediaType} from '@/services/api/media.service';

import BookmarksForm from '@/pages/Bookmarks/Bookmarks.form.vue';
import CreateBookmarkForm from '@/pages/Bookmarks/CreateBookmark.form.vue';
import Iframe from '@/components/UI/Iframe.vue';
import Video from '@/components/UI/Video.vue';

import EyeIcon from '@/assets/icon/ico-eye.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import ExpandIcon from '@/assets/icon/ico-fullscreen.svg';

export interface DetailsModalsParams {
  id: string, 
  mediaIdList?: number[], 
  mediaItemType?: MediaType,
  media?: IMedia
  rentalUrl?: string;
}

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: {
    EyeIcon,
    DownloadIcon,
    PlusIcon,
    ExpandIcon,
    BookmarksForm,
    CreateBookmarkForm,
    Iframe,
    Video,
  },
  computed: {...mapFields([
      'isModalBookmarkListLoading',
    ]),
  }
})

export default class DetailsModals extends Vue {
  private PlusIcon = PlusIcon;
  private ExpandIcon = ExpandIcon;

  public mediaIdList: number[] = [];
  public mediaId: number | null = null;
  public mediaItemType: MediaType | null = null;
  public media: IMedia | null = null;
  public rentalUrl: string | null = null;
  public isModalBookmarkListLoading!: boolean;
  public isLoading: boolean = false;

  private params: DetailsModalsParams | null = null;

  private get modals(): IModal[] {
    return [
      {
        id: 'iframe',
        title: this.title,
        maxWidth: '1200px',
        height: '100%',
        component: Iframe
      },
      {
        id: 'rental',
        title: this.title,
        maxWidth: '855px',
        height: '100%',
        component: Iframe
      },
      {
        id: 'video',
        title: this.title,
        maxWidth: '956px',
        component: Video
      },
      {
        id: 'addMedia',
        title: 'Zur Merkliste hinzufügen',
        maxWidth: '512px',
        component: BookmarksForm
      },
      {
        id: 'addMediaToNewBookmark',
        title: 'Neue Merkliste',
        maxWidth: '512px',
        component: CreateBookmarkForm
      }
    ];
  }

  public mounted() {
    this.$root.$on('openDetailsModal', (params: DetailsModalsParams) => this.openModal(params));
    this.$root.$on('closeDetailsModal', () => this.closeModal());
    this.$root.$on('closeModalByHash', () => this.closeModal());
    this.$root.$on('toggleLoadingDetailsModal', (value: boolean) => this.toggleLoading(value));
  }

  public get title() {
    return this.media ? this.media.title : '';
  }

  public get url(): string {
    return this.media 
      && this.media.dataInfo 
      && this.media.dataInfo.versions
      && this.media.dataInfo.versions.directory
        ? this.media.dataInfo.versions.directory.url 
        : '';
  }

  private visibleData: boolean[] = [];

  private setDefaultVisibleData() {
    this.visibleData = [...Array(this.modals.length).fill(false)];
  }

  private getIndexById(id: string): number {
    return this.modals
      .findIndex((modal: IModal) => modal.id === id);
  }

  public openModal(params: DetailsModalsParams) {
    this.params = params;
    if (params.mediaItemType) this.mediaItemType = params.mediaItemType;
    if (params.mediaIdList) this.mediaIdList = params.mediaIdList;
    if (params.media) this.media = params.media;
    if (params.rentalUrl) this.rentalUrl = params.rentalUrl;

    this.mediaIdList.length > 1
      ? this.mediaId = null
      : this.mediaId = this.mediaIdList[0];

    this.setDefaultVisibleData();
    this.visibleData
      .splice(this.getIndexById(params.id), 1, true);

    if (['iframe', 'video'].includes(params.id)) {
      this.$router.push({query: this.$route.query, hash: `modal-${Date.now()}`});
    }
  }

  public closeModal(params?: { iframeHistoryBack?: boolean }) {
    const close = () => {
      this.mediaId = null;
      this.mediaIdList = [];
      this.mediaItemType = null;

      this.setDefaultVisibleData();
      this.toggleLoading(false);
    };

    if (!params?.iframeHistoryBack) {
      close();
    }

    if (params?.iframeHistoryBack) {
      this.$root.$emit('initIframeHistoryBack');
    }
  }

  public toggleLoading(value: boolean) {
    this.isLoading = value;
  }
}
