import Vue from 'vue';
import Vuex from 'vuex';
import ui, {IUiState} from './modules/ui/ui.store';
import form, {IFormState} from './modules/form/form.store';
import lightbox, {ILightboxState} from '@/store/modules/ui/lightbox.store';
import user, {IUserState} from '@/store/modules/user.store';
import mediaCenter, {IMediaCenterState} from '@/store/modules/media-center.store';
import search, {ISearchState} from '@/store/modules/search.store';
import searchFilters, {ISearchFiltersState} from '@/store/modules/search.filters.store';
import searchMediaFilters, {ISearchMediaFiltersState} from '@/store/modules/search.mediaFilters.store';
import searchEducationalStandards, {ISearchEducationalStandardsState} from '@/store/modules/search.educationalStandards.store';
import chart, {IChartState} from '@/store/modules/chart.store';
import offers, {IOffersState} from '@/store/modules/offers.store';
import educationalStandards, {IEducationalStandardsState} from '@/store/modules/educational-standarts.store';
import slider, { ISliderState } from './modules/slider.head.mainpage.store';
import bookmark, {IBookmarkState} from '@/store/modules/bookmark.store';
import media, {IMediaState} from '@/store/modules/media.store';
import lti, {ILtiState} from '@/store/modules/lti.store';
import statistic from '@/store/modules/statistic.store';
Vue.use(Vuex);

export interface RootState {
  ui: IUiState;
  user: IUserState;
  lightbox: ILightboxState;
  form: IFormState;
  mediaCenter: IMediaCenterState;
  search: ISearchState;
  searchFilters: ISearchFiltersState;
  searchMediaFilters: ISearchMediaFiltersState;
  searchEducationalStandards: ISearchEducationalStandardsState;
  chart: IChartState;
  offers: IOffersState;
  educationalStandards: IEducationalStandardsState;
  slider: ISliderState;
  bookmark: IBookmarkState;
  media: IMediaState;
  lti: ILtiState;
  statistic: object;
}

export default new Vuex.Store<RootState>({
  modules: {
    ui,
    user,
    lightbox,
    form,
    mediaCenter,
    search,
    searchFilters,
    searchMediaFilters,
    searchEducationalStandards,
    chart,
    offers,
    educationalStandards,
    slider,
    bookmark,
    media,
    lti,
    statistic
  }
});
