import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import {getField, updateField} from 'vuex-map-fields';

export interface ILightboxState {
  visible: boolean;
  lightboxData: ILighBoxData | null;
}

export interface ILighBoxData {
  url: string;
  component: string | null;
}

const initialState = (): ILightboxState => ({
  visible: false,
  lightboxData: {
    url: '',
    component: null,
  }
});
const state = initialState();

const getters = {
  getLightboxField(state: ILightboxState) {
    return getField(state);
  }
};

const mutations = {
  ['SET_LIGHTBOX_VISIBILITY'](state: ILightboxState, payload: boolean) {
    state.visible = payload;
  },
  ['SET_LIGHTBOX_DATA'](state: ILightboxState, payload: ILighBoxData) {
    state.lightboxData = payload;
  },
  updateLightboxField(state: ILightboxState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<ILightboxState, RootState> = {
  openLightbox({ commit }, data) {
    commit('SET_LIGHTBOX_VISIBILITY', true);
    commit('SET_LIGHTBOX_DATA', data);
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
  },
  closeLightbox({ commit }) {
    commit('SET_LIGHTBOX_VISIBILITY', false);
    commit('SET_LIGHTBOX_DATA', null);
    document.body.style.position = 'static';
    document.body.style.overflow = 'auto';
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
