








import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {ITab} from './interfaces';

@Component({})
export default class Tab extends Vue implements ITab {
  @Prop({
    type: String,
    required: true
  })
  public title!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public disabled!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public hidden!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private selected!: boolean;

  public isActive: boolean = true;

  @Watch('isActive')
  public onIsActiveChanged(active: boolean): void {
    if (active) this.$emit('activate');
  }
}
