import { AxiosRequestConfig } from 'axios';
import TokenService from '@/services/data/token.service';
import store from '@/store';
import router from '@/router';

const requestInterceptor = (config: AxiosRequestConfig) => {
  store.commit('SET_ERROR_CODE', null);
  const token = router.currentRoute.query.sid
    ? router.currentRoute.query.sid
    : TokenService.getToken();
  if (token) {
    config.headers.token = token;
  }
  return config;
};

export default requestInterceptor;
