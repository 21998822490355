





















import {
  Vue,
  Component,
  Prop
} from 'vue-property-decorator';
import {email, required} from 'vuelidate/lib/validators';
import {createHelpers} from 'vuex-map-fields';
import {IDictionary} from '@/interfaces/interfaces';
import UserProfile from '@/assets/icon/user-profile.svg';

const { mapFields } = createHelpers({
  getterType: 'getUserEmail',
  mutationType: 'resetPassword'
});

const validations = {
  email: {
    required,
    email
  }
};

@Component({
  validations,
  computed: {...mapFields([
      'email'
    ])
  },
})
export default class ResetPassword extends Vue {

  public UserProfile = UserProfile;

  public emailErrorMessageList = {
    required: 'Empty email',
    email: 'Incorrect email'
  };

  @Prop({
    type: String,
    required: false,
    default: 'reset'
  })
  private type!: 'reset' | 'change';

  private actionTitleDictionary: IDictionary<string> = {
    reset: 'Zurücksetzen',
    change: 'Ändern'
  };

  private get actionTitle(): string {
    return this.actionTitleDictionary[this.type];
  }

  public submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$store.dispatch('resetPassword')
        .then(() => {
          this.$emit('openModal', `${this.type}PasswordSuccess`);
        });
    }
  }

}
