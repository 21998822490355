import {getField, updateField} from 'vuex-map-fields';
import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import SliderApiService, {ISlide} from '@/services/api/head-slider.service';

export interface ISliderState {
  slides: ISlide[]
}

const initialState = (): ISliderState => ({
  slides: []
});

const state = initialState();

const getters = {
  getSlidesField(state: ISliderState) {
    return getField(state);
  },
  getSlideList(state: ISliderState): ISlide[] {
    return state.slides;
  }
};

const mutations = {
  ['SET_SLIDE_LIST'](state: ISliderState, payload: ISlide[]) {
    state.slides = payload;
  },
  updateSlidesField(state: ISliderState, slides: ISlide[]) {
    return updateField(state, slides);
  }
};

const actions: ActionTree<ISliderState, RootState> = {
  async setSlides({commit}) {
    commit('SET_LOADING', {setSlides: true});
    SliderApiService.getSlideList()
      .then((data: ISlide[]) => {
        commit('SET_SLIDE_LIST', data);
        commit('SET_LOADING', {setSlides: false});
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
