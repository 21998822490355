

























import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import Input from '@/components/UI/Input/Input.vue';
import { IBookmark } from '@/services/api/bookmark.service';

import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: { Input },
  computed: {...mapFields([
      'bookmarkListModal',
      'bookmarkList'
    ]),
  },
  mixins: [adaptiveButtonSize]
})

export default class CreateBookmarkForm extends Vue {
  public name: string = '';
  private bookmarkListModal!: IBookmark[];
  private bookmarkList!: IBookmark[];
  public get acceptButton():string {
    return this.$attrs.action === 'renameBookmark' 
      ? 'Ok' 
      : 'Anlegen';
  }

  public mounted() {
    (this.$refs.input as Input).setFocus();
    const openAddMedia = this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'addMedia') {
          this.$emit('openModal', 'addMedia');
          openAddMedia();
        }
      }
    });
  }
  
  public callBookmarkAction(): void {
    if (!this.name) {
      this.notifyNameError('Bitte vergeben Sie einen Namen für Ihre Merkliste');
      return;
    }
    if (this.isNameDublicated) {
      this.notifyNameError('Der Name ist bereits für eine andere Merkliste vergeben');
      return;
    }
    this.$store.dispatch(
      this.$attrs.action, 
      {
        bookmarkName: this.name,
        bookmarkIdList: this.$attrs.bookmarkIdList, 
        mediaIdList: this.$attrs.mediaIdList
      }
    )
      .then(() => {
        if (this.$attrs.action)
          this.$emit('closeModal');
      });
  }

  public get isNameDublicated(): boolean {
    const list = this.bookmarkList[0]
      ? this.bookmarkList
      : this.bookmarkListModal;
    return !!list
      .filter(bookmark => bookmark.collectionName === this.name)[0];
  }

  public notifyNameError(text: string) {
    this.$notify({
      group: 'app',
      type: 'warning',
      title: 'Merklisten',
      text
    });
  }

  public cancel(): void {
    this.$emit('closeModal');
  }
}
