













import Vue from 'vue';
import Component from 'vue-class-component';
import LogoIcon from '@/assets/image/logo.svg';

@Component({
  components: {
    LogoIcon
  }
})
export default class ErrorBrowserNotValid extends Vue {}
