












import Vue from 'vue';
import Component from 'vue-class-component';
import Error from '@/pages/Error.vue';
import {ErrorCode} from '@/store/modules/ui/ui.store';
import {createHelpers} from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  components: {
    Error
  },
  computed: {
    ...mapFields([
      'errorCode'
    ])
  }
})
export default class FrameLayout extends Vue {
  private errorCode!: null | ErrorCode;
}
