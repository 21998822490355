
























import Vue from 'vue';
import Component from 'vue-class-component';
import { createHelpers } from 'vuex-map-fields';

import InputLink from '@/components/UI/InputLink.vue';
import Textarea from '@/components/UI/Textarea.vue';
import dayjs from 'dayjs';

const { mapFields } = createHelpers({
  getterType: 'getMediaField',
  mutationType: 'updateMediaField',
});

@Component({
  components: {
    InputLink,
    Textarea
  },
  computed: {...mapFields([
      'mediaSharedToken',
      'mediaSharedDateTo',
      'mediaSharedPath',
      'mediaAssetPath',
      'mediaAssetToken',
      'mediaAssetDateTo'
    ]),
  }
})
export default class EmbedResult extends Vue {
  private mediaSharedToken!: string;
  private mediaSharedDateTo!: string;
  private mediaSharedPath!: string;
  private mediaAssetPath!: string;
  private mediaAssetToken!: string;
  private mediaAssetDateTo!: string;

  private get sharedUrl() {
    return `${window.location.origin}/${this.mediaSharedPath}/${this.mediaSharedToken}`;
  }

  private get assetUrl() {
    return `${window.location.origin}/${this.mediaAssetPath}/${this.mediaAssetToken}`;
  }

  private get sharedDateTo() {
    return dayjs(this.mediaSharedDateTo ?? this.mediaAssetDateTo).format('DD.MM.YYYY');
  }

  private get snippet() {
    return `<iframe width="560" height="315" src="${this.sharedUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><!-- Der folgende Tag wird aus Urheberrechtsgründen benötigt. Bitte auch kopieren -->`;
  }
}
