
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';

import InputCloseIcon from '@/assets/icon/input-close.svg';
import { ILighBoxData } from '@/store/modules/ui/lightbox.store';

import ImageViewer from '@/components/Viewer/ImageViewer.vue';
import PdfViewer from '@/components/Viewer/PdfViewer.vue';

const { mapFields } = createHelpers({
  getterType: 'getLightboxField',
  mutationType: 'updateLightboxField',
});

@Component({
  components: {
    InputCloseIcon,
    ImageViewer,
    PdfViewer
  },
  computed: {...mapFields([
      'visible',
      'lightboxData'
    ])
  }
})

export default class Lightbox extends Vue {
  public visible!: boolean;
  public lightboxData!: ILighBoxData;
  public InputCloseIcon = InputCloseIcon;

  private get classObject(): object {
    return {
      'visible': this.visible
    };
  }

  public get component() {
    return this.lightboxData.component;
  }

  @Watch('$route')
  private onRouteChanged() {
    this.$store.dispatch('closeLightbox');
  }
}
