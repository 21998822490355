













import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {ButtonMode, ButtonSize} from '@/interfaces/interfaces';

@Component({})
export default class LinkButton extends Vue {

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public icon!: string | null;

  @Prop({
    type: String,
    required: false,
    default: 'primary'
  })
  private mode!: ButtonMode;

  @Prop({
    type: String,
    required: false,
    default: 'sm'
  })
  private size!: ButtonSize;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private border!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private disabled!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private iconLeft!: boolean;

  private get classObject(): object {
    return {
      [`${this.mode}-button`]: true,
      [`button-${this.size}`]: true,
      'button-border': this.border,
      'disabled': this.disabled
    };
  }
}
