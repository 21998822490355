import {ActionTree, GetterTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import {
  ExpertSearchFilterDictionary, ExpertSearchFilterDictionaryData,
  ExpertSearchFilterMapping,
  ExpertSearchFilterType,
  FilterDictionary,
  IFilterData,
  IFilterItem, SearchFilterType,
} from '@/services/api/search.service';
import {decodeNumberArrayParameter} from '@/utilities';

export interface ISearchMediaFiltersState {
  selectedFilters: ExpertSearchFilterDictionaryData<number[]>;
  subject: IFilterData[];
  targetGroup: IFilterData[];
  mediaType: IFilterData[];
  loanable: IFilterData[];
}

export const filterMapping: ExpertSearchFilterMapping = {
  subjectList: 'subject',
  targetGroupList: 'targetGroup',
  mediaTypeList: 'mediaType',
  loanableList: 'loanable'
};

const emptyFilters = () => ({
  subject: [],
  targetGroup: [],
  mediaType: [],
  loanable: []
});

const initialState = (): ISearchMediaFiltersState => ({
  selectedFilters: {},
  ...emptyFilters()
});

const state = initialState();

const getters: GetterTree<ISearchMediaFiltersState, RootState> = {
  getSearchMediaFiltersField: (state: ISearchMediaFiltersState) => getField(state),
  selectedFilters: (state: ISearchMediaFiltersState) => state.selectedFilters,

  subject: (state: ISearchMediaFiltersState) => state.subject,
  targetGroup: (state: ISearchMediaFiltersState) => state.targetGroup,
  mediaType: (state: ISearchMediaFiltersState) => state.mediaType,
  loanable: (state: ISearchMediaFiltersState) => state.loanable,

  isSearchMediaFiltersSelected: (state: ISearchMediaFiltersState) => {
    const filters = [
      ...state.loanable,
      ...state.mediaType,
      ...state.subject,
      ...state.targetGroup
    ];
    return filters.filter(item => item.value).length > 0;
  }
};

const mutations = {
  updateSearchMediaFiltersField(state: ISearchMediaFiltersState, field: string) {
    return updateField(state, field);
  },
  ['RESET_SELECTED_FILTERS'](state: ISearchMediaFiltersState) {
    state.selectedFilters = {};
  },
  ['SET_SELECTED_FILTERS'](state: ISearchMediaFiltersState, payload: ExpertSearchFilterDictionaryData<number[]>) {
    state.selectedFilters = payload;
  },

  ['SET_FILTERS'](state: ISearchMediaFiltersState, payload: FilterDictionary<IFilterData[]>) {
    (Object.keys(payload) as SearchFilterType[])
      .map((filter: SearchFilterType) => {
        state[filter] = payload[filter];
      });
  },

};

const actions: ActionTree<ISearchMediaFiltersState, RootState> = {
  parseSelectedFilters: ({commit}, parameters: Partial<FilterDictionary<string>> = {}): void => {
    const selectedFilters = (Object.keys(filterMapping) as ExpertSearchFilterType[])
      .reduce(
        (result: Partial<ExpertSearchFilterDictionary<number[]>>, filterName: ExpertSearchFilterType) => {
          const numericArray: number[] = decodeNumberArrayParameter(parameters[filterMapping[filterName]], []);
          if (numericArray.length) {
            result[filterName] = numericArray;
          }
          return result;
        },
        {}
      );
    commit('SET_SELECTED_FILTERS', selectedFilters);
  },
  setFilters({commit, getters, state}) {
    const filterDictionary: ExpertSearchFilterDictionary<IFilterItem[]> = getters.getFiltersData;
    const searchFilters = (Object.keys(filterDictionary) as ExpertSearchFilterType[])
      .reduce(
        (result: FilterDictionary<IFilterData[]>, filterName: ExpertSearchFilterType) => {
          const selectedFilterList: number[] = state.selectedFilters[filterName] || [];
          result[filterMapping[filterName]] = filterDictionary[filterName]
            .map(
              (item: IFilterItem) => {
                const value = selectedFilterList.length
                  ? selectedFilterList.includes(item.id)
                  : false;
                return {...item, value};
              }
            );
          return result;
        },
        emptyFilters()
      );
    commit('SET_FILTERS', searchFilters);
    commit('SET_APPLIED_FILTER', getters.getAppliedFilter);
  },
  setSelectedFilters: ({commit}): Promise<void> => {
    const selectedFilters = (Object.keys(filterMapping) as ExpertSearchFilterType[])
      .reduce(
        (result: Partial<FilterDictionary<number[]>>, filterName: ExpertSearchFilterType) => {
          const idList = state[filterMapping[filterName]]
            .filter((item: IFilterData) => item.value)
            .map((item: IFilterData) => item.id);
          if (idList.length) {
            result[filterMapping[filterName]] = idList;
          }
          return result;
        },
        {}
      );
    commit('SET_SELECTED_FILTERS', selectedFilters);
    return Promise.resolve();
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
