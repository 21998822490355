























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

import CopyToClipBoard from '@/components/UI/CopyToClipBoard.vue';

@Component({
  components: { CopyToClipBoard }
})
export default class Textarea extends Vue {
  @PropSync('value', { type: String }) 
  public inputValue!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public placeholder!: string;

  @Prop({
    type: String,
    required: false,
    default: '140px'
  })
  public height!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public note!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public isDisabled!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public copyValue!: boolean;

  public get classObject(): object {
    return {
      'disabled': this.isDisabled,
      'has-copy': this.copyValue
    };
  }

  public get styleObject(): object {
    return {
      '--textarea-min-height': this.height,
    };
  }

  public resize() {
    this.$nextTick(() => {
      const element = (this.$el as HTMLElement).querySelector('.textarea') as HTMLElement;
      const updateHeight = () => {
        element.style.height = '';
        element.style.height = `${element.scrollHeight}px`;
      };
      requestAnimationFrame(updateHeight);
    });
  }

  public mounted() {
    this.resize();
  }

  @Watch('inputValue')
  private onInputValueChanged() {
    this.resize();
  }
}
