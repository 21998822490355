





















import { Vue, Component } from 'vue-property-decorator';
import { CookieService } from '@/services/data/cookie.service';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

@Component({
  mixins: [adaptiveButtonSize]
})
export default class CookieBanner extends Vue {
  public accept(): void {
    CookieService.set('cookieBanner', true);
    this.$store.dispatch('setIsCookieBanner', false);
  }

  public close(): void {
    this.$store.dispatch('setIsCookieBanner', false);
  }
}
