






































import Vue from 'vue';
import Component from 'vue-class-component';
import Logo from '../Logo.vue';
import Search from './Search.vue';
import HeaderMenu from './HeaderMenu.vue';
import Register from './Register.vue';
import Login from './Login.vue';
import Bookmarks from './Bookmarks.vue';
import EducationalStandards from './EducationalStandards.vue';
import User from './User.vue';
import HeaderModals from '@/components/Layout/Header/HeaderModals.vue';
import Permission from '@/components/Permission/Permission.vue';
import { Watch } from 'vue-property-decorator';
import appScroll from '@/directives/app-scroll';

@Component({
  components: {
    Logo,
    Search,
    HeaderMenu,
    Register,
    Login,
    Bookmarks,
    EducationalStandards,
    User,
    HeaderModals,
    Permission
  },
  directives: {appScroll}
})
export default class Header extends Vue {
  private isCloseDisabled: boolean = false;
  private sticky: boolean = false;

  private handleScroll(event: Event) {
    const app = event.target as HTMLElement;
    app.scrollTop > 0
      ? this.sticky = true
      : this.sticky = false;
  }

  @Watch('$route')
  private onRouteChanged() {
    this.isCloseDisabled = 'search' === this.$route.name;
  }
}
