import {ActionTree, GetterTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import SearchApiService, {
  IFilterData,
  IFilterItem,
  IEducationalStandardsItem,
  ISearchResponse
} from '@/services/api/search.service';
import EducationalStandardsApiService, {IEducationalStandard} from '@/services/api/educational-standarts.service';
import {decodeNumberArrayParameter} from '@/utilities';
import { IMedia } from '@/services/api/media.service';

export interface ISearchEducationalStandardsState {
  aspect: IFilterData[];
  selectedAspect: number[];
  educationalStandard: number[];
  schoolType: null | IEducationalStandard;
  schoolTypeList: IEducationalStandard[];
  schoolTypeLoading: boolean;
  subject: null | IEducationalStandard;
  subjectList: IEducationalStandard[];
  subjectLoading: boolean;
  classroom: null | IEducationalStandard;
  classroomList: IEducationalStandard[];
  classroomLoading: boolean;
  educationalStandardFilters: IEducationalStandardsItem[];
  selectedEducationalStandardFilters: number[];
}

const initialState = (): ISearchEducationalStandardsState => ({
  aspect: [],
  selectedAspect: [],
  educationalStandard: [],
  schoolType: null,
  schoolTypeList: [],
  schoolTypeLoading: false,
  subject: null,
  subjectList: [],
  subjectLoading: false,
  classroom: null,
  classroomList: [],
  classroomLoading: false,
  educationalStandardFilters: [],
  selectedEducationalStandardFilters: []
});

const state = initialState();

const getters: GetterTree<ISearchEducationalStandardsState, RootState> = {
  getSearchEducationalStandardsField: (state: ISearchEducationalStandardsState) => getField(state),
  educationalStandard: (state: ISearchEducationalStandardsState) => state.educationalStandard,

  schoolTypeFilterLoading: (state: ISearchEducationalStandardsState) => state.schoolTypeLoading,
  subjectFilterLoading: (state: ISearchEducationalStandardsState) => state.subjectLoading,
  classroomFilterLoading: (state: ISearchEducationalStandardsState) => state.classroomLoading,

  schoolTypeFilterList: (state: ISearchEducationalStandardsState) => Array.from(state.schoolTypeList) || [],
  subjectFilterList: (state: ISearchEducationalStandardsState) => Array.from(state.subjectList) || [],
  classroomFilterList: (state: ISearchEducationalStandardsState) => Array.from(state.classroomList) || [],

  selectedAspect: (state: ISearchEducationalStandardsState): number[] => state.selectedAspect,
  selectedEducationalStandardFilters: (state: ISearchEducationalStandardsState): number[] => state.selectedEducationalStandardFilters,
  getEducationalStandardFilters: (state: ISearchEducationalStandardsState): number[] => {
    return [
      state.schoolType
      && state.schoolType.id,
      state.subject
      && state.subject.id,
      state.classroom
      && state.classroom.id
    ]
      .filter((item: null | number) => item !== null) as number[];
  },
  getEducationalStandardFilterList: (state: ISearchEducationalStandardsState) => (id: number): IEducationalStandardsItem[] =>
    state.educationalStandardFilters
      .filter((item: IEducationalStandardsItem) => item.parentId === id),
  isSearchEducationStandartsFiltersSelected: (state: ISearchEducationalStandardsState): boolean => {
    return state.aspect.filter(item => item.value).length > 0
      || !!state.schoolType;
  }
};

const mutations = {
  updateSearchEducationalStandardsField(state: ISearchEducationalStandardsState, field: string) {
    return updateField(state, field);
  },
  ['SET_SELECTED_ASPECT'](state: ISearchEducationalStandardsState, payload: number[]) {
    state.selectedAspect = payload;
  },
  ['RESET_SELECTED_ASPECT'](state: ISearchEducationalStandardsState) {
    state.selectedAspect = [];
  },
  ['SET_ASPECT'](state: ISearchEducationalStandardsState, payload: IFilterData[]) {
    state.aspect = payload;
  },
  ['SET_EDUCATION_STANDARDS'](state: ISearchEducationalStandardsState, payload: number[]) {
    state.educationalStandard = payload;
  },
  ['RESET_EDUCATION_STANDARDS'](state: ISearchEducationalStandardsState) {
    state.educationalStandard = [];
  },
  ['SET_SCHOOL_TYPE_FILTER'](state: ISearchEducationalStandardsState, payload: null | IEducationalStandard) {
    state.schoolType = payload;
  },
  ['SET_SCHOOL_TYPE_FILTER_LIST'](state: ISearchEducationalStandardsState, payload: IEducationalStandard[]) {
    state.schoolTypeList = payload;
  },
  ['SET_SCHOOL_TYPE_FILTER_LOADING'](state: ISearchEducationalStandardsState, payload: boolean) {
    state.schoolTypeLoading = payload;
  },
  ['SET_SUBJECT_FILTER'](state: ISearchEducationalStandardsState, payload: null | IEducationalStandard) {
    state.subject = payload;
  },
  ['SET_SUBJECT_FILTER_LIST'](state: ISearchEducationalStandardsState, payload: IEducationalStandard[]) {
    state.subjectList = payload;
  },
  ['SET_SUBJECT_FILTER_LOADING'](state: ISearchEducationalStandardsState, payload: boolean) {
    state.subjectLoading = payload;
  },
  ['SET_CLASSROOM_FILTER'](state: ISearchEducationalStandardsState, payload: null | IEducationalStandard) {
    state.classroom = payload;
  },
  ['SET_CLASSROOM_FILTER_LIST'](state: ISearchEducationalStandardsState, payload: IEducationalStandard[]) {
    state.classroomList = payload;
  },
  ['SET_CLASSROOM_FILTER_LOADING'](state: ISearchEducationalStandardsState, payload: boolean) {
    state.classroomLoading = payload;
  },
  ['SET_EDUCATION_STANDARDS_FILTERS'](state: ISearchEducationalStandardsState, payload: IEducationalStandardsItem[]) {
    state.educationalStandardFilters = payload;
  },
  ['SET_SELECTED_EDUCATION_STANDARDS_FILTERS'](state: ISearchEducationalStandardsState, payload: number[]) {
    state.selectedEducationalStandardFilters = payload;
  },
  ['RESET_SELECTED_EDUCATION_STANDARDS_FILTERS'](state: ISearchEducationalStandardsState) {
    state.selectedEducationalStandardFilters = [];
  }
};

const actions: ActionTree<ISearchEducationalStandardsState, RootState> = {
  parseEducationalStandards: ({commit}, parameters?: string): void => {
    const array = decodeNumberArrayParameter(parameters, []);
    const educationalStandard = array.slice(0, 3);
    const selectedEducationalStandard = array.slice(3);
    commit('SET_EDUCATION_STANDARDS', educationalStandard);
    commit('SET_SELECTED_EDUCATION_STANDARDS_FILTERS', selectedEducationalStandard);
  },
  parseSelectedAspect: ({commit}, parameters?: string): void => {
    commit('SET_SELECTED_ASPECT', decodeNumberArrayParameter(parameters, []));
  },
  setEducationalStandard({commit, dispatch, getters}) {
    const educationalStandard = getters.educationalStandard;
    const educationalStandardId = educationalStandard.slice(-1).pop();
    if (educationalStandardId) {
      return EducationalStandardsApiService
        .getEducationalStandardsPath(educationalStandardId)
        .then((data: IEducationalStandard[]) => {
          const schoolTypeId = educationalStandard[0] || null;
          const subjectId = educationalStandard[1] || null;
          const classroomId = educationalStandard[2] || null;
          const schoolType = schoolTypeId === null
            ? null
            : data.find((item: IEducationalStandard) => item.id === schoolTypeId);
          const subject = subjectId === null
            ? null
            : data.find((item: IEducationalStandard) => item.id === subjectId);
          const classroom = classroomId === null
            ? null
            : data.find((item: IEducationalStandard) => item.id === classroomId);
          commit('SET_SCHOOL_TYPE_FILTER', schoolType);
          commit('SET_SUBJECT_FILTER', subject);
          commit('SET_CLASSROOM_FILTER', classroom);
          return dispatch('setEducationalStandards');
        });
    } else {
      commit('SET_SCHOOL_TYPE_FILTER', null);
      commit('SET_SUBJECT_FILTER', null);
      commit('SET_CLASSROOM_FILTER', null);
      return dispatch('setEducationalStandards');
    }
  },
  setAspect({commit, getters, state}) {
    const aspectFilters = getters.getAspectData.map(
      (item: IFilterItem) => {
        const value = state.selectedAspect.length
          ? state.selectedAspect.includes(item.id)
          : false;
        return {...item, value};
      }
    );
    commit('SET_ASPECT', aspectFilters);
  },
  setSelectedAspect({commit, state}) {
    const idList = state.aspect
      .filter((item: IFilterData) => item.value)
      .map((item: IFilterData) => item.id);
    commit('SET_SELECTED_ASPECT', idList);
  },
  async getSchoolTypeFilterList({commit}) {
    commit('SET_SCHOOL_TYPE_FILTER_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList()
      .then((data: IEducationalStandard[]) => {
        commit('SET_SCHOOL_TYPE_FILTER_LIST', data);
        commit('SET_SCHOOL_TYPE_FILTER_LOADING', false);
      })
      .catch(() => {
        commit('SET_SCHOOL_TYPE_FILTER_LIST', []);
        commit('SET_SCHOOL_TYPE_FILTER_LOADING', false);
      });
  },
  setSchoolTypeFilter({commit, dispatch}, payload: null | IEducationalStandard) {
    commit('SET_SCHOOL_TYPE_FILTER', payload);
    commit('SET_SUBJECT_FILTER_LIST', []);
    commit('RESET_SELECTED_EDUCATION_STANDARDS_FILTERS', []);
    dispatch('setSubjectFilter', null);
    dispatch('updateEducationalStandardsFilters');
  },
  async getSubjectFilterList({commit, dispatch, state}) {
    commit('SET_SUBJECT_FILTER_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList(state.schoolType!.id)
      .then((data: IEducationalStandard[]) => {
        commit('SET_SUBJECT_FILTER_LIST', data);
        dispatch('setClassroomFilter', null);
        commit('SET_SUBJECT_FILTER_LOADING', false);
      })
      .catch(() => {
        commit('SET_SUBJECT_FILTER_LIST', []);
        dispatch('setClassroomFilter', null);
        commit('SET_SUBJECT_FILTER_LOADING', false);
      });
  },
  setSubjectFilter({commit, dispatch}, payload: null | IEducationalStandard) {
    commit('SET_SUBJECT_FILTER', payload);
    commit('SET_CLASSROOM_FILTER_LIST', []);
    commit('RESET_SELECTED_EDUCATION_STANDARDS_FILTERS', []);
    dispatch('setClassroomFilter', null);
    dispatch('updateEducationalStandardsFilters');
  },
  async getClassroomFilterList({commit, state}) {
    commit('SET_CLASSROOM_FILTER_LOADING', true);
    EducationalStandardsApiService.getEducationalStandardsList(state.subject!.id)
      .then((data: IEducationalStandard[]) => {
        const sortedData = data.sort((a: IEducationalStandard, b: IEducationalStandard) => {
          const sort = (item: IEducationalStandard) => +item.name.split(' ')[0] || 0;
          return sort(a) - sort(b);
        });
        commit('SET_CLASSROOM_FILTER_LIST', sortedData);
        commit('SET_CLASSROOM_FILTER_LOADING', false);
      })
      .catch(() => {
        commit('SET_CLASSROOM_FILTER_LIST', []);
        commit('SET_CLASSROOM_FILTER_LOADING', false);
      });
  },
  setClassroomFilter({commit, dispatch}, payload: null | IEducationalStandard) {
    commit('SET_CLASSROOM_FILTER', payload);
    commit('RESET_SELECTED_EDUCATION_STANDARDS_FILTERS', []);
    dispatch('updateEducationalStandardsFilters');
    dispatch('getEducationalStandards');
  },
  updateEducationalStandardsFilters({commit, getters}) {
    commit('SET_EDUCATION_STANDARDS', getters.getEducationalStandardFilters);
  },
  setEducationalStandards({commit, getters}) {
    commit('SET_EDUCATION_STANDARDS_FILTERS', getters.getEducationalStandardsData);
    commit('SET_APPLIED_FILTER', getters.getAppliedFilter);
  },
  getEducationalStandards({commit, getters}) {
    const searchQueryParameters = getters.getSearchParameters;
    return SearchApiService.search(searchQueryParameters)
      .then((response: ISearchResponse<IMedia>) => {
        const data = response.filters.educationStandardList;
        commit('SET_EDUCATION_STANDARDS_FILTERS', data);
      })
      .catch(() => {
        commit('SET_EDUCATION_STANDARDS_FILTERS', []);
      });
  },
  setSelectedEducationalStandards({commit, state}, payload: {level: number, data: (null | number)[]}) {
    const {level, data} = payload;
    const index = level - 5;
    const value = data.filter((item: null | number) => item !== null);
    commit('SET_SELECTED_EDUCATION_STANDARDS_FILTERS', [...state.selectedEducationalStandardFilters.slice(0, index), ...value]);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
