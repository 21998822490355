































import {Vue, Component, Prop} from 'vue-property-decorator';
import {debounce} from '@/utilities';
import SearchApiService, {ISuggest} from '@/services/api/search.service';
import clickOutside from '@/directives/click-outside';
import SearchIcon from '@/assets/icon/search.svg';
import CloseIcon from '@/assets/icon/input-close.svg';
import BackIcon from '@/assets/icon/back.svg';

import { createHelpers } from 'vuex-map-fields';
import {InputActionData, InputActionType} from '@/interfaces/interfaces';
const { mapFields } = createHelpers({
  getterType: 'getSearchField',
  mutationType: 'updateSearchField',
});

@Component({
  computed: {
    ...mapFields([
      'isSearchOpened',
      'search',
      'allowCloseSearch'
    ])
  },
  directives: {
    clickOutside
  },
  components: {
    BackIcon
  }
})
export default class Search extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private closeDisabled!: boolean;

  private SearchIcon = SearchIcon;
  private isSearchOpened!: boolean;
  private allowCloseSearch!: boolean;
  private search!: string;
  private isSearchBlocked: boolean = false;

  public loading: boolean = false;
  public suggestList: ISuggest[] = [];
  public suggestListDisabled: boolean = true;

  public get classObject() {
    return {
      'opened': !this.allowCloseSearch && (this.closeDisabled && !this.$store.getters.isMobileAdaptiveMode) || this.isSearchOpened,
      'anonymous': this.$store.getters.getUserField('isAnonymousUser')
    };
  }

  public get actionList(): InputActionData[] {
    return [
      InputActionType.clear,
      ...(
        this.closeDisabled || this.$store.getters.isMobileAdaptiveMode
          ? []
          : [{
            icon: CloseIcon,
            title: 'Schließen',
            action: this.closeSearch.bind(this)
          }]
      ),
      {
        position: 'right',
        icon: SearchIcon,
        className: 'search',
        title: 'Suchen',
        action: this.onSearch.bind(this)
      }
    ];
  }

  private request(query: string): void {
    this.loading = true;
    SearchApiService.suggest(query)
      .then((data: ISuggest[]) => {
        this.suggestList = data;
        this.loading = false;
      })
      .catch((error) => {
        if (error.message && error.message === 'aborted') {
          return;
        }
        console.error(error);
      });
  }

  public asyncSearch = debounce((query: string) => this.request(query), 300);

  public onChange(value: string) {
    if (!this.suggestListDisabled) { // turn off suggestList
      if (value.length > 2) {
        this.asyncSearch(value);
      } else {
        this.suggestList = [];
      }
    }
  }

  private setSearch(value: string): void {
    this.search = value;
    this.isSearchBlocked = true;
    this.onSearch();
  }

  private onSearch(): void {
    this.suggestList = [];
    this.$store.dispatch('onChangeSearch');
  }

  public openSearch() {
    if (!this.isSearchOpened) {
      this.$store.commit('SET_IS_SEARCH_OPENED', true);
      this.focusOnInput();
      this.allowCloseSearch = false;
    }
  }

  public focusOnInput() {
    this.$nextTick(() => {
      const searchRef = this.$refs.search as any;
      searchRef.$refs.input.focus();
    });
  }

  public closeSearch() {
    this.suggestList = [];
    if (this.isSearchBlocked) {
      this.isSearchBlocked = false;
      return;
    }
    if (this.isSearchOpened) {
      this.$store.commit('SET_IS_SEARCH_OPENED', false);
    }
  }
}
