















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import CopyToClipBoard from '@/components/UI/CopyToClipBoard.vue';
import LinkIcon from '@/assets/icon/ico-link.svg';

@Component({
  components: {
    LinkIcon,
    CopyToClipBoard,
  }
})
export default class InputLink extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public url!: string;

  public onCopySuccess() {
    this.$notify({
      group: 'app',
      type: 'success',
      title: 'Link kopiert',
      text: 'Dieser Link steht für Sie zum Einfügen bereit.'
    });
  }
}
