
import store from '@/store';
import {StorageService} from '@/services/data/storage.service';

export interface IMediaCenterService {
  getMediaCenter: () => null | number;
  setMediaCenter: (value: null | number) => void;
}

const getMediaCenter = (): null | number => {
  const userId = store.getters.getUserId;
  const cookieMediaCenter = StorageService.get<string>(`mediaCenter-${userId}`);
  return cookieMediaCenter === null || cookieMediaCenter === 'null' || typeof cookieMediaCenter === 'undefined' ? null : +cookieMediaCenter;
};
const setMediaCenter = (value: null | number): void => {
  const userId = store.getters.getUserId;
  value === null
    ? StorageService.remove(`mediaCenter-${userId}`)
    : StorageService.set(`mediaCenter-${userId}`, value);
};

const MediaCenterService: IMediaCenterService = {
  getMediaCenter,
  setMediaCenter
};

export default MediaCenterService;
