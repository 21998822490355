import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import {getField, updateField} from 'vuex-map-fields';
import {IResetPassword} from '@/services/api/authorization.service';

const initialState = (): IResetPassword => ({
  email: ''
});
const state: IResetPassword = initialState();

const getters = {
  getUserEmail(state: IResetPassword) {
    return getField(state);
  }
};

const mutations = {
  ['RESET_CHANGE_PASSWORD_FORM'](state: IResetPassword) {
    Object.assign(state, initialState());
  },
  resetPassword(state:IResetPassword, field: string){
    return updateField(state,field);
  }
};

const actions: ActionTree<IResetPassword, RootState> = {};

export default {
  state,
  getters,
  mutations,
  actions
};
