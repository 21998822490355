import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export interface IMediaCenter {
  id: number;
  path: string[];
  name: string;
}

export interface IRentalUrlResponse {
  params: string;
}

export interface IMediaCenterApiService {
  getMediaCenterList: () => Promise<IMediaCenter[]>
  getRentalUrlParameters: () => Promise<IRentalUrlResponse>
}

const getMediaCenterList = (): Promise<IMediaCenter[]> => api
  .get('/mediazenters')
  .then((response: AxiosResponse<IMediaCenter[]>) => response.data)
  .catch((error) => Promise.reject(error));

const getRentalUrlParameters = (): Promise<IRentalUrlResponse> => api
  .get('/antares')
  .then((response: AxiosResponse<IRentalUrlResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const MediaCenterApiService: IMediaCenterApiService = {
  getMediaCenterList,
  getRentalUrlParameters
};

export default MediaCenterApiService;
