import Vue from 'vue';
import {VNode} from 'vue/types/vnode';
import router from './router';
import store from './store';

// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';

import App from './App.vue';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(() => {
    console.warn('[ServiceWorker]: Registration successful');
  }, (err) => {
    console.warn('[ServiceWorker]: Registration failed');
    console.warn(err);
  });
}

import 'leaflet/dist/leaflet.css';
import 'swiper/css/swiper.css';

import Vuelidate from 'vuelidate';
// @ts-ignore
import Notifications from 'vue-notification';
// @ts-ignore
import VTooltip from 'v-tooltip';
// @ts-ignore
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import Vuebar from 'vuebar';

import {VueHammer} from 'vue2-hammer';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
// @ts-ignore
import { Fragment } from 'vue-fragment';
import LoadingIndicator from '@/components/UI/LoadingIndicator.vue';
import Loading from '@/components/UI/Loading.vue';
import Modal from '@/components/UI/Modal.vue';
import Lightbox from '@/components/UI/Lightbox.vue';
import Notification from '@/components/UI/Notification.vue';
// @ts-ignore
import VueMultiselect from 'vue-multiselect';
import MultiSelect from '@/components/UI/MultiSelect.vue';
import Button from '@/components/UI/Button.vue';
import IconButton from '@/components/UI/IconButton.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import Input from '@/components/UI/Input/Input.vue';
import InputError from '@/components/UI/Input/InputError.vue';
import Checkbox from '@/components/UI/Checkbox.vue';
import RadioButton from './components/UI/RadioButton.vue';
import Tabs from '@/components/UI/Tabs/Tabs.vue';
import Tab from '@/components/UI/Tabs/Tab.vue';
import ShowMore from '@/components/UI/ShowMore/ShowMore.vue';

import CloseIcon from '@/assets/icon/input-close.svg';
import DeleteIcon from '@/assets/icon/ico-delete.svg';
import DotsIcon from '@/assets/icon/comp-dots-horizontal.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import EditIcon from '@/assets/icon/edit.svg';
import MoveIcon from '@/assets/icon/verschieben.svg';
import NoticeIconSuccess from '@/assets/icon/round-check-done.svg';
import NoticeIconWarning from '@/assets/icon/round-check-warn.svg';
import RenameIcon from '@/assets/icon/ico-rename.svg';
import SearchIcon from '@/assets/icon/search.svg';
import SearchIconSm from '@/assets/icon/ico-search.svg';
import ShareIcon from '@/assets/icon/ico-freigabe.svg';
import SortIcon from '@/assets/icon/sort.svg';
import SortReverseIcon from '@/assets/icon/sort-reverse.svg';
import MediaCollectionIcon from '@/assets/icon/media-collection.svg';
import RecommendedIcon from '@/assets/icon/recommended.svg';
import OerIcon from '@/assets/icon/ico-oer.svg';
import ApprovedIcon from '@/assets/icon/ico-approved.svg';
import EyeIcon from '@/assets/icon/ico-eye.svg';
import BackIcon from '@/assets/icon/back.svg';

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VTooltip);
Vue.use(VueHammer);
Vue.use(VueClipboard);
Vue.use(Vuebar);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('LoadingIndicator', LoadingIndicator);
Vue.component('Loading', Loading);
Vue.component('Modal', Modal);
Vue.component('Lightbox', Lightbox);
Vue.component('Fragment', Fragment);
Vue.component('Notification', Notification);
Vue.component('VueMultiSelect', VueMultiselect);
Vue.component('MultiSelect', MultiSelect);
Vue.component('LinkButton', LinkButton);
Vue.component('Button', Button);
Vue.component('LinkButton', LinkButton);
Vue.component('IconButton', IconButton);
Vue.component('Input', Input);
Vue.component('InputError', InputError);
Vue.component('Checkbox', Checkbox);
Vue.component('RadioButton', RadioButton);
Vue.component('Tabs', Tabs);
Vue.component('Tab', Tab);
Vue.component('ShowMore', ShowMore);

Vue.component('CloseIcon', CloseIcon);
Vue.component('DeleteIcon', DeleteIcon);
Vue.component('DotsIcon', DotsIcon);
Vue.component('DownloadIcon', DownloadIcon);
Vue.component('PlusIcon', PlusIcon);
Vue.component('EditIcon', EditIcon);
Vue.component('MoveIcon', MoveIcon);
Vue.component('NoticeIconSuccess', NoticeIconSuccess);
Vue.component('NoticeIconWarn', NoticeIconWarning);
Vue.component('RenameIcon', RenameIcon);
Vue.component('SearchIcon', SearchIcon);
Vue.component('SearchIconSm', SearchIconSm);
Vue.component('ShareIcon', ShareIcon);
Vue.component('SortIcon', SortIcon);
Vue.component('SortReverseIcon', SortReverseIcon);
Vue.component('MediaCollectionIcon', MediaCollectionIcon);
Vue.component('RecommendedIcon', RecommendedIcon);
Vue.component('OerIcon', OerIcon);
Vue.component('ApprovedIcon', ApprovedIcon);
Vue.component('EyeIcon', EyeIcon);
Vue.component('BackIcon', BackIcon);

// if (process.env.NODE_ENV === 'production') {
//   const environment = window.location.hostname.split('.').shift();
//   Sentry.init({
//     release: `sesam_frontend@${process.env.npm_package_version}`,
//     dsn: 'https://a2ae8922c8ec43bcb89086bf7b53b7c9@o410785.ingest.sentry.io/5285711',
//     integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
//     environment
//   });
// }

const render = (createElement: typeof Vue.prototype.$createElement): VNode => createElement(App);
new Vue({
  router,
  store,
  render
}).$mount('#app');
