
export const decodeNumberArrayParameter = <T>(value: undefined | string, fallback: T): T | number[] => {
  if (typeof value === 'undefined') {
    return fallback;
  }
  return value
    .split(',')
    .map((stringItem: string) => +stringItem)
    .filter((numericItem: number) => !isNaN(numericItem));
};
