
























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})

export default class RegisterSuccess extends Vue {
  public created() {
    this.$store.dispatch('setIsLicenceOpened', true);
  }

  public close(): void {
    this.$emit('closeModal');
  }
}
