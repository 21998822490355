




































import { Vue, Component } from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import { IUser } from '@/services/api/user.service';
import { IRentalUrlResponse } from '@/services/api/media-center.service';

const {mapFields: mapUserFields} = createHelpers({
  getterType: 'getUserField',
  mutationType: 'updateUserField'
});

const {mapFields: mapMediaCenterFields} = createHelpers({
  getterType: 'getMediaCenterField',
  mutationType: 'updateMediaCenterField'
});

@Component({
  computed: {
    ...mapUserFields(['user']),
    ...mapMediaCenterFields(['rentalUrlParameters'])
  }
})

export default class ProfileForm extends Vue {
  public user!: IUser;
  public rentalUrlParameters!: IRentalUrlResponse;
  public rentalLink: string = 'https://mzantares-bw.datenbank-bildungsmedien.net/easy?';

  public onSubmit(): void {
    this.$store.dispatch('updateProfile')
      .then(() => this.$emit('closeModal'));
  }

  public openRentalLink(event: Event) {
    const targerEvent = event;
    targerEvent.preventDefault();
    this.$store.dispatch('getRentalUrlParameters')
      .then(() => {
        this.$emit('openModal', 'rentalFrame', this.rentalLink + this.rentalUrlParameters);
      });

  }
}
