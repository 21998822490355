













































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IMedia} from '@/services/api/media.service';
import Chevron from '@/components/UI/Icons/Chevron.vue';

import EmbedIcon from '@/assets/icon/embed.svg';

@Component({
  components: {
    Chevron
  }
})
export default class AccordionVideo extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private item!: IMedia;

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  private activeAccordionId!: number;

  private EmbedIcon = EmbedIcon;
  private Chevron = Chevron;

  private toggle() {
    this.$emit('onToggle', this.item);
  }

  private setDetails(): void {
    this.$emit('closeModal');
    const id = this.item.id.toString();
    this.$router.push({
      name: 'details',
      params: { id }
    });
  }

  private get accordionClass(): string {
    return this.activeAccordionId === this.item.id || this.$store.getters.isMobileAdaptiveMode ? 'is-expanded' : 'as-default';
  }

  private get arrowClass(): string {
    return this.activeAccordionId === this.item.id || this.$store.getters.isMobileAdaptiveMode ? 'up' : 'down';
  }
}
