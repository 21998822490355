import { Vue, Component } from 'vue-property-decorator';

@Component({})

export default class AdaptiveButtonSize extends Vue {
  private get buttonSize() {
    return this.$store.getters.isMobileAdaptiveMode
      ? 'sm'
      : 'lg';
  }
}