import {ActionTree, GetterTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import {decodeBooleanParameter} from '@/utilities';
import {defaultValueDictionary} from '@/store/modules/search.store';

export interface ISearchFiltersState {
  recommended: boolean;
  oer: boolean;
}

const initialState = (): ISearchFiltersState => ({
  recommended: defaultValueDictionary.recommended,
  oer: defaultValueDictionary.oer
});

const state = initialState();

const getters: GetterTree<ISearchFiltersState, RootState> = {
  getSearchFiltersField: (state: ISearchFiltersState) => getField(state),

  oer: (state: ISearchFiltersState) => state.oer,
  recommended: (state: ISearchFiltersState) => state.recommended,
};

const mutations = {
  updateSearchFiltersField(state: ISearchFiltersState, field: string) {
    return updateField(state, field);
  },
  ['SET_RECOMMENDED'](state: ISearchFiltersState, payload: boolean) {
    state.recommended = payload;
  },
  ['SET_OER'](state: ISearchFiltersState, payload: boolean) {
    state.oer = payload;
  },
};

const actions: ActionTree<ISearchFiltersState, RootState> = {
  parseRecommended: ({commit}, parameters?: string): void => {
    commit('SET_RECOMMENDED', decodeBooleanParameter(parameters, defaultValueDictionary.recommended));
  },
  parseOer: ({commit}, parameters?: string): void => {
    commit('SET_OER', decodeBooleanParameter(parameters, defaultValueDictionary.oer));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
