
































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

const { mapFields } = createHelpers({
  getterType: 'getUiField',
  mutationType: 'updateUiField',
});

@Component({
  computed: {...mapFields([
      'isLtiDisclamer'
    ]),
  },
  mixins: [adaptiveButtonSize]
})

export default class LtiDisclamer extends Vue {
  private isLtiDisclamer!: boolean;

  public mounted() {
    if (this.$route.query.sid 
      && this.$route.query.sid 
      !== localStorage.getItem('LTI_LEGAL_ID')) {
      this.isLtiDisclamer = true;
    }
  }

  public closeModal() {
    this.isLtiDisclamer = false;
  }

  public deny() {
    this.isLtiDisclamer = false;
  }

  public accept() {
    localStorage.setItem('LTI_LEGAL_ID', String(this.$route.query.sid));
    this.isLtiDisclamer = false;
  }
}
