import Vue, {CreateElement, RenderContext, VNode} from 'vue';

type VnodeProps = {
  vNode: VNode;
}

const Vnode = Vue.extend(
  {
    functional: true,
    render: (createElement: CreateElement, ctx: RenderContext<Record<keyof VnodeProps, VNode>>) => {
      ctx.props.vNode.key = ctx.data.key;
      return ctx.props.vNode;
    }
  }
);
export default Vnode;
