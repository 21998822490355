










import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {ISuggest} from '@/services/api/search.service';

@Component({})
export default class InputSuggest extends Vue {

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  public suggestList!: ISuggest[];
  
}
