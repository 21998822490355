import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {scrollTo} from '@/utilities';

import productionRoutes from '@/router/routes/routes.production';
import developmentRoutes from '@/router/routes/routes.development';
import globalGuards from './guards';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (from, to) => {
    if (store.getters.modalVisibility || to.hash.startsWith('#modal') || from.hash.startsWith('#modal')) return;
    from.path === to.path
      ? scrollTo('.app-container', 'smooth')
      : scrollTo('.app-container');
  },
  routes: [...productionRoutes, ...developmentRoutes],
});

router.beforeEach((to, from, next) => globalGuards(to, from, next));

export default router;
