import {ActionTree} from 'vuex';
import {getField, GetterTree, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import {IMedia, IMediaStandard, MediaType, MediaTypes, MediaExtensions} from '@/services/api/media.service';
import MediaApiService from '@/services/api/media.service';
import MediaShareApiService from '@/services/api/media-share.service';
import router from '@/router';

export interface IMediaState {
  mediaData?: IMedia | null;
  sharedMedia: IMedia | null;
  mediaSharedToken: string | null;
  mediaSharedDateTo: string | null;
  mediaSharedPath: string | null;
  mediaAssetToken: string | null;
  mediaAssetDateTo: string | null;
  mediaAssetPath: string | null;
}
const initialState = (): IMediaState => ({
  mediaData: null,
  sharedMedia: null,
  mediaSharedDateTo: null,
  mediaSharedToken: null,
  mediaSharedPath: null,
  mediaAssetToken: null,
  mediaAssetDateTo: null,
  mediaAssetPath: null
});
const state = initialState();
const getters: GetterTree<IMediaState, RootState> = {
  getMediaType: (state: IMediaState) => state.mediaData ? state.mediaData.mediaType : null,
  getNestedMediaListByType: (state: IMediaState) => (type: MediaType) => state.mediaData
    ? state.mediaData.nestedObjects
      .filter((item: IMedia) => item.mediaType === type)
    : [],
  getMediaField: (state: IMediaState) => getField(state),
  getMediaDidacticNotes: (state: IMediaState) => state.mediaData ? state.mediaData.didacticNotes : [],
  getMediaStandards: (state: IMediaState) => {
    if (!state.mediaData) return [];
    if (!state.mediaData.standardList) return [];
    const nest = (items: IMediaStandard[], id: number | null = null): IMediaStandard[] =>
      items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id) }));
    return nest(state.mediaData.standardList);
  },
  getMediaFurtherInfo: (state: IMediaState) => state.mediaData ? state.mediaData.furtherInformation : [],
  getMediaProductionInfo: (state: IMediaState) => state.mediaData ? state.mediaData.productionInformation : [],
  getMediaDownloadLink: () => (media: IMedia): string => {
    return media.dataInfo 
      ? media.dataInfo.versions.original
        ? media.dataInfo.versions.original.downloadUrl 
        : media.dataInfo.versions.preview.downloadUrl 
      : '';
  },
  isMediaDownloadAvailable: () => (media: IMedia): boolean => {
    if (media.externalObject) return false;
    if (!media.download) return false;
    if (!media.dataInfo) return false;
    return true;
  },
  canMediaView: (_state, _getters, _rootState, rootGetters) => (media: IMedia): boolean => {
    if (rootGetters.isSelfRegisteredUser && media.externalObject) return false;
    if (!media.streaming) return false;
    if (media.mediaType === MediaTypes.WEBSEITE) return true;
    if (media.mediaType === MediaTypes.MEDIENSAMMLUNG && media.url) return true;
    if (!media.dataInfo || !media.dataInfo.versions) return false;
    if (media.dataInfo.versions.preview 
      || media.dataInfo.versions.directory && media.dataInfo.extension === MediaExtensions.webdvdzip) return true;
    if (rootGetters.getMediaEmbedType(media) && media.dataInfo.versions.original) return true;
    return false;
  },
  canMediaEmbed: () => (item: IMedia): boolean => {
    if (!item.dataInfo) return false;
    switch (item.dataInfo.extension) {
      case MediaExtensions.mpg:
      case MediaExtensions.mov:
      case MediaExtensions.wmv:
      case MediaExtensions.mp4:
      case MediaExtensions.mp3:
      case MediaExtensions.ogg:
      case MediaExtensions.wav:
      case MediaExtensions.flv:
        return true;
      default: 
        return false;
    }
  },
  getMediaEmbedType: () => (item: IMedia): string | null => {
    switch (item.dataInfo.extension) {
      case MediaExtensions.mpg:
      case MediaExtensions.mov:
      case MediaExtensions.wmv:
      case MediaExtensions.mp4:
      case MediaExtensions.mp3:
      case MediaExtensions.ogg:
      case MediaExtensions.wav:
      case MediaExtensions.flv:
        return 'video';
      case MediaExtensions.webdvdzip:
        return 'iframe';
      default: 
        return null;
    }
  },
  getMediaListDownloadLink: () => (idList: number[]): string => {
    return `/api/download/zip?id=${idList}`;
  },
  isMediaDocumentViewAvailable: () => (item: IMedia): boolean => {
    switch (item.dataInfo.extension) {
      case MediaExtensions.pdf:
      case MediaExtensions.doc:
      case MediaExtensions.docx:
        return true;
      default: 
        return false;
    }
  }
};
const mutations = {
  ['SET_MEDIA_DATA'](state: IMediaState, payload: IMedia) {
    state.mediaData = payload;
  },
  ['SET_SHARED_MEDIA'](state: IMediaState, payload: IMedia) {
    state.sharedMedia = payload;
  },
  ['SET_MEDIA_SHARED_TOKEN'](state: IMediaState, payload: string) {
    state.mediaSharedToken = payload;
  },
  ['SET_MEDIA_SHARED_DATE_TO'](state: IMediaState, payload: string) {
    state.mediaSharedDateTo = payload;
  },
  ['SET_MEDIA_SHARED_PATH'](state: IMediaState, payload: string) {
    state.mediaSharedPath = payload;
  },
  ['SET_MEDIA_ASSET_TOKEN'](state: IMediaState, payload: string) {
    state.mediaAssetToken = payload;
  },
  ['SET_MEDIA_ASSET_DATE_TO'](state: IMediaState, payload: string) {
    state.mediaAssetDateTo = payload;
  },
  ['SET_MEDIA_ASSET_PATH'](state: IMediaState, payload: string) {
    state.mediaAssetPath = payload;
  },
  
  updateMediaField(state: IMediaState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IMediaState, RootState> = {
  async getMediaData({commit}, params: {id: number}) {
    commit('SET_LOADING', {details: true});
    commit('SET_MEDIA_DATA', null);
    return MediaApiService.getMediaData(params.id)
      .then((data) => {
        // HACK: Fix media direct url authentication for LTI on Safari
        if (router.currentRoute.query.sid) {
          const streamUrl = `/api/download/stream/${data.id}/cid/${router.currentRoute.query.sid}`;
          if (data.dataInfo 
            && data.dataInfo.versions.original 
            && data.dataInfo.versions.original.url
            && data.dataInfo.extension !== MediaExtensions.docx
          ) {
            data.dataInfo.versions.original.url = streamUrl;
          }
        }

        commit('SET_MEDIA_DATA', data);
        commit('SET_LOADING', {details: false});
      })
      .catch(() => {
        commit('SET_LOADING', {details: false});
      });
  },
  async embedMedia({commit}, id: number) {
    commit('SET_LOADING', {lti: true});
    return MediaApiService.embedMedia(id)
      .then(() => {
        commit('SET_LOADING', {lti: false});
      })
      .catch(() => {
        commit('SET_LOADING', {lti: false});
      });
  },
  async openExternalMediaLink({commit}, id: number) {
    const windowReference = window.open('portal:blank', '_blank')!;
    commit('SET_LOADING', {details: true});
    return MediaApiService.openExternalMediaLink(id)
      .then((data) => {
        commit('SET_LOADING', {details: false});
        windowReference.location.href = data.url;
      })
      .catch(() => {
        commit('SET_LOADING', {details: false});
      });
  },
  async getRentalMediaLink({commit}, id: number) {
    commit('SET_LOADING', {details: true});
    return MediaApiService.getRentalMediaLink(id)
      .then((data) => {
        commit('SET_LOADING', {details: false});
        return data;
      })
      .catch(() => {
        commit('SET_LOADING', {details: false});
      });
  },
  async createSharedMedia({commit}, id: number) {
    commit('SET_LOADING', {details: true});
    return MediaShareApiService.createSharedMedia(id)
      .then((data) => {
        commit('SET_MEDIA_SHARED_TOKEN', data.token);
        commit('SET_MEDIA_SHARED_DATE_TO', data.endDate);
        commit('SET_MEDIA_SHARED_PATH', 'public');
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', {details: false});
      });
  },
  async createAssetLink({commit}, id: number) {
    commit('SET_LOADING', {details: true});
    return MediaShareApiService.createAssetLink(id)
      .then((data) => {
        commit('SET_MEDIA_ASSET_TOKEN', data.token);
        commit('SET_MEDIA_ASSET_DATE_TO', data.availableTo);
        commit('SET_MEDIA_ASSET_PATH', 'api/asset/stream');
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', {details: false});
      });
  },
  async createEmbedMedia({commit, dispatch, getters}, media: IMedia) {
    commit('SET_LOADING', {shared: true});
    const promises = [];
    if (getters.canMediaEmbed(media)) promises.push(dispatch('createSharedMedia', media.id));
    if (media.assetLink) promises.push(dispatch('createAssetLink', media.id));
    return Promise.all(promises)
      .finally(() => {
        commit('SET_LOADING', {shared: false});
      });      
  },
  async getSharedMedia({commit}, id: number) {
    commit('SET_LOADING', {details: true});
    return MediaShareApiService.getSharedMedia(id)
      .then((data) => {
        commit('SET_SHARED_MEDIA', data);
        return data;
      })
      .finally(() => {
        commit('SET_LOADING', {details: false});
      });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
