

































import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {required, minLength} from 'vuelidate/lib/validators';
import { createHelpers } from 'vuex-map-fields';

import UserProfileIcon from '@/assets/icon/user-profile.svg';

const { mapFields } = createHelpers({
  getterType: 'getLoginFormField',
  mutationType: 'updateLoginFormField',
});

const validations = {
  login: {
    required
  },
  password: {
    required,
    minLength: minLength(8)
  }
};

@Component({
  validations,
  computed: {...mapFields([
      'login',
      'password',
      'submitSuccess'
    ])
  }
})
export default class LoginForm extends Vue {
  private submitSuccess!: boolean;
  public UserProfileIcon = UserProfileIcon;

  public loginErrorMessageList = {
    required: 'Anmelden ist erforderlich'
  };
  public passwordErrorMessageList = {
    required: 'Passwort wird benötigt',
    minLength: 'Das Passwort muss aus mindestens 8 Zeichen bestehen'
  };

  public openModal(modal: string) {
    this.$emit('openModal', modal);
  }

  public submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$store.dispatch('login');
    }
  }

  @Watch('submitSuccess')
  public onSubmitSuccess(success: boolean) {
    if (success) this.$emit('closeModal');
  } 
}
