import {DirectiveOptions} from 'vue';
import {DirectiveBinding} from 'vue/types/options';

const directive: DirectiveOptions = {
  inserted(element: HTMLElement, binding: DirectiveBinding): void {
    if (!binding.arg) return;
    const app = document.getElementById(binding.arg);
    if (!app) return;

    const f = (event: Event) => {
      if (binding.value(event, element)) {
        app.removeEventListener('scroll', f);
      }
    };
    app.addEventListener('scroll', f);
  }
};

export default directive;