












































import Vue from 'vue';
import Component from 'vue-class-component';
import {LinkList, IModal} from '@/interfaces/interfaces';

import Logo from '../Logo.vue';
import Links from '../LinkList.vue';
import FooterModals from '@/components/Layout/Footer/FooterModals.vue';
import MediaCenters from '@/components/Layout/Footer/MediaCenters.vue';

import LmzLogo from '@/assets/image/lmzLogo.svg';
import Envolope from '@/assets/icon/envelope.svg';
import Phone from '@/assets/icon/phone.svg';

@Component({
  components: {
    Logo,
    Links,
    LmzLogo,
    FooterModals,
    MediaCenters
  },
})

export default class Footer extends Vue {
  public modals: IModal[] = [];

  public mounted() {
    this.modals = (this.$refs.modals as FooterModals).modals;
  }

  private aboutLinks: LinkList = [
    {
      icon: Envolope,
      externalLink: 'mailto:sesam@lmz-bw.de',
      text: 'sesam@lmz-bw.de'
    },
    {
      icon: Phone,
      externalLink: 'tel:071149096358',
      text: '0711 49096358'
    },
    {
      id: 'forMediaProviders',
      link: '/',
      text: 'Für Medienanbieter',
      modal: true
    },
  ];

  private helpLinks: LinkList = [
    {
      externalLink: 'https://www.lmz-bw.de/angebote/sesam-mediathek/infos-und-hilfe-zur-sesam-mediathek/sesam-hilfe',
      text: 'Hilfe'
    },
    {
      externalLink: 'https://www.lmz-bw.de/angebote/sesam-mediathek/infos-und-hilfe-zur-sesam-mediathek/sesam-faqs',
      text: 'FAQs'
    },
    {
      externalLink: 'https://www.lmz-bw.de/angebote/sesam-mediathek/infos-und-hilfe-zur-sesam-mediathek/sesam-erklaerfilme',
      text: 'Erklärfilme'
    }
  ];
}
