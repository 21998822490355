








import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {PermissionType} from '@/services/api/user.service';

@Component({})
export default class Permission extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private type!: PermissionType;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private allow!: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'div'
  })
  private tag!: 'div' | 'li';

  private get isAllowed() {
    if (this.type === 'useSearch' && this.$router.currentRoute.query.sid) return true;
    return this.allow 
      ? this.$store.getters.permission(this.type)
      : !this.$store.getters.permission(this.type);
  }
}
