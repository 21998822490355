

























import { Vue, Component, Prop } from 'vue-property-decorator';
import EmbedIcon from '@/assets/icon/embed.svg';
import { IMedia } from '@/services/api/media.service';
import { set, get, del } from 'idb-keyval';

import screenfull from 'screenfull';

@Component
export default class Iframe extends Vue {
  public EmbedIcon = EmbedIcon;

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public data!: IMedia;

  @Prop({
    type: String,
    required: false
  })
  public height!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public preventDownload!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public hasEmbedButton!: boolean;

  public async mounted() {
    await del('IFRAME_HISTORY_BACK_MODE');
    this.$root.$emit('toggleLoadingDetailsModal', false);
    this.$root.$emit('navigateIframe', false);
    this.$root.$on('openModalFullscreen', this.fullscreen);
    this.$root.$emit('toggleLoadingDetailsModal', true);
    this.$root.$on('initIframeHistoryBack', this.initIframeHistoryBack);
  }

  public async beforeDestroy() {
    this.$root.$off('initIframeHistoryBack', this.initIframeHistoryBack);
    this.$root.$emit('toggleLoadingDetailsModal', false);
  }

  public async onLoad(event: Event) {
    const iframe = event.target as HTMLIFrameElement;
    const isHistoryBackMode = await get('IFRAME_HISTORY_BACK_MODE');
    try {
      this.inject(iframe);
    } catch (error) {
      console.warn(`Blocked a frame with origin ${window.location.origin} from accessing a cross-origin frame`);
    }
    if (!isHistoryBackMode) {
      this.$root.$emit('toggleLoadingDetailsModal', false);
    }
  }

  public get src(): string {
    // Use this URL only for testing iframe on local dev server!
    // return '/resource/menu.html';
    return this.$attrs.url || this.$attrs.rentalUrl || '';
  }

  public inject(iframe: HTMLIFrameElement) {
    if (!iframe || !iframe.contentWindow || !iframe.contentWindow.document) return;

    const document = iframe.contentWindow.document;

    const styleSheet = document.createElement('style');
    const styles = `
      body {
        scrollbar-color: #1b5be9 #f7f7f7;
        scrollbar-width: thin;
      }
      body::-webkit-scrollbar {
        width: 9px;
        height: 9px;
      }
      body::-webkit-scrollbar-track {
        background-color: #f7f7f7;
      }
      body::-webkit-scrollbar-thumb {
        background-color: #1b5be9;
      }
    `;
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    if (!this.preventDownload) { return; }

    const script = document.createElement('script');
    script.innerHTML = `
      (function () {
        var videos = document.querySelectorAll('video') || null;
        if (!videos) return;
        videos.forEach(function(video) {
            video.setAttribute("oncontextmenu", "return false;");
            video.setAttribute("controlsList", "nodownload");
        });
      }());
      (function () {
        var blanklinks = document.querySelectorAll('a[target="_blank"]') || null;
        if (!blanklinks) return;
        function isSelfUrl(url) {
          return !!url.match(/sesam.lmz-bw.de/g)
            ? true
            : !RegExp('^(https?:)?//').test(url);
        };
        function isVideoExtension(url) {
          var extension = url.split(/[#?]/)[0].split('.').pop().trim();
          switch (extension) {
            case 'mp3':
              return true;
            case 'mp4':
              return true;
            case 'mov':
              return true;
            default:
              return false;
          }
        };
        blanklinks.forEach(function(blanklink) {
          var url = blanklink.getAttribute('href');
          if (isSelfUrl(url) && isVideoExtension(url)) blanklink.setAttribute("target", "_self");
        });
      }());
    `;
    document.body.appendChild(script);
  }

  public fullscreen() {
    const element = (document.getElementById('modalIframe') as HTMLElement);
    if (screenfull.isEnabled) {
      screenfull.request(element)
        .catch(() => console.error);
    }
  }

  public get wrapperHeight() {
    return this.hasEmbedButton 
      && this.data 
      && this.data.streaming 
      && !this.$store.getters.permission('student')
        ? 'calc(100% - 56px)' 
        : 'calc(100% - 5px)';
  }

  // Iframe history back loop for closing modal window

  public async initIframeHistoryBack() {
    this.$root.$emit('toggleLoadingDetailsModal', true);
    await set('IFRAME_HISTORY_BACK_MODE', true);
     let counter: number = 0;

    const interval = setInterval(async () => {
      const isHistoryBackMode = await get('IFRAME_HISTORY_BACK_MODE');
      if (isHistoryBackMode && counter < 100) {
        counter++;
        console.warn(`[IFRAME HISTORY] Force navigation back (${counter})`);
        return await new Promise((resolve) => {
          history.back();
          resolve(true);
        });
      }
      else {
        clearInterval(interval);
        if (this.$route.hash.includes('#modal')) {
          this.$router.replace({ hash: undefined });
        }
        console.warn('[IFRAME HISTORY] Closing iframe...');
        this.$root.$emit('closeDetailsModal', false);
      }
    }, 100);
  }
}
