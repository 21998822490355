









































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {VNode} from 'vue/types/umd';
import VnodeList from './VnodeList';

@Component({
  components: {Vnodes: VnodeList}
})
export default class ShowMore extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  public limit!: number;

  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  public step!: number;

  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  public page!: number;

  @Prop({
    type: String,
    required: false,
    default: 'none'
  })
  public animation!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public canExpand!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public canNext!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public canPrev!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public canCollapse!: boolean;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public wrapperClass!: string;

  private isDisabled: boolean = true;
  private isCollapsable: boolean = true;
  private data: VNode[] = [];
  private content: VNode[] = [];
  private total: number = 0;
  private stepSize: number = this.step;
  private stepCount: number = 0;
  private currentStep: number = this.page;

  private created(): void {
    this.updateState();
    this.updateContent();
  }

  private get isCanCollapse(): boolean {
    return !this.isDisabled && !!this.currentStep;
  }

  private get isCanExpand(): boolean {
    return !this.isDisabled && this.stepCount > this.currentStep;
  }

  private updateState(): void {
    this.data = this.$slots.default
      ? this.$slots.default
          .filter(vnode => vnode.data)
      : [];
    this.total = this.data.length;
    this.isDisabled = this.total <= this.limit;
    this.stepSize = this.step || this.total - this.limit;
    this.stepCount = Math.ceil((this.total - this.limit) / this.stepSize);
    if (this.currentStep > this.stepCount) {
      this.currentStep = this.stepCount;
    }
  }
  
  private expand(): void {
    this.currentStep = this.stepCount;
    this.updateContent();
  }

  private nextStep(): void {
    this.currentStep++;
    this.updateContent();
  }

  private previousStep(): void {
    this.currentStep--;
    this.updateContent();
  }

  private collapse(): void {
    this.currentStep = 0;
    this.updateContent();
  }

  private updateContent(): void {
    this.content = this.data.slice(0, this.limit + this.stepSize * this.currentStep);
  }
}
