













import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IDictionary} from '@/interfaces/interfaces';
import {ErrorCode} from '@/store/modules/ui/ui.store';

interface IErrorText {
  title: string;
  subtitle: string[];
  text: string;
}

@Component({})
export default class Error extends Vue {
  @Prop({
    required: true,
    type: Number,
    default: 500
  })
  public errorCode!: ErrorCode;

  @Prop({
    required: false,
    type: String,
    default: null
  })
  public errorMessage!: string | null;

  private get expiredLinkDate() {
    return this.errorMessage === 'error.user.insufficient_rights.base.collection';
  }

  private get dictionary(): IDictionary<IErrorText> {
    return {
      400: {
        title: this.expiredLinkDate
          ? 'Link abgelaufen'
          : 'Uppps.',
        subtitle: this.expiredLinkDate
          ? ['Der aufgerufene Link war zeitlich begrenzt und ist nicht mehr gültig', 'Über das Logo gelangen Sie zurück zur Startseite.']
          : ['Hier gibt’s leider nichts zu sehen.', 'Aber das ist nicht das Ende.'],
        text: 'Über das Logo gelangen Sie zurück zur Startseite.'
      },
      401: {
        title: 'Uppps.',
        subtitle: ['Hier gibt’s leider nichts zu sehen.', 'Aber das ist nicht das Ende.'],
        text: 'Einfach eine neue Suche starten und finden, wonach Sie suchen…'
      },
      404: {
        title: 'Uppps.',
        subtitle: ['Hier gibt’s leider nichts zu sehen.', 'Aber das ist nicht das Ende.'],
        text: 'Einfach eine neue Suche starten und finden, wonach Sie suchen…'
      },
      500: {
        title: 'Uppps.',
        subtitle: ['Über das Logo gelangen Sie zurück zur Startseite.', 'Wir arbeiten an der Lösung des Problems.'],
        text: 'Über das Logo gelangen Sie zurück zur Startseite.'
      }
    };
  }
  public get title(): string {
    return this.dictionary[this.errorCode].title;
  }
  public get subtitle(): string[] {
    return this.dictionary[this.errorCode].subtitle;
  }
  public get text(): string {
    return this.dictionary[this.errorCode].text;
  }

}
