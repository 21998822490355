export const sortObjKeys = (object: {}): {} => { // TODO use sorterFn(sortDirection)
  return (Object.keys(object) as (keyof object)[])
    .sort(
      (a: keyof object, b: keyof object) => a > b ? 1 : -1
    )
    .reduce(
      (result: {}, key: keyof object) => {
        result[key] = object[key];
        return result;
      },
      {}
    );
};
