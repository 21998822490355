


























import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {Watch} from 'vue-property-decorator';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

const { mapFields } = createHelpers({
  getterType: 'getUserField',
  mutationType: 'updateUserField',
});

@Component({
  computed: {...mapFields([
      'isRegistrationConfirmed'
    ]),
  },
  mixins: [adaptiveButtonSize]
})

export default class RegistrationConfirmModal extends Vue {
  private visible: boolean = false;
  private mail: string = '';
  private isRegistrationConfirmed!: boolean;

  @Watch('isRegistrationConfirmed')
  public onRegistrationConfirm(success: boolean) {
    if (success) {
      this.mail = this.$route.query.login as string;
      this.visible = true;
      this.$router.replace({query: {}});
    }
  }

  public closeModal(): void {
    this.visible = false;
    this.$store.commit('SET_REGISTRATION_CONFIRM_SUCCESS', false);
  }
}
