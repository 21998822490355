





import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {AdaptiveMode} from '@/store/modules/ui/ui.store';
import { debounce } from '@/utilities';

import DefaultLayout from '@/layouts/Default.layout.vue';
import FrameLayout from '@/layouts/Frame.layout.vue';
import ErrorBrowserNotValid from '@/pages/ErrorBrowserNotValid.vue';

import Bowser from 'bowser';
import { Watch } from 'vue-property-decorator';
import { del } from 'idb-keyval';

interface ILayout {
  name: string;
  component: VueConstructor;
}

@Component({
  components: {
    ErrorBrowserNotValid,
    DefaultLayout,
    FrameLayout
  }
})
export default class AppComponent extends Vue {
  private layouts: ILayout[] = [
    {
      name: 'default',
      component: DefaultLayout
    },
    {
      name: 'frame',
      component: FrameLayout
    }
  ]

  public get layout(): VueConstructor | null {
    return this.$route.meta.layout 
      ? this.layouts.filter(item => item.name === this.$route.meta.layout)[0].component 
      : null;
  }

  public get isBrowserValid() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isNotValidBrowser = browser.satisfies({
      edge: '<79',
      ie: '>=1'
    });
    return !isNotValidBrowser;
  }

  private resizeDebounceTimeout = 25;
  public created() {
    this.vhUnitsFix();
    this.resizeHandler();
  }
  public mounted() {
    window.addEventListener(
      'resize',
      debounce(this.resizeHandler, this.resizeDebounceTimeout),
      {passive: true}
    );
  }
  beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeHandler
    );
  }
  private vhUnitsFix() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  private resizeHandler() {
    this.vhUnitsFix();
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewPort <= 375) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XS) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XS);
      }
    } else if (viewPort <= 800) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.SM) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.SM);
      }
    } else if (viewPort <= 1152) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.MD) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.MD);
      }
    } else {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.LG) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.LG);
      }
    }
  }

  @Watch('$route.hash')
  public async onRouteHashChanged(newValue: string, oldValue: string) {
    if (!newValue && oldValue.startsWith('#modal')) {
      await del('IFRAME_HISTORY_BACK_MODE');
      this.$root.$emit('closeDetailsModal');
    }
  }
}
