























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})

export default class ResetPasswordSuccess extends Vue {
  public accept() {
    this.$emit('closeModal');
  }
}
