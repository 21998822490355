








































































































import { Vue, Component } from 'vue-property-decorator';
import { gsap, Power0, Power2 } from 'gsap';

interface ITotalLengthPathDictionary {
  [key: string]: number
}

@Component({})
export default class LogoSplash extends Vue {
  public mounted() {
    const { logo, s1, e, eLine, s2, signet, signetCirclePath, m } = this.$refs;
    const animationSpeed1 = 0.6;
    const animationSpeed2 = 1.0;

    const timeline = gsap.timeline({
      delay: 0.4,
      defaults: {
        duration: animationSpeed1,
        autoAlpha: 1,
        ease: Power2.easeOut
      }
    });

    const totalLengthPathDictionary: ITotalLengthPathDictionary = {
      s1: this.isIEBrowser ? 70 : (s1 as SVGPathElement).getTotalLength(),
      e: this.isIEBrowser ? 61 : (e as SVGPathElement).getTotalLength(),
      eLine: this.isIEBrowser ? 15 : (eLine as SVGPathElement).getTotalLength(),
      s2: this.isIEBrowser ? 70 : (s2 as SVGPathElement).getTotalLength(),
      signet: this.isIEBrowser ? 81 : (signet as SVGPathElement).getTotalLength(),
      signetCirclePath: this.isIEBrowser ? 382 : (signetCirclePath as SVGPathElement).getTotalLength(),
      m: this.isIEBrowser ? 100 : (m as SVGPathElement).getTotalLength()
    };

    timeline
      .set(
        logo,
        {opacity: 1}
      )
      .set(
        signetCirclePath,
        {
          strokeDashoffset: totalLengthPathDictionary.signetCirclePath + 60,
          strokeDasharray: '200,545',
          opacity: 0
        }
      )
      .set(
        s1,
        {
          strokeDashoffset: totalLengthPathDictionary.s1,
          strokeDasharray: totalLengthPathDictionary.s1
        }
      )
      .set(
        e,
        {
          strokeDashoffset: totalLengthPathDictionary.e,
          strokeDasharray: totalLengthPathDictionary.e
        }
      )
      .set(
        eLine,
        {
          strokeDashoffset: totalLengthPathDictionary.eLine,
          strokeDasharray: totalLengthPathDictionary.eLine
        }
      )
      .set(
        s2,
        {
          strokeDashoffset: totalLengthPathDictionary.s2,
          strokeDasharray: totalLengthPathDictionary.s2
        }
      )
      .set(
        signet,
        {
          strokeDashoffset: totalLengthPathDictionary.signet,
          strokeDasharray: totalLengthPathDictionary.signet
        }
      )
      .set(
        m,
        {
          strokeDashoffset: totalLengthPathDictionary.m,
          strokeDasharray: totalLengthPathDictionary.m
        }
      )
      .to(
        s1,
        {
          strokeDashoffset: 0,
        },
        0
      )
      .to(
        e,
        {
          strokeDashoffset: 0,
        },
        0
      )
      .to(
        eLine,
        {
          strokeDashoffset: 0,
        },
        0
      )
      .to(
        s2,
        {
          strokeDashoffset: 0,
        },
        0
      )
      .to(
        signet,
        {
          strokeDashoffset: 0,
        },
        0
      )
      .to(
        m,
        {
          strokeDashoffset: 0,
          ease: Power0.easeOut
        },
        0
      )
      .set(
        signetCirclePath,
        {
          opacity: 1
        }
      )
      .set(
        signet,
        {
          opacity: 0
        }
      )
      .to(
        signetCirclePath,
        {
          duration: animationSpeed2,
          strokeDashoffset: totalLengthPathDictionary.signetCirclePath + 60,
          strokeDasharray: '100,323',
          ease: 'elastic.out(0.3, 0.5)'
        },
        1.0
      )
      .duration(6)
      .then(() => this.$store.dispatch('setIsLogoSplash', false));
  }

  private get isIEBrowser(): boolean {
    const userAgent = window.navigator.userAgent || navigator.userAgent;
    return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1;
  }
}
