
















import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import MediaCenterForm from '@/components/Form/MediaCenter.form.vue';

const { mapFields } = createHelpers({
  getterType: 'getMediaCenterField',
  mutationType: 'updateMediaCenterField',
});

@Component({
  components: {
    MediaCenterForm
  },
  computed: {...mapFields([
      'isMediaCentersVisible'
    ]),
  }
})

export default class MediaCenterModal extends Vue {
  private MediaCenterForm = MediaCenterForm;
  private isMediaCentersVisible!: boolean;

  public openModal() {
    this.isMediaCentersVisible = true;
    this.$nextTick(() => (this.$refs.form as MediaCenterForm).focusInput());
  }

  public closeModal() {
    this.isMediaCentersVisible = false;
  }
}
