











import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import InputCloseIcon from '@/assets/icon/input-close.svg';

@Component({
  components: { InputCloseIcon }
})
export default class InputAction extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public icon!: VueConstructor;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public title!: null | string;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public className!: null | string;

  @Prop({
    type: Function,
    required: true
  })
  public action!: () => void;
}
