import { CookieService } from './cookie.service';
import { set, del } from 'idb-keyval';

export type TokenStorageType = 'cookie' | 'idb';

export interface ITokenService {
  getToken: () => string | null;
  setToken: (token: string | null, storage?: TokenStorageType) => void;
  resetToken: () => void;
}

const cookieToken = CookieService.get('authorizationToken');
let token: string | null =
  !cookieToken === null || cookieToken === 'null' ? null : cookieToken;

const getToken = (): string | null => token;
const setToken = (value: string | null, storage?: TokenStorageType): void => {
  token = value;
  if (!storage || storage === 'cookie') { CookieService.set('authorizationToken', token); }
  if (storage === 'idb') { set('authorizationToken', token); }
};
const resetToken = (): void => {
  CookieService.remove('authorizationToken');
  del('authorizationToken');
  token = null;
};

const TokenService: ITokenService = {
  getToken,
  setToken,
  resetToken,
};
export default TokenService;
