


























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IEducationalStandard} from '@/services/api/educational-standarts.service';
import {markMatch} from '@/utilities/mark-match';

@Component({})
export default class EducationalStandardsSelect extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private placeholder!: string;

  @Prop({
    type: String,
    required: true
  })
  private name!: string;

  @Prop({
    validator: v => typeof v === 'object',
    required: true
  })
  private value!: null | IEducationalStandard;

  @Prop({
    type: Array,
    required: true
  })
  private optionsList!: IEducationalStandard[];

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private loading!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private disabled!: boolean;
  
  private onOpen(): void {
    this.$emit('onOpen');
  }

  private onChange(value: IEducationalStandard): void {
    this.$emit('onChange', value);
  }

  private markMatch = markMatch;

  private hintName = ({name}: IEducationalStandard) => name;
}
