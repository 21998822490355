import Vue from 'vue';
import {ActionTree} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import {RootState} from '@/store';
import BookmarkApiService, { 
  IBookmark, 
  IBookmarkMedia, 
  IBookmarkReplacingMedia, 
  INewBookmark, 
  IBookmarkUpdateResponse, 
  IBookmarkShareResponse } from '@/services/api/bookmark.service';
import {IMedia} from '@/services/api/media.service';
import {SortDirection} from '@/interfaces/interfaces';

export interface IBookmarkState {
  dataList: IBookmark[];
  bookmarkListModal: IBookmark[];
  isModalBookmarkListLoading: boolean;
  isModalBookmarkShareLoading: boolean;
  bookmarkList: IBookmark[];
  mediaList: IMedia[];
  editMode: boolean;
  search: string;
  searchModal: string;
  sortDirection: SortDirection,
  activeBookmarkIdList: number[];
  activeMediaIdList: number[];
  bookmarkIdList: number[];
  mediaIdList: number[];
  isAllMediaListSelected: boolean;
  mediaListLoader: boolean;
  newBookmarkParentId: null | number;
  shareId: string;
  editModeAnimated: boolean;
}

const initialState = (): IBookmarkState => ({
  dataList: [],
  bookmarkList: [],
  bookmarkListModal: [],
  isModalBookmarkListLoading: false,
  isModalBookmarkShareLoading: false,
  mediaList: [],
  editMode: false,
  search: '',
  searchModal: '',
  sortDirection: 'ascending',
  activeMediaIdList: [],
  activeBookmarkIdList: [],
  bookmarkIdList: [],
  mediaIdList: [],
  isAllMediaListSelected: false,
  mediaListLoader: false,
  newBookmarkParentId: null,
  shareId: '',
  editModeAnimated: false
});

const state = initialState();

const getters = {
  getBookmarkField: (state: IBookmarkState) => getField(state),
  getBookmarkShareLink: (state: IBookmarkState) => state.shareId,
  getBookmarkById: (state: IBookmarkState) => (id: number) => {
    return state.bookmarkList.length > 0
      ? state.bookmarkList.filter(item => item.collectionId === id)[0]
      : null;
  }
};

const mutations = {
  ['SET_DATA_LIST'](state: IBookmarkState, payload: IBookmark[]) {
    state.dataList = payload;
  },
  ['SET_BOOKMARK_LIST'](state: IBookmarkState, payload: IBookmark[]) {
    state.bookmarkList = payload;
  },
  ['SET_BOOKMARK_LIST_MODAL'](state: IBookmarkState, payload: IBookmark[]) {
    state.bookmarkListModal = payload;
  },
  ['SET_MEDIA_LIST'](state: IBookmarkState, payload: IMedia[]) {
    state.mediaList = payload;
  },
  ['SET_BOOKMARK_ID_LIST'](state: IBookmarkState, payload: number[]) {
    state.bookmarkIdList = payload;
  },
  ['SET_ACTIVE_MEDIA_ID_LIST'](state: IBookmarkState, payload: number[]) {
    state.activeMediaIdList = payload;
  },
  ['SET_ACTIVE_BOOKMARK_ID_LIST'](state: IBookmarkState, payload: number[]) {
    state.activeBookmarkIdList = payload;
  },
  ['SET_MEDIA_ID_LIST'](state: IBookmarkState, payload: number[]) {
    state.mediaIdList = payload;
  },
  ['SET_ALL_MEDIA_SELECTED'](state: IBookmarkState, payload: boolean) {
    state.isAllMediaListSelected = payload;
  },
  ['SET_NEW_BOOKMARK_PARENT_ID'](state: IBookmarkState, payload: number) {
    state.newBookmarkParentId = payload;
  },
  ['SET_MODAL_BOOKMARK_LIST_LOADING'](state: IBookmarkState, payload: boolean) {
    state.isModalBookmarkListLoading = payload;
  },
  ['SET_MODAL_BOOKMARK_SHARE_LOADING'](state: IBookmarkState, payload: boolean) {
    state.isModalBookmarkShareLoading = payload;
  },
  ['SET_BOOKMARK_SEARCH'](state: IBookmarkState, payload: string) {
    state.search = payload;
  },
  ['SET_SEARCH_MODAL'](state: IBookmarkState, payload: string) {
    state.searchModal = payload;
  },
  ['TOGGLE_SORT_DIRECTION'](state: IBookmarkState) {
    state.sortDirection = state.sortDirection === 'ascending' ? 'descending' : 'ascending';
  },
  ['TOGGLE_EDIT_MODE'](state: IBookmarkState, payload: boolean) {
    state.editMode = payload;
  },
  ['SET_MEDIA_LIST_LOADER'](state: IBookmarkState, payload: boolean) {
    state.mediaListLoader = payload;
  },
  ['SET_BOOKMARK_SHARE_ID'](state: IBookmarkState, payload: string) {
    state.shareId = payload;
  },
  ['SET_EDIT_MODE_ANIMATED'](state: IBookmarkState, payload: boolean) {
    state.editModeAnimated = payload;
  },
  updateBookmarkField(state: IBookmarkState, field: string) {
    return updateField(state, field);
  }
};

const actions: ActionTree<IBookmarkState, RootState> = {
  async getBookmarkData({commit, dispatch, state}, modal: boolean) {
    if (modal) return;
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getBookmarkList', {own: true,})
      .then((bookmarkList: IBookmark[]) => {
        if (bookmarkList.length && state.activeBookmarkIdList.length === 0) {
          commit('SET_ACTIVE_BOOKMARK_ID_LIST', [bookmarkList[0].collectionId]);
        }
        commit('SET_ACTIVE_BOOKMARK_ID_LIST', state.activeBookmarkIdList);
        if (state.activeBookmarkIdList.length) {
          dispatch('getMediaListById', {id: state.activeBookmarkIdList[0], loader: true});
        }
      })
      .catch(() => {
        commit('SET_DATA_LIST', []);
        commit('SET_BOOKMARK_LIST', []);
        commit('SET_LOADING', {bookmark: false});
      });
  },
  async getSharedData({dispatch, commit}, params: {bookmarkId: number}) {
    commit('SET_LOADING', {shared: true});
    return dispatch('getBookmarkList', {own: false})
      .then(() => {
        dispatch('getMediaListById', {id: params.bookmarkId, loader: false})
          .then((data) => {
            commit('SET_LOADING', {shared: false});
            return data;
          })
          .catch(() => {
            commit('SET_LOADING', {shared: false});
          });
      });
  },
  async getBookmarkList({commit, dispatch}, params: {modal: boolean, own: boolean, mediaId: number}) {
    if (params.modal) commit('SET_MODAL_BOOKMARK_LIST_LOADING', true);
    return BookmarkApiService.getBookmarkList(params.own, params.mediaId)
      .then((data: IBookmark[]) => {
        commit('SET_DATA_LIST', data);
        commit('SET_LOADING', {bookmark: false});
        if (params.modal) {
          commit('SET_MODAL_BOOKMARK_LIST_LOADING', false);
          return dispatch('setBookmarkList', true);
        } else {
          return dispatch('setBookmarkList');
        }
      });
  },
  async setBookmarkVersion({commit, state}, params: {id: number, version: number}) {
    const list = state.bookmarkList.map(item => {
      if (item.collectionId === params.id) return {...item, version: params.version};
      return item;
    });
    commit('SET_BOOKMARK_LIST', list);
  },
  async setBookmarkList({commit, state}, modal: boolean) {
    const list = state.dataList.reverse();
    const search: string = modal 
      ? state.searchModal.toLowerCase()
      : state.search.toLowerCase();

    const filteredList = !search.length
      ? list
      : list
        .filter(
          (item: IBookmark) => item.collectionName.toLowerCase().includes(search)
        );
        
    if (modal) {
      const bookmarkList = filteredList
        .sort((a: IBookmark, b: IBookmark) => (b.added === a.added)? 0 : a.added? -1 : 1);
      commit('SET_BOOKMARK_LIST_MODAL', filteredList);
      return bookmarkList;
    } else {
      commit('SET_BOOKMARK_LIST', filteredList);
      return filteredList;
    }
  },
  async sortBookmarkList({state, commit}) {
   const bookmarkList = state.sortDirection === 'ascending'
     ? state.dataList.sort((a: IBookmark, b: IBookmark) => a.collectionName.localeCompare(b.collectionName))
     : state.dataList.sort((a: IBookmark, b: IBookmark) => b.collectionName.localeCompare(a.collectionName));
   commit('SET_BOOKMARK_LIST', bookmarkList);
  },
  async toggleBookmarkSort({commit, dispatch}) {
    commit('TOGGLE_SORT_DIRECTION');
    dispatch('sortBookmarkList');
  },
  async toggleEditMode({commit, state}) {
    commit('SET_MEDIA_ID_LIST', []);
    state.editMode
      ? commit('TOGGLE_EDIT_MODE', false) 
      : commit('TOGGLE_EDIT_MODE', true);
  },
  async searchBookmark({commit, dispatch}, query: string) {
    commit('SET_BOOKMARK_SEARCH', query);
    dispatch('setBookmarkList');
  },
  async searchBookmarkModal({commit, dispatch}, query: string) {
    commit('SET_SEARCH_MODAL', query);
    dispatch('setBookmarkList', true);
  },
  async setBookmarkIdList({commit}, list: number[]) {
    commit('SET_BOOKMARK_ID_LIST', list);
  },
  async setBookmarksCount({state}, data: IBookmarkUpdateResponse[]) {
    data.forEach((item: IBookmarkUpdateResponse) => {
      state.bookmarkList.map((element) => item.bookmarkId === element.collectionId
        ? element.count = item.count 
        : element );
    });
  },
  async setAllMediaIdList({commit, state}) {
    const list = state.mediaList.map((item : IMedia)=> item.id);
    if (!state.isAllMediaListSelected) {
      commit('SET_MEDIA_ID_LIST', list);
    } else {
      commit('SET_MEDIA_ID_LIST', []);
    }
  },
  async getMediaListById({commit}, params: {id: number, loader: boolean}) {
    commit('SET_MEDIA_ID_LIST', []);
    commit('TOGGLE_EDIT_MODE', false);
    if (params.loader) commit('SET_MEDIA_LIST_LOADER', true);
    commit('SET_ACTIVE_BOOKMARK_ID_LIST', [params.id]);
    
    return BookmarkApiService.getMediaListById(params.id)
      .then((data: IMedia[]) => {
        commit('SET_MEDIA_LIST', data);
        commit('SET_MEDIA_ID_LIST', []);
        commit('SET_ALL_MEDIA_SELECTED', false);
        commit('SET_MEDIA_LIST_LOADER', false);
      })
      .catch((error) => {
        if (error.message && error.message === 'aborted') {
          commit('SET_MEDIA_LIST_LOADER', true);
        }
        commit('SET_MEDIA_LIST', []);
      });
  },
  async addMedia({commit, dispatch, state}, params: {mediaIdList: number[], bookmarkIdList: number[]}) {
    commit('SET_LOADING', {bookmark: true});

    const media: IBookmarkMedia = {
      mediaIdList: params.mediaIdList,
      bookmarkIdList: params.bookmarkIdList ? params.bookmarkIdList : state.bookmarkIdList
    };

    return BookmarkApiService.addBookmarkMedia(media)
      .then((data: IBookmarkUpdateResponse[]) => {
        commit('TOGGLE_EDIT_MODE', false);
        dispatch('setBookmarksCount', data);
        commit('SET_LOADING', {bookmark: false});
        if (params.bookmarkIdList) return;
        dispatch('notifySuccess', {title: 'Merkliste', message: 'Sie haben den Titel Ihrer Merkliste hinzugefügt'});
      })
      .catch(() => {
        commit('SET_LOADING', {bookmark: false});
        commit('TOGGLE_EDIT_MODE', false);
      });
  },
  async removeMedia({commit, dispatch, state}) {
    commit('SET_LOADING', {bookmark: true});

    const media: IBookmarkMedia = {
      mediaIdList: state.mediaIdList,
      bookmarkIdList: state.activeBookmarkIdList
    };

    return BookmarkApiService.removeBookmarkMedia(media)
      .then((data: IBookmarkUpdateResponse[]) => {
        commit('TOGGLE_EDIT_MODE', false);
        dispatch('setBookmarksCount', data);
        commit('SET_LOADING', {bookmark: false});
        dispatch('notifySuccess', {title: 'Merkliste', message: 'Sie haben den Titel Ihrer Merkliste gelöscht'});
        return dispatch('getMediaListById', {id: state.activeBookmarkIdList[0], loader: true});
      })
      .catch(() => {
        commit('SET_LOADING', {bookmark: false});
        commit('TOGGLE_EDIT_MODE', false);
      });
  },
  async replaceMedia({commit, dispatch, state}, params: {bookmarkIdList: number[]}) {
    commit('SET_LOADING', {bookmark: true});

    const replacingMedia: IBookmarkReplacingMedia = {
      objectIdList: state.mediaIdList,
      fromBookmarkIdList: state.activeBookmarkIdList,
      toBookmarkIdList: params.bookmarkIdList ? params.bookmarkIdList : state.bookmarkIdList
    };

    return BookmarkApiService.replaceBookmarkMedia(replacingMedia)
      .then((data: IBookmarkUpdateResponse[]) => {
        commit('TOGGLE_EDIT_MODE', false);
        commit('SET_LOADING', {bookmark: false});
        dispatch('setBookmarksCount', data);
        if (params.bookmarkIdList) return; 
        dispatch('notifySuccess', {title: 'Merklisten', message: 'Sie haben Ihre Auswahl in eine andere Merkliste verschoben'});
        dispatch('getMediaListById', {id: state.activeBookmarkIdList[0], loader: true});
      })
      .catch(() => {
        commit('SET_LOADING', {bookmark: false});
        commit('TOGGLE_EDIT_MODE', false);
      });
  },
  async updateBookmarkData({dispatch, commit, state}) {
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getMediaListById', {id: state.activeBookmarkIdList[0], loader: false})
      .then(() => dispatch('getBookmarkList', {modal: false, own: true,}))
      .then(() => commit('SET_LOADING', {bookmark: false}));
  },
  async getNewBookmarkParentId({commit}) {
    return BookmarkApiService.getParentId()
      .then((data: IBookmark) => {
        commit('SET_NEW_BOOKMARK_PARENT_ID', data.collectionId);
      });
  },
  async createBookmark({dispatch, state, commit}, params: {bookmarkName: string}) {
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getNewBookmarkParentId')
      .then(() => {
        const bookmark = {
          name: params.bookmarkName,
          parentId: state.newBookmarkParentId,
          id: null,
          version: null,
          type: 'workfolder'
        };

        return BookmarkApiService
          .createBookmark(bookmark)
          .then((data: INewBookmark) => {
            commit('SET_ACTIVE_BOOKMARK_ID_LIST', [data.id]);
            dispatch('updateBookmarkData')
              .then(() => dispatch('notifySuccess', 
                {
                  title: `Merklisten ${params.bookmarkName}`,
                  message: 'Sie haben eine neue Merkliste erstellt'
                }
              ));
          })
          .catch(() => {
            commit('SET_LOADING', {bookmark: false});
          });
        });
  },
  async addMediaToNewBookmark({dispatch, state, commit}, params: {bookmarkName: string, mediaIdList: number[]}) {
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getNewBookmarkParentId')
      .then(() => {
        const bookmark = {
          name: params.bookmarkName,
          parentId: state.newBookmarkParentId,
          id: null,
          version: null,
          type: 'workfolder'
        };

        return BookmarkApiService.createBookmark(bookmark)
          .then((data: INewBookmark) => {
            commit('SET_ACTIVE_BOOKMARK_ID_LIST', [data.id]);
            dispatch('addMedia', {bookmarkIdList: [data.id], mediaIdList: params.mediaIdList})
              .then(() => 
                dispatch('notifySuccess', 
                  {
                    title: 'Merklisten',
                    message: 'Sie haben eine neue Merklisten angelegt'
                  }
                )
              );
          })
          .catch(() => {
            commit('SET_LOADING', {bookmark: false});
          });
      });
  },
  async replaceMediaToNewBookmark({dispatch, state, commit}, params: {bookmarkName: string}) {
    commit('SET_LOADING', {bookmark: true});
    return dispatch('getNewBookmarkParentId')
      .then(() => {
        const bookmark = {
          name: params.bookmarkName,
          parentId: state.newBookmarkParentId,
          id: null,
          version: null,
          type: 'workfolder'
        };

      BookmarkApiService.createBookmark(bookmark)
        .then((data: INewBookmark) => {
          commit('SET_ACTIVE_BOOKMARK_ID_LIST', [data.id]);
          dispatch('replaceMedia', {bookmarkIdList: [data.id]})
            .then(() => {
              return dispatch('updateBookmarkData')
                .then(() => dispatch('notifySuccess', {title: 'Merkliste', message: 'Sie haben den Titel Ihrer Merkliste hinzugefügt'}));
            });
        })
        .catch(() => {
          commit('SET_LOADING', {bookmark: false});
        });
      });
  },
  async removeBookmark({dispatch, state, commit}, params: {id: number}) {
    commit('SET_LOADING', {bookmark: true});
    return BookmarkApiService.removeBookmark(params.id)
      .then(() => {
        if (state.activeBookmarkIdList.includes(params.id)) {
          const bookmaks = state.bookmarkList
            .filter(bookmark => bookmark.collectionId !== params.id);
          commit('SET_ACTIVE_BOOKMARK_ID_LIST', [bookmaks[0].collectionId]);
        }
        
        dispatch('updateBookmarkData')
          .then(() => dispatch('notifySuccess', {title: 'Merklisten', message: 'Sie haben die Merkliste erfolgreich gelöscht'}));
      })
      .catch(() => {
        commit('SET_LOADING', {bookmark: false});
      });
  },
  async renameBookmark({dispatch, state, commit}, params: {bookmarkIdList: number[], bookmarkName: string}) {
    commit('SET_LOADING', {bookmark: true});
    const activeBookmark = state.bookmarkList.filter(item => params.bookmarkIdList[0] === item.collectionId);
    const bookmark = {
      name: params.bookmarkName,
      parentId: activeBookmark[0].parentId,
      id: activeBookmark[0].collectionId,
      version: activeBookmark[0].version,
      type: 'workfolder'
    };
    return BookmarkApiService.updateBookmark(bookmark)
    .then(() => {
      dispatch('updateBookmarkData')
        .then(() => dispatch('notifySuccess', {title: 'Merklisten', message: 'Sie haben Ihre Merkliste umbenannt'}));
    })
    .catch(() => {
      commit('SET_LOADING', {bookmark: false});
    });
  },
  async shareBookmark({dispatch, commit}, bookmark: IBookmark) {
    commit('SET_MODAL_BOOKMARK_SHARE_LOADING', true);
    return BookmarkApiService.shareBookmark(bookmark)
      .then((data: IBookmarkShareResponse) => {
        commit('SET_BOOKMARK_SHARE_ID', data.id);
        dispatch('setBookmarkVersion', {id: bookmark.collectionId, version: data.version});
        commit('SET_MODAL_BOOKMARK_SHARE_LOADING', false);
      })
      .catch(() => {
        commit('SET_MODAL_BOOKMARK_SHARE_LOADING', false);
      });
  },
  async notifySuccess(ctx, data: {title: string, message: string}) {
    Vue.prototype.$notify({
      group: 'app',
      type: 'success',
      title: data.title,
      text: data.message
    });
  },
  setEditModeAnimated({commit}, payload) {
    commit('SET_EDIT_MODE_ANIMATED', payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
