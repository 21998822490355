























import Vue from 'vue';
import Component from 'vue-class-component';
import {Model, Prop} from 'vue-property-decorator';
import CheckboxCheckIcon from '../../assets/icon/checkbox-check.svg';
import {IDictionary} from '@/interfaces/interfaces';

@Component({
  components: { CheckboxCheckIcon },
  inheritAttrs: false
})
export default class RadioButton extends Vue {

  @Model('change', { type: [String, Boolean, Number] })
  readonly modelValue!: string | number | boolean;

  @Prop({
    type: String,
    required: true
  })
  public name!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public label!: string;

  @Prop({
    type: String,
    required: false,
    default: 'checkbox'
  })
  public mode!: 'radio' | 'checkbox';

  @Prop({
    type: [String, Boolean, Number],
    required: true,
  })
  public value!: string | number | boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public noPadding!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public white!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public isShowSingleError!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorMessageList!: IDictionary<string>;

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  public errorState!: IDictionary<boolean>;

  private get classObject(): IDictionary<boolean> {
    return {
      [`${this.mode}-input`]: true,
      'no-padding': this.noPadding,
      'white': this.white,
      error: this.errorState.$invalid && this.errorState.$dirty
    };
  }
  private get shouldBeChecked(): boolean {
    return this.modelValue === this.value;
  }
  private updateInput(): void {
    this.$emit('change', this.value);
  }
}
