import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export interface ILtiKey {
  id: string;
  keyName: string;
  publicKey: string;
  secretKey: string;
}

export interface ILtiApiService {
  getLtiKeys: () => Promise<ILtiKey[]>,
  addLtiKey: (keyName: string) => Promise<ILtiKey>,
  removeLtiKey: (id: string) => Promise<ILtiKey>
}

const getLtiKeys = () => api
  .get('ltikey')
  .then((response: AxiosResponse<ILtiKey[]>) => response.data)
  .catch((error) => Promise.reject(error));

const addLtiKey = (keyName: string) => api
  .post('ltikey', {
    keyName
  })
  .then((response: AxiosResponse<ILtiKey>) => response.data)
  .catch((error) => Promise.reject(error));

const removeLtiKey = (id: string) => api
  .delete(`ltikey/${id}`)
  .then((response: AxiosResponse<ILtiKey>) => response.data)
  .catch((error) => Promise.reject(error));


const LtiApiService: ILtiApiService = {
  getLtiKeys,
  addLtiKey,
  removeLtiKey
};

export default LtiApiService;
