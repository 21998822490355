import {getField, updateField} from 'vuex-map-fields';
import {IDictionary} from '@/interfaces/interfaces';
import {ActionTree} from 'vuex';
import {RootState} from '@/store';

export enum AdaptiveMode {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type ErrorCode = 404 | 500;

export interface IUiState {
  adaptiveMode: AdaptiveMode;
  loadingDictionary: IDictionary<boolean>;
  isSearchActive: boolean;
  errorCode: null | ErrorCode;
  errorMessage: null | string;
  isLogoSplash: null | boolean;
  isCookieBanner: boolean;
  isLtiDisclamer: boolean;
  modalState: string[];
  modalVisibility: boolean;
}

export interface ILoadingData {
  [key: string]: boolean;
}

const initialState = (): IUiState => ({
  adaptiveMode: AdaptiveMode.LG,
  loadingDictionary: {user: true},
  isSearchActive: false,
  errorCode: null,
  errorMessage: null,
  isLogoSplash: false,
  isCookieBanner: false,
  isLtiDisclamer: false,
  modalState: [],
  modalVisibility: false
});
const state = initialState();

const getters = {
  getUiField: (state: IUiState) => getField(state),
  loadingDictionary: (state: IUiState) => state.loadingDictionary,
  isLoading: (state: IUiState) => Object.keys(state.loadingDictionary)
    .map((key: string) => state.loadingDictionary[key])
    .some((isLoading: boolean) => isLoading),
  isMobileAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.XS
    || state.adaptiveMode === AdaptiveMode.SM,
  isTabletAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.MD,
  isMobileDeviceAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.XS
    || state.adaptiveMode === AdaptiveMode.SM
    || state.adaptiveMode === AdaptiveMode.MD,
  isDesktopAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.LG,
  modalVisibility: (state: IUiState) => state.modalVisibility
};
const mutations = {
  ['SET_ADAPTIVE_MODE'](state: IUiState, payload: AdaptiveMode) {
    state.adaptiveMode = payload;
  },
  ['SET_LOADING'](state: IUiState, payload: ILoadingData) {
    state.loadingDictionary = {...state.loadingDictionary, ...payload};
  },
  ['SET_ERROR_CODE'](state: IUiState, payload: ErrorCode) {
    state.errorCode = payload;
  },
  ['SET_ERROR_MESSAGE'](state: IUiState, payload: string) {
    state.errorMessage = payload;
  },
  ['SET_IS_LOGO_SPLASH'](state: IUiState, payload: boolean) {
    state.isLogoSplash = payload;
  },
  ['SET_IS_COOKIE_BANNER'](state: IUiState, payload: boolean) {
    state.isCookieBanner = payload;
  },
  ['SET_MODAL'](state: IUiState, payload: string) {
    state.modalState = state.modalState.includes(payload)
      ? state.modalState.filter(id => id !== payload)
      : [...state.modalState, payload];
  },
  ['SET_MODAL_VISIBILITY'](state: IUiState, payload: boolean) {
    state.modalVisibility = payload;
  },
  ['RESET_MODALS'](state: IUiState) {
    state.modalState = [];
  },
  updateUiField(state: IUiState, field: string) {
    return updateField(state, field);
  }
};
const actions: ActionTree<IUiState, RootState> = {
  setIsLogoSplash({commit}, payload) {
    commit('SET_IS_LOGO_SPLASH', payload);
  },
  setIsCookieBanner({commit}, payload) {
    commit('SET_IS_COOKIE_BANNER', payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
