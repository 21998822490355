import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import MediaCenterService from '@/services/data/media-center.service';
import { IMedia } from '@/services/api/media.service';

export interface IChartApiService {
  getChartList: () => Promise<IMedia[]>
}

const getChartList = (): Promise<IMedia[]> => {
  const mc = MediaCenterService.getMediaCenter();
  return api
    .get(
      '/charts',
      {
        params: { mc },
        data: null
      }
    )
    .then((response: AxiosResponse<IMedia[]>) => response.data)
    .catch((error) => Promise.reject(error));
};

const ChartApiService: IChartApiService = {
  getChartList
};

export default ChartApiService;
