import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import { IMedia } from '@/services/api/media.service';
import { defaultValueDictionary } from '@/store/modules/search.store';

export interface ISuggest {
  value: string;
  text: string;
}

export interface IResponseSort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}
export interface ISearchResponsePageable {
  sort: IResponseSort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}
export interface ISearchResponseObjects<T> {
  content: T[]
  sort: IResponseSort;
  pageable: ISearchResponsePageable
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  size: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
}
export interface ISearchResponse<T> {
  responseObjects: ISearchResponseObjects<T>;
  filters: ExpertSearchDictionary<IFilterItem[]> & EducationalStandardsData
}

export type ExpertSearchType = 'subjectList'
  | 'targetGroupList'
  | 'mediaTypeList'
  | 'loanableList'
  | 'aspectList';

export type ExpertSearchDictionary<T> = {
  [key in ExpertSearchType]: T;
}

export interface IFilterItem {
  id: number;
  name: string;
  facetScore: number;
}
export interface IFilterData extends IFilterItem {
  value: boolean;
}
export interface IEducationalStandardsItem extends IFilterItem {
  level: number;
  parentId: number;
}
export type SearchFilterType = 'subject'
  | 'targetGroup'
  | 'mediaType'
  | 'loanable';
export type ExpertSearchFilterType = 'subjectList'
  | 'targetGroupList'
  | 'mediaTypeList'
  | 'loanableList';
export type ExpertSearchFilterMapping= {
  [key in ExpertSearchFilterType]: SearchFilterType;
};
export type EducationalStandardsData = {
  educationStandardList: IEducationalStandardsItem[]
}
export type FilterDictionary<T> = {
  [key in SearchFilterType]: T;
};

export type ExpertSearchFilterDictionary<T> = {
  [key in ExpertSearchFilterType]: T;
};
export type ExpertSearchFilterDictionaryData<T> = {
  [key in ExpertSearchFilterType]?: T;
};

// TODO refactor interfaces: flow - flat (store) => query (url) => structured (api)
export interface ISearchQueryStringParameters {
  search?: string;
  size?: string;
  page?: string;
  sort?: string;
  recommended?: string;
  oer?: string;
  subject?: string;
  targetGroup?: string;
  mediaType?: string;
  loanable?: string;
  educationalStandard?: string;
  aspect?: string;
  mc?: number;
  ltiInitial?: boolean;
}

export enum SearchSort {
  TITLE_DESC = 'TITLE_DESC',
  TITLE_ASC = 'TITLE_ASC',
  PRODUCTION_YEAR = 'PRODUCTION_YEAR',
  PRODUCTION_YEAR_ASC = 'PRODUCTION_YEAR_ASC',
  RELEVANCE = 'RELEVANCE'
}
export type SearchSortType = keyof typeof SearchSort;

export interface ISearchQueryParameters {
  search?: string;
  size?: number;
  page?: number;
  sort?: SearchSortType;
  mc?: number;
  recommended?: boolean;
  oer?: boolean;
  subject?: number[];
  targetGroup?: number[];
  mediaType?: number[];
  loanable?: number[];
  educationalStandard?: number[];
  aspect?: number[];
}

export interface ISearchRequestParameters extends ISearchParameters {
  expertSearch?: IExpertSearchParameters;
  ltiInitial?: boolean;
}

export interface ISearchParameters {
  search: string;
  sorting: SearchSortType;
  page?: number;
  size?: number;
  mc?: number;
}

export interface ISearchQueryRootParameters {
  search?: string;
  sort?: SearchSortType;
  page?: number;
  size?: number;
  mc?: number;
}

export interface IExpertSearchQueryParameters {
  recommended?: boolean;
  oer?: boolean;
  subject?: number[];
  targetGroup?: number[];
  mediaType?: number[];
  loanable?: number[];
  educationalStandard?: number[];
  aspect?: number[];
}

export interface IExpertSearchParameters {
  recommendedMedia?: boolean;
  oer?: boolean;
  subjectList?: number[];
  targetGroupList?: number[];
  mediaTypeList?: number[];
  loanableList?: number[];
  educationalStandardList?: number[];
  aspectList?: number[];
}

export interface ISearchApiService {
  suggest: (query: string) => Promise<ISuggest[]>
  search: (searchParameters: ISearchRequestParameters) => Promise<ISearchResponse<IMedia>>
}

const suggest = (q: string): Promise<ISuggest[]> => {
  const size = 10;
  return api
    .get<ISuggest[]>(
      'suggest',
      {
        params: {q, size},
        data: null
      }
    )
    .then((response: AxiosResponse<ISuggest[]>) => response.data)
    .catch((error) => Promise.reject(error));
};

const search = (searchParameters: ISearchRequestParameters): Promise<ISearchResponse<IMedia>> => {
  const size = searchParameters.size || defaultValueDictionary.size;
  return api
    .post(
      '/search',
      {...searchParameters, size}
    )
    .then((response: AxiosResponse<ISearchResponse<IMedia>>) => response.data)
    .catch((error) => Promise.reject(error));
};

const SearchApiService: ISearchApiService = {
  suggest,
  search
};

export default SearchApiService;
