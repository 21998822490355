import {api} from '@/services/api/api.service';

export interface IStatisticApiService {
  sendStatisticStream: (id: number, mediaCenterId: number) => void,
  sendStatisticDownload: (idList: number[], mediaCenterId: number) => void,
  sendStatisticCollectionDownload: (id: number, mediaCenterId: number) => void
}

const sendStatisticStream = (id: number, mediaCenterId: number) => api
  .get(`event/stream?id=${id}${mediaCenterId ? '&mc=' + mediaCenterId : ''}`);

const sendStatisticDownload = (idList: number[], mediaCenterId: number) => api
  .get(`event/download/?idList=${idList.join(',')}${mediaCenterId ? '&mc=' + mediaCenterId : ''}`);

const sendStatisticCollectionDownload = (id: number, mediaCenterId: number) => api
  .get(`event/download/zip/?id=${id}${mediaCenterId ? '&mc=' + mediaCenterId : ''}`);


const StatisticApiService: IStatisticApiService = {
  sendStatisticStream,
  sendStatisticCollectionDownload,
  sendStatisticDownload
};

export default StatisticApiService;