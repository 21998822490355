




























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})

export default class DataTransmission extends Vue {
  public confirmation: boolean = true;

  public accept(): void {
    this.$emit('confirm');
  }
}
