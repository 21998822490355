import {VueConstructor} from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';

export interface IDictionary<T> {
  [key: string]: T;
}
export interface INumericDictionary<T> {
  [key: number]: T;
}
export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
} // TODO use in all input components

export interface ILinkItem {
  id?: number | string;
  icon?: object;
  title?: string;
  link?: string;
  externalLink?: string;
  text: string;
  content?: object;
  modal?: boolean;
}
export type LinkList = ILinkItem[];
export interface IText {
  id: number;
  text: string;
}
export interface IPaginationSettings {
  pagesLeft?: number;
  pagesRight?: number;
}
export interface IPaginationConfiguration {
  pagesLeft: number;
  pagesRight: number;
}
export interface IDirection<T> {
  up: T;
  down: T;
  left: T;
  right: T;
}
export type SortDirection = 'ascending' | 'descending';

export type Direction = 'left' | 'right' | 'up' | 'down';

export interface ISortFields {
  [key: string]: string;
}

/*** Input ***/
export type InputActionPosition = 'left' | 'right';

export enum InputActionType {
  loading = 'loading',
  clear = 'clear',
  password = 'password'
}
export type InputType = 'email' | 'password';
export type InputActionTypeType = keyof typeof InputActionType;
export type InputTypeActionDictionary = {
  [key in InputType]: InputActionData[];
};
export type InputActionDictionary = {
  [key in InputActionTypeType]: IInputAction;
};

export interface IInputActionDataBase {
  title?: string;
  action?: () => void;
}
export interface IInputActionData extends IInputActionDataBase {
  className?: string;
  icon: VueConstructor;
  position?: InputActionPosition;
}
export type InputActionData = InputActionType | IInputActionData;

export interface IInputAction {
  component: VueConstructor;
  props: InputActionProps;
}

export interface IInputActionBase {
  className?: null | string;
  title: null | string;
  action: () => void;
}

export interface IInputActionDataProps extends IInputActionBase {
  icon: VueConstructor;
}
export interface IInputActionClearProps extends IInputActionBase {
  value: () => string;
}
export interface IInputActionPasswordProps {
  isPassword: () => boolean;
  isPasswordVisible: () => boolean;
  action: () => void;
}
export interface IInputActionLoadingProps {
  title: null | string;
  value: () => boolean;
}
export type InputActionProps = IInputActionDataProps | IInputActionClearProps | IInputActionPasswordProps | IInputActionLoadingProps;

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ButtonMode = 'primary' | 'secondary' | 'flat' | 'white';
export type PopoverPlacement = 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export type ICardType = 'high' | 'wide';

export interface IModal {
  id: string;
  title?: string | null;
  maxWidth?: string;
  height?: string;
  label?: string;
  type?: string;
  component: VueConstructor;
}

export interface IRouterGuard {
  to: Route;
  from: Route;
  next: NavigationGuardNext;
  nextGuard?: (params?: IRouterGuard) => void;
}

