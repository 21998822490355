









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({})
export default class InputActionLoading extends Vue {
  @Prop({
    type: Function,
    required: true
  })
  public value!: () => boolean;

  @Prop({
    type: String,
    required: true
  })
  public title!: string;
}
