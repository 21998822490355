



























import Vue from 'vue';
import Component from 'vue-class-component';
import { IMedia, MediaTypes, MediaVideoExtensions, MediaAudioExtensions } from '@/services/api/media.service';
import { Prop } from 'vue-property-decorator';
import AccordionVideo from '@/pages/Main/Accordion.video.vue';

@Component({
  components: { AccordionVideo }
})
export default class Video extends Vue {
  private activeId: number = 0;
  private MediaTypes = MediaTypes;
  public MediaVideoExtensions = MediaVideoExtensions;
  public MediaAudioExtensions = MediaAudioExtensions;

  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  private onToggle(item: IMedia) {
    if (this.activeId !== 0) {
      this.activeId = 0;
    } else {
      this.activeId = item.id;
    }
  }

  public get url() {
    return this.data.dataInfo.versions.original.url || '';
  }

  private closeModal(): void {
    this.$emit('closeModal');
  }
}
